import { Container } from 'atomic'
import { FC } from 'react'

import { container } from './Usp.css'
import UspItem, { UspItemProps } from './UspItem'
import { BoxProps } from 'atomic/components/atoms/box/Box'
import { useCmsOrTranslation } from 'hooks/useSafeTranslation'
import { BuilderProps, removeKeys } from 'components/builder_components/helpers/BuilderPropCleaner'

type UspProps = {
  usp1?: UspItemProps
  usp2?: UspItemProps
  usp3?: UspItemProps
  usp4?: UspItemProps
} & BuilderProps &
  BoxProps

const Usp: FC<UspProps> = ({ usp1, usp2, usp3, usp4, ...props }) => {
  const { cmsOrLokalise } = useCmsOrTranslation()

  const cleanProps = removeKeys(props, { builderBlock: true, builderState: true })

  return (
    <Container className={container} {...cleanProps}>
      <UspItem
        {...usp1}
        title={cmsOrLokalise(usp1?.title, 'productpage_factbox_sustainability_header')}
        body={cmsOrLokalise(usp1?.body, 'productpage_factbox_sustainability_body')}
      />
      <UspItem
        {...usp2}
        title={cmsOrLokalise(usp2?.title, 'productpage_factbox_printing_header')}
        body={cmsOrLokalise(usp2?.body, 'productpage_factbox_printing_body')}
      />
      <UspItem
        {...usp3}
        title={cmsOrLokalise(usp3?.title, 'productpage_factbox_collab_header')}
        body={cmsOrLokalise(usp3?.body, 'productpage_factbox_collab_body')}
      />
      <UspItem
        {...usp4}
        title={cmsOrLokalise(usp4?.title, 'productpage_factbox_seamless_header')}
        body={cmsOrLokalise(usp4?.body, 'productpage_factbox_seamless_body')}
      />
    </Container>
  )
}

export default Usp
