import React, { FC } from 'react'

import Box, { BoxProps } from '../box/Box'

type StackProps = Omit<BoxProps, 'display' | 'flexDirection'> & {
  direction?: 'horizontal' | 'vertical'
  className?: string
  children?: React.ReactNode
}

const Stack: FC<StackProps> = ({ children, direction = 'horizontal', ...props }) => {
  return (
    <Box display="flex" flexDirection={direction === 'vertical' ? 'column' : 'row'} {...props}>
      {children}
    </Box>
  )
}

export default Stack
