import Box, { BoxProps } from 'atomic/components/atoms/box/Box'
import React, { FC, ReactNode } from 'react'
import InternalLink from 'components/internallink/InternalLink'
import { useLink } from 'context/LinkContext'
import { useRouter } from 'next/router'
import * as css from './DiscountCircle.css'

type DiscountCircleProps = Pick<BoxProps, 'background'> & {
  illustration?: ReactNode
  link?: string
}

const DiscountCircle: FC<DiscountCircleProps> = (props) => {
  const { getLocalePath } = useLink()
  const router = useRouter()

  return (
    <>
      {props.link ? (
        <InternalLink href={getLocalePath(props.link, router.locale)}>
          <Box
            background={props.background}
            borderRadius="full"
            position={'absolute'}
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            justifyContent={'center'}
            paddingX={'s'}
            paddingY={'m'}
            className={css.circle}
          >
            {props.illustration && (
              <Box style={{ zIndex: 1 }} paddingBottom={'xxxs'}>
                {props.illustration}
              </Box>
            )}
            <Box marginBottom="none" className={css.text} textAlign={'center'} fontSize={'xs'} style={{ zIndex: 1 }}>
              {props.children}
            </Box>
          </Box>
        </InternalLink>
      ) : (
        <Box
          background={props.background}
          borderRadius="full"
          position={'absolute'}
          display={'flex'}
          flexDirection={'column'}
          alignItems={'center'}
          justifyContent={'center'}
          paddingX={'s'}
          paddingY={'m'}
          className={css.circle}
        >
          {props.illustration && (
            <Box style={{ zIndex: 1 }} paddingBottom={'xxxs'}>
              {props.illustration}
            </Box>
          )}
          <Box marginBottom="none" className={css.text} textAlign={'center'} fontSize={'xs'} style={{ zIndex: 1 }}>
            {props.children}
          </Box>
        </Box>
      )}
    </>
  )
}

export default DiscountCircle
