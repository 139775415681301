import DynamicLink from 'components/dynamiclink/DynamicLink'
import { useRouter } from 'next/router'
import { FC, useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { withTheme } from 'styled-components'
import { Theme } from 'styles/Theme'
import { StoryblokContent } from 'types/StoryblokContent'

import { Button } from '@onceuponapp/ui'
import { storyblokEditable } from '@storyblok/react'

import Modal from '../../modal/Modal'
import StoryblokImage from '../../storyblokimage/StoryblokImage'

import styles from './Splash.module.scss'
import { useOuCookies } from 'components/cookiebanner/CookieBanner'
import { ouTheme } from 'atomic/styles/theme.css'

type PropTypes = {
  blok?: StoryblokContent
  theme: Theme
}

const Splash: FC<PropTypes> = ({ blok, theme }) => {
  const { consentNecessary } = useOuCookies()
  const storage = globalThis?.localStorage
  const isMobile = useMediaQuery({ maxWidth: theme.screenSizes.small })
  const [isOpen, setIsOpen] = useState(true)
  const router = useRouter()

  useEffect(() => {
    if (storage) {
      const isGoogleSearch = router.query.af_channel === 'google_search'
      setIsOpen(!isGoogleSearch && !storage.getItem('hasSeenSplash'))
    }
  }, [storage, router.query])

  const setHasSeenSplash = (): void => {
    if (consentNecessary) {
      storage.setItem('hasSeenSplash', 'true')
    }
    setIsOpen(false)
  }

  return (
    <Modal className={styles.modal} show={isOpen} onClosed={() => setHasSeenSplash()}>
      <div className={styles.container} {...storyblokEditable(blok)}>
        <div className={styles.imageContainer}>
          <StoryblokImage src={blok.icon.filename} fill sizes={`(max-width: ${ouTheme.breakpoints.desktop}) 100vw, 50vw`} alt={blok.icon.name} />
        </div>
        <h3>{blok.title}</h3>
        <p>{blok.text}</p>
        {blok.button_link && (
          <div className={styles.buttonContainer}>
            <DynamicLink linkObject={blok.button_link}>
              <Button tight={isMobile} onClick={() => setHasSeenSplash()}>
                {blok.button_title}
              </Button>
            </DynamicLink>
          </div>
        )}
      </div>
    </Modal>
  )
}

export default withTheme(Splash)
