import React, { FC } from 'react'
import style from './HeroImage.module.scss'
import { LocaleKey } from 'types/LokalizeKeys'
import { useSafeTranslation } from 'hooks/useSafeTranslation'
import { Button } from '@onceuponapp/ui'
import InternalLink from 'components/internallink/InternalLink'
import { useLink } from 'context/LinkContext'
import { useRouter } from 'next/router'
import Image from 'next/image'
import customerSatisfactionImage from '../../../public/images/customersatisfaction/customer_satisfaction_guarantee.webp'

interface IHeroImageProps {
  title: LocaleKey
  subtitle: LocaleKey
}

const HeroImage: FC<IHeroImageProps> = (props) => {
  const { t } = useSafeTranslation()
  const router = useRouter()
  const { getLocalePath } = useLink()

  const buttonId = 'start_hero_create_btn'

  return (
    <div className={style.container}>
      <div className={style.imageContainer}>
        <Image src={customerSatisfactionImage} alt={''} />
      </div>
      <div className={style.headingContainer}>
        <h1>{t(props.title)}</h1>
        <div className={style.lineDivider} />
        <h2>{t(props.subtitle)}</h2>

        <InternalLink href={getLocalePath('/photobooks', router.locale)}>
          <Button id={buttonId} className={style.button} theme={'primary'}>
            {t('productpage_hero_photobook_header')}
          </Button>
        </InternalLink>
      </div>
    </div>
  )
}

export default HeroImage
