import { Box } from 'atomic'
import Image from 'next/image'
import React from 'react'
import v1Image from './images/version1_illustration.png'
import v2Image1 from './images/version2_illustration1.png'
import v2Image2 from './images/version2_illustration2.png'
import v2Image3 from './images/version2_illustration3.png'
import v2Image3Mobile from './images/version2_illustration3_mobile.png'
import { ouTheme } from 'atomic/styles/theme.css'

type Props = {
  version: 1 | 2
}

const FloatingIllustrations: React.FC<Props> = ({ version }) => {
  return (
    <>
      {version === 1 && (
        <Box display={{ mobile: 'none', desktop: 'flex' }}>
          <Image
            src={v1Image}
            width={346}
            height={428}
            style={{ objectFit: 'contain', objectPosition: 'center', maxWidth: '100%', position: 'absolute', top: -60, right: 0 }}
            sizes={`(max-width: ${ouTheme.breakpoints.desktop}) 100vw, 50vw`}
            alt={''}
          />
        </Box>
      )}
      {version === 2 && (
        <>
          <Box display={{ mobile: 'flex', desktop: 'none' }}>
            <Image
              src={v2Image1}
              width={80}
              height={113}
              style={{ objectFit: 'contain', objectPosition: 'center', maxWidth: '100%', position: 'absolute', top: -50, left: 0 }}
              sizes={`(max-width: ${ouTheme.breakpoints.desktop}) 100vw, 50vw`}
              alt={''}
            />
            <Image
              src={v2Image2}
              width={249}
              height={121}
              style={{ objectFit: 'contain', objectPosition: 'center', maxWidth: '100%', position: 'absolute', bottom: 0, left: 0 }}
              sizes={`(max-width: ${ouTheme.breakpoints.desktop}) 100vw, 50vw`}
              alt={''}
            />
            <Image
              src={v2Image3Mobile}
              width={130}
              height={130}
              style={{ objectFit: 'contain', objectPosition: 'center', maxWidth: '100%', position: 'absolute', bottom: 30, right: 0 }}
              sizes={`(max-width: ${ouTheme.breakpoints.desktop}) 100vw, 50vw`}
              alt={''}
            />
          </Box>
          <Box display={{ mobile: 'none', desktop: 'flex' }}>
            <Image
              src={v2Image1}
              width={140}
              height={197}
              style={{ objectFit: 'contain', objectPosition: 'center', maxWidth: '100%', position: 'absolute', top: -30, left: 0 }}
              sizes={`(max-width: ${ouTheme.breakpoints.desktop}) 100vw, 50vw`}
              alt={''}
            />
            <Image
              src={v2Image2}
              width={400}
              height={217}
              style={{ objectFit: 'contain', objectPosition: 'center', maxWidth: '100%', position: 'absolute', bottom: 0, left: 0 }}
              sizes={`(max-width: ${ouTheme.breakpoints.desktop}) 100vw, 50vw`}
              alt={''}
            />
            <Image
              src={v2Image3}
              width={250}
              height={150}
              style={{ objectFit: 'contain', objectPosition: 'center', maxWidth: '100%', position: 'absolute', bottom: -50, right: -65 }}
              sizes={`(max-width: ${ouTheme.breakpoints.desktop}) 100vw, 50vw`}
              alt={''}
            />
          </Box>
        </>
      )}
    </>
  )
}

export default FloatingIllustrations
