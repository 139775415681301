import { storyblokEditable } from '@storyblok/react'
import StoryblokImage from 'components/storyblokimage/StoryblokImage'
import React, { FC } from 'react'
import { StoryblokContent } from 'types/StoryblokContent'
import styles from './CustomerSatisfactionContainer.module.scss'
import InternalLink from 'components/internallink/InternalLink'
import { useLink } from 'context/LinkContext'
import { useRouter } from 'next/router'

type PropTypes = {
  blok?: StoryblokContent
}
const CustomerSatisfactionContainer: FC<PropTypes> = (props) => {
  const { getLocalePath } = useLink()
  const router = useRouter()
  if (props.blok.link) {
    return (
      <InternalLink href={getLocalePath(props.blok.link.story.url, router.locale)}>
        <div className={styles.container} {...storyblokEditable(props.blok)}>
          <StoryblokImage
            src={props.blok.illustration.filename}
            width={props.blok.illustration_width}
            height={props.blok.illustration_height}
            alt={''}
          />
          <h2 className={styles.heading}>{props.blok.title}</h2>
          <p className={styles.text}>{props.blok.text}</p>
        </div>
      </InternalLink>
    )
  }
  return (
    <div className={styles.container} {...storyblokEditable(props.blok)}>
      <StoryblokImage src={props.blok.illustration.filename} width={props.blok.illustration_width} height={props.blok.illustration_height} alt={''} />
      <h2 className={styles.heading}>{props.blok.title}</h2>
      <p className={styles.text}>{props.blok.text}</p>
    </div>
  )
}

export default CustomerSatisfactionContainer
