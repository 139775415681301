import Heading, { HeadingProps } from 'atomic/components/atoms/heading/Heading'
import * as css from './Heading.css'
import { Container } from 'atomic'
import { BoxProps } from 'atomic/components/atoms/box/Box'
import { FC } from 'react'
import { removeKeys, BuilderProps } from '../helpers/BuilderPropCleaner'
import SmoothScrollToId from 'utils/smooth-scroll-to-id'

type BlogHeadingProps = {
  text: string
  blogHeadingPosition?: 'left' | 'center' | 'right'
  anchorRef?: boolean
  anchorRefName?: string
  anchorLink?: boolean
  anchorLinkName?: string
  modelType?: 'pages' | 'blog'
}

type Props = Pick<HeadingProps, 'as' | 'marginBottom' | 'marginTop' | 'fontWeight' | 'fontFamily' | 'fontSize'> &
  BoxProps &
  BlogHeadingProps &
  BuilderProps

const HeadingComponent: FC<Props> = ({
  as,
  modelType = 'blog',
  fontWeight,
  fontSize,
  text,
  paddingLeft = 'none',
  paddingRight = 'none',
  blogHeadingPosition,
  anchorRefName,
  anchorRef,
  anchorLink,
  anchorLinkName,
  ...props
}) => {
  let blogHeadingFlexPosition
  switch (blogHeadingPosition) {
    case 'left':
      blogHeadingFlexPosition = 'flex-start'
      break
    case 'center':
      blogHeadingFlexPosition = 'center'
      break
    case 'right':
      blogHeadingFlexPosition = 'flex-end'
      break
  }

  // Remove Builder.io specific props
  const cleanProps = removeKeys(props, { builderBlock: true, builderState: true })

  return (
    <>
      {!blogHeadingPosition ? (
        <Container
          className={`${css.headingContainer[modelType]}`}
          paddingLeft={paddingLeft}
          paddingRight={paddingRight}
          id={anchorRef ? anchorRefName : null}
          {...cleanProps}
        >
          <Heading
            as={as}
            fontWeight={fontWeight}
            fontSize={fontSize}
            onClick={
              anchorLink ? () => SmoothScrollToId(anchorLinkName, { scrollMarginTop: '100px', scrollBehavior: 'smooth', scrollBlock: 'start' }) : null
            }
            style={anchorLink ? { cursor: 'pointer' } : null}
          >
            {text}
          </Heading>
        </Container>
      ) : (
        <Container
          className={`${css.headingContainer[modelType]}`}
          paddingLeft={paddingLeft}
          paddingRight={paddingRight}
          justifyContent={blogHeadingFlexPosition}
          id={anchorRef ? anchorRefName : null}
          {...cleanProps}
        >
          <Heading
            as={as}
            fontWeight={fontWeight}
            fontSize={fontSize}
            textAlign={blogHeadingPosition ?? 'left'}
            onClick={
              anchorLink ? () => SmoothScrollToId(anchorLinkName, { scrollMarginTop: '100px', scrollBehavior: 'smooth', scrollBlock: 'start' }) : null
            }
            style={{ cursor: 'pointer' }}
          >
            {text}
          </Heading>
        </Container>
      )}
    </>
  )
}

export default HeadingComponent
