/* eslint-disable @typescript-eslint/no-empty-function */

import { createContext, FC, ReactNode, useContext } from 'react'

import StoryDataInfo from '../types/StoryDataInfo'

type StoryDataContextType = {
  storyDataInfo: StoryDataInfo
}

const StoryDataContextDefaultValues: StoryDataContextType = {
  storyDataInfo: null,
}

const StoryDataContext = createContext<StoryDataContextType>(StoryDataContextDefaultValues)

export function useStoryData(): StoryDataContextType {
  return useContext(StoryDataContext)
}

type PropTypes = {
  children: ReactNode
  storyDataInfo: StoryDataInfo
}

export const StoryDataProvider: FC<PropTypes> = ({ children, storyDataInfo }) => {
  const value = {
    storyDataInfo: storyDataInfo,
  }

  return (
    <>
      <StoryDataContext.Provider value={value}>{children}</StoryDataContext.Provider>
    </>
  )
}
