import React, { FC } from 'react'

import Box from '../box/Box'
import { IllustrationBaseProps } from './Base'

export const Discount25: FC<IllustrationBaseProps> = ({ width = '100%', height = '100%', ...props }) => {
  return (
    <Box {...props}>
      <svg width={width} height={height} viewBox="0 0 314 157" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M50.6287 15.9243C27.5273 15.9243 0.83728 42.8385 0.83728 61.23C0.83728 66.8371 6.22014 73.79 11.603 73.79C13.3973 73.79 15.8644 72.8928 15.8644 69.7528C15.8644 67.7343 12.9487 69.08 12.9487 65.4914C12.9487 51.3614 36.0501 28.4843 49.9558 28.4843C56.0116 28.4843 60.273 31.1757 60.273 35.8857C60.273 55.8471 37.3959 94.2 14.743 118.647C11.603 122.011 8.46299 125.376 8.46299 128.067C8.46299 133.674 14.2944 136.141 20.1259 136.141C27.5273 136.141 39.6387 129.861 62.2916 129.861C72.6087 129.861 79.3373 133.001 81.3559 133.001C82.4773 133.001 84.0473 132.104 84.0473 128.516C84.0473 124.927 71.263 117.301 57.3573 117.301C43.4516 117.301 36.9473 117.974 30.443 121.339C50.1801 98.2371 71.263 62.1271 71.263 37.68C71.263 21.98 54.8901 15.9243 50.6287 15.9243Z"
          fill="currentColor"
        />
        <path
          d="M129.353 70.2014C119.933 70.2014 113.204 72.4443 107.597 77.8271C110.737 64.5943 114.55 42.1657 114.999 32.0728C130.026 32.0728 149.763 30.7271 167.482 23.55C170.173 22.4286 170.622 21.3071 169.949 19.2886C169.276 17.27 166.584 12.3357 164.566 12.3357C163.669 12.3357 158.959 14.13 154.473 15.2514C146.623 17.0457 129.577 19.5128 114.102 19.9614C112.532 17.0457 110.064 14.8028 106.252 14.8028C102.887 14.8028 102.439 15.2514 102.439 18.84C100.644 19.5128 97.953 30.9514 101.093 32.2971C99.9716 42.8386 96.383 76.0328 94.813 89.0414C94.1401 95.0971 104.233 97.34 107.149 97.34C113.429 97.34 111.186 83.8828 130.699 80.7428C142.81 78.7243 156.716 91.7328 156.716 105.863C156.716 125.6 139.67 146.01 128.904 146.01C125.316 146.01 120.157 144.664 118.139 143.319C115.896 141.749 114.999 143.991 114.999 145.337C114.999 146.907 124.419 156.327 136.53 156.327C151.109 156.327 170.397 129.861 170.397 107.209C170.397 76.7057 147.744 70.2014 129.353 70.2014Z"
          fill="currentColor"
        />
        <path
          d="M282.54 7.62571C282.54 5.83143 276.26 0 273.793 0C271.774 0 270.429 0.897143 269.083 3.81286C265.046 12.7843 215.03 119.096 212.114 127.619C210.096 133.226 208.75 137.711 208.75 139.506C208.75 142.646 214.357 144.889 217.049 144.889C219.292 144.889 220.862 141.749 222.432 138.16C232.076 115.731 276.933 22.2043 280.297 15.2514C281.643 12.3357 282.54 9.42 282.54 7.62571ZM228.712 48.8943C228.712 25.3443 219.292 15.2514 209.423 15.2514C204.04 15.2514 192.826 33.6429 192.826 52.4829C192.826 70.2014 202.47 76.0329 210.32 76.0329C218.619 76.0329 228.712 63.2486 228.712 48.8943ZM208.302 62.1271C206.956 62.1271 206.507 58.9871 206.507 54.95C206.507 44.1843 209.872 33.8671 212.563 33.8671C214.357 33.8671 215.03 41.7171 215.03 46.2029C215.03 53.38 210.096 62.1271 208.302 62.1271ZM294.876 76.4814C295.1 75.8086 295.1 75.36 295.1 74.9114C295.1 69.9771 287.25 66.3886 284.559 66.3886C279.624 66.3886 271.102 93.7514 271.102 109.9C271.102 127.394 278.503 137.263 293.306 137.263C303.174 137.263 313.043 115.507 313.043 100.704C313.043 91.7329 304.52 80.5186 294.876 76.4814ZM287.026 125.6C285.456 125.6 285.007 116.629 285.007 110.797C285.007 98.4614 288.596 90.3871 291.287 84.5557C295.549 86.5743 298.913 92.4057 298.913 99.5829C298.913 108.554 291.736 125.6 287.026 125.6Z"
          fill="currentColor"
        />
      </svg>
    </Box>
  )
}
