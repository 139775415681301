import { FC } from 'react'
import { StoryblokContent } from 'types/StoryblokContent'

import { storyblokEditable } from '@storyblok/react'

import ContentContainer from '../../styled/ContentContainer'

import styles from './InspirationSection.module.scss'

type PropTypes = {
  blok?: StoryblokContent
}

const InspirationSection: FC<PropTypes> = ({ blok }) => {
  return (
    <section className={styles.container} {...storyblokEditable(blok)}>
      <ContentContainer>
        <h2>{blok.title}</h2>
      </ContentContainer>
    </section>
  )
}

export default InspirationSection
