import { FC } from 'react'

const CookieIcon: FC<{ className?: string }> = ({ className }) => (
  <svg className={className} width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 800" xmlSpace="preserve">
    <g>
      <path
        fill="#222"
        d="M10,380.9c0.8,0,1.6,0,2.4,0c0.2-1.8-1-3.7,0.2-5.7c1-1.7-0.7-4.2,1.1-6c0-4.4,0-4.4,1.6-7.8
		c0.1-0.3,0.3-0.8,0.2-1c-1.6-2.2,0.5-3.8,0.9-5.6c0.5-2.1,0.7-4.5,1.8-6.3c0.8-1.2,1.1-2.2,1-3.5c0-0.2-0.1-0.5,0-0.7
		c1.4-3,2.8-5.9,4.3-8.8c0.3-0.6,0.9-1.1,1-1.7c1.2-3.7,2.3-7.5,3.4-11.2c0.2-0.7,0.4-1.3,0.6-2c0.7-2,1.4-3.9,1.4-6.3
		c-0.1-2.7,1.9-5.6,3.1-8.3c0.2-0.5,1.1-0.9,1.1-1.3c-0.4-2.5,1.1-4.3,2.5-5.9c2-2.3,2.7-5,3.5-7.8c0.6-2.3,1.3-4.4,3.3-6.2
		c1-0.9,1.9-2.8,2.4-4.3c1.2-3.6,2.9-6.7,5.7-9.2c0.7-0.7,1.2-1.8,1.5-2.8c0.4-1.4,1.3-2,2.3-2.8c1.3-1,2.1-2.7,2.9-4.3
		c0.6-1.1,0.6-2.3,2.3-2.8c1-0.3,1.6-2,2.3-3c0.9-1.4,1.8-2.8,2.8-4.1c1.9-2.4,3.9-4.7,5.8-7.1c1.7-2.1,3.3-4.2,4.9-6.4
		c0.1-0.2,0.2-0.5,0.4-0.6c4.4-1.9,5.2-7.2,9.2-9.5c1.4-0.8,2-2.3,2.7-3.9c0.9-2,3-3.5,4.7-5.1c0.2-0.2,0.8-0.2,0.9-0.4
		c1.7-3.4,4.8-5.8,7.4-8.5c3-3.2,5.1-7.2,9.6-9.1c2.5-1.1,4.3-4.2,6.2-6.5c2-2.4,3.5-4.9,6.7-6.4c2.5-1.2,5.1-3.5,6.4-5.9
		c1.7-3.1,4.5-4.8,6.8-7.2c2.9-2.9,6.3-5.2,8.4-8.9c0.5-0.9,1.4-1.6,2.2-2.2c2.8-2.1,5.6-4.1,7.7-7c2.3-3.1,4.8-6.1,7.6-8.7
		c1.4-1.3,2.9-2.7,4.4-3.9c5.2-4.1,8.7-9.7,13.2-14.4c2.8-2.8,5.6-5.6,8.4-8.4c0.4-0.4,0.9-0.8,1.2-1.3c1.4-2,3-3.7,5.1-5.1
		c2.4-1.5,4.3-3.8,6.5-5.6c0.9-0.7,2-1,3.1-1.4c2.4-0.9,4.9-1.7,6.2-4.3c0.4-0.8,1.6-1.3,2.5-1.9c1.1-0.6,2.4-0.9,3.4-1.6
		c1.6-1.2,3.6-1.8,5.1-2.8c2.1-1.5,4.5-2.3,6.6-3.7c2.2-1.5,4.5-2.6,6.8-3.9c0.5-0.3,1.1-0.6,1.6-0.5c1.6,0.4,2.6-0.4,3.9-1.2
		c1.3-0.9,2.9-1.2,4.4-1.9c4-1.9,7.9-3.9,11.9-5.9c1.5-0.7,3-1.8,4.5-1.8c2.3,0,4.2-1,6-1.9c2.4-1.3,4.8-1.9,7.5-1.9
		c1.8,0,3.1-0.4,4.5-1.7c1.1-1,2.6-1.8,4-2c4.9-0.8,9.4-3.7,14.7-3.2c1.1,0.1,2.4-0.6,3.5-1.2c3.2-1.7,6.6-2,10.1-1.8
		c3.3,0.2,6.4-0.5,9.6-1.1c2.5-0.5,4.9-1,7.3-1.7c0.5-0.1,1.2,0,1.7,0.1c2.1,0.5,6.8,0.2,8.8-0.5c0.9-0.3,1.9-0.2,2.8-0.2
		c1.3,0,2.6,0.5,3.8,0.2c2.1-0.5,4-1.4,6-2.1c2,1.7,4,0.5,6,0.1c0.6-0.1,1.4-0.4,1.8-0.9c1.1-1.5,2.8-1.1,3.9-0.8
		c3.8,1,7.2,0.4,10.8-0.9c2.9-1,6-2.3,9.2-1c0.5,0.2,1.2,0,1.8-0.1c0.2,0,0.4-0.2,0.6-0.3c1.9-1.7,4.4,0.8,6.6-1.4
		c1.7-1.8,4.4-0.6,6.7-0.2c1.4,0.3,3,0.2,4.3-1.1c1.3-1.3,3.2-2,4.9-1.4c1.6,0.6,2.8,0,4-0.5c2-0.9,3.9-1.6,6.1-0.5
		c0.5,0.3,1.4-0.1,2-0.3c1.6-0.5,3-1.9,5-0.9c0.2,0.1,0.7-0.4,1.1-0.5c0.8-0.3,1.6-0.7,2.3-0.6c5.3,0.5,6.3,0.2,12.3-1.7
		c2-0.6,4.4-0.1,6.6,0c0.8,0,1.7,0,2.5,0.3c1.5,0.7,2-0.5,3.2-1.8c0.4,0.7,0.7,1.3,1.3,2.2c0.6-2,2.2-1.6,3.4-1.5
		c4,0.5,8.1,0.3,12.1,0.1c3.5-0.2,6.6,1.2,10,1c2.5-0.1,5,0,7.5,0c2.1,0,4.2-0.2,6.7-0.3c1.2,2.3,3.5,3.6,6.5,3.9
		c2.3,0.3,3,2,3.7,3.8c1.1,2.8,2.7,5.6,3.3,8.5c0.7,3.1,2.4,5.6,3.7,8.4c1.4,3,2.3,6.3,3.4,9.5c0.2,0.4,0.1,1.1,0.4,1.3
		c2.1,1.6,1.8,4.1,2,6.3c0.1,1.2,0.5,2.1,0.9,3.2c1,2.6,1.8,5.2,2.4,7.9c0.1,0.5-0.6,1.2-1,1.9c0.5,0.3,0.9,0.7,1.4,1
		c-0.2,0.5-0.3,0.9-0.4,1.2c-1.6,2-3.2,3.9-4.8,6c-0.8,1.1-1.5,2.4-2.2,3.6c-1.3,2.1-2.4,4.3-4,6.1c-2.4,2.7-4.5,5.3-5.4,8.9
		c-0.3,1.4-1.5,2.6-2.4,3.9c-1.7,2.4-3.9,4.4-4.2,7.6c-0.1,1.3-1.1,2.6-2,3.7c-2.7,3.5-4.4,7.4-5.8,11.5c-0.2,0.6-0.6,1.4-1.2,1.7
		c-2.8,2-3.6,5.3-5.6,7.9c-1.5,2-2,4.7-3,7c-0.5,1.2-1.3,2.2-1.8,3.4c-0.3,0.8-0.6,1.8-0.6,2.6c-0.1,2.8-0.3,5.6-2.1,8
		c-0.7,1-1.4,2.3-1.5,3.5c-0.2,4.1-2.8,7.4-3.6,11.6c2.4-1,5.1-0.4,7.2-2.2c0.2-0.2,0.7-0.3,1-0.3c4.3,0.4,7.9-1.4,11.5-3.5
		c1.7-1,4-1.2,6-1.8c1.1-0.3,2.6-0.3,3.2-1c2-2.4,4.7-2,7.3-2.5c1-0.2,2.1,0,3-0.4c2.8-1.3,5.7-2.5,8.3-4.1c3-1.9,6.4-2.2,9.7-2.2
		c2.6,0,4.3-1.6,6.5-2.1c3.6-1,7.3-1.3,10.9-2c1.2-0.2,2.3-0.5,3.5-0.7c0.7-0.1,1.4-0.2,2.1,0c2.4,0.8,4.8,1.7,7.1,2.6
		c0.4,0.2,1,0.4,1.2,0.7c1.4,2.8,3.4,4.9,6,6.6c0.7,0.5,0.6,2.2,1.1,3.2c1.4,2.5,3.2,4.8,4.6,7.3c1.2,2,2.5,4.1,3,6.3
		c0.9,4.1,5.1,6.5,5.3,10.8c0,0.8,1,1.6,1.6,2.3c0.6,0.7,1.4,1.4,1.8,2.2c0.5,1.2,1,2.6,0.7,3.8c-0.8,3.2,1.2,6.5-0.8,9.6
		c-1.6,2.5-3,5.3-4.5,7.8c-0.4,0.6-0.9,1.1-1.5,1.5c-2.2,1.7-4.4,3.5-6,5.9c-0.7,1-2.4,1.3-3.1,2.3c-1.1,1.6-3.2,2.3-3.7,4.5
		c-0.2,0.9-1.5,2-2.6,2.3c-3.5,0.9-5.3,3.4-6.7,6.5c-0.4,1-1.6,1.6-2.4,2.4c-3.5,4-7.2,7.7-11.5,10.8c-0.9,0.7-1.7,1.7-2.2,2.7
		c-1.6,3.2-2.9,6.6-6.1,8.8c-0.8,0.5-0.6,2.2-1.1,3.2c-1.3,2.6-3.1,5-3.9,7.7c-0.6,1.8-1.1,3.4-2.1,5c-0.4,0.6-0.4,1.7-0.1,2.3
		c0.4,0.9,0.7,1.4-0.3,2c-0.3,0.2-0.6,0.7-0.6,0.8c1.1,1.6-0.2,2.8-0.6,4.2c-0.1,0.3,0.1,0.7,0.1,0.8c2,0.3,4,1,5.8,0.8
		c2.2-0.3,4.5-0.3,6.6-1.7c2.1-1.3,4.9-1.6,7.5-2.2c2.1-0.5,4.3-0.7,6.5-1c0.5-0.1,1.2-0.1,1.3-0.4c0.8-2.4,3.3-2,4.9-2.9
		c1.6-1,3.7-1.2,5.6-1.7c2.3-0.6,4.7-0.6,6.6-2.5c0.6-0.6,2-0.5,3-0.9c1.7-0.6,3.5-1.4,5.2-2.1c1.5-0.7,2.9-1.9,4.4-2.1
		c2.8-0.3,5.3-1.3,7.6-2.7c2.3-1.5,4.8-0.4,7.2-1c2.7-0.7,5.4,0.3,8.1,0c0.6,0,1.4,0.4,1.8,0.8c1.9,2.3,4,4.4,6.4,6.3
		c1.9,1.5,3,4.1,4.3,6.3c2.9,5.1,6.3,9.8,9.8,14.5c0.3,0.4,0.6,0.8,0.6,1.2c0,3.6,2.7,5.7,4.8,8c0.8,0.9,1.2,1.7,1.4,3
		c0.4,2.5,1.3,4.9,2.1,7.3c0.4,1.3,0.5,1.2-1.1,3.2c0.5,0.4,1,0.9,1.6,1.5c-0.8,1-1.4,2.1-2.3,2.9c-3,2.4-5.8,5.1-9.2,6.9
		c-2.4,1.3-3.9,3.1-5.4,5.1c-2.5,3.2-5.6,6-8,9.3c-1.8,2.4-3.6,4.8-5,7.5c-1.5,3-3.6,5.6-5.2,8.6c-1.6,2.9-3.7,5.4-4.3,8.9
		c-0.5,2.7-2.6,5-3,7.7c-1.1,6.8-1.8,13.7-2.6,20.5c0,0.3,0.2,0.9,0,1c-1.6,1.4-2,2.9-1.1,5c0.6,1.5-1.1,3.3,0.1,5.1
		c0.4,0.6-0.2,1.8-0.3,3.2c1.6,0.7,3.5,2.2,5.1,2c2.2-0.4,4.3,1.4,6.4,0c0.4-0.3,1.3-0.2,1.7,0.1c2.3,1.7,4.2,0.2,6.4-0.5
		c2.8-1,5.7-1.7,8.6-1.9c6.9-0.5,13.5-2.3,20.1-4.3c6.5-2,12.8-4.6,19.3-6.1c4.5-1.1,9-3,13.9-1.2c2,0.8,4.5,0.7,6.7,0.6
		c4.1-0.2,6.7,2.2,8.7,5.2c2.1,3.1,4.8,5.9,5.4,9.8c3.8,3.9,4.1,9.5,6.8,14c2.5,4.1,4,8.7,5.9,13.1c0.2,0.5,0.4,1.1,0.8,1.6
		c2.5,2.8,2.5,6.6,4,9.8c0.1,0.2,0.1,0.5,0,0.7c-1.5,2.3-0.7,4.4,0.7,6.4c0.2,0.3,0.2,1.2,0,1.4c-1.8,1.4-2,2.9-0.6,4.8
		c0.2,0.2-1,1.2-1.1,1.9c-0.4,2.8-1,5.6-0.8,8.3c0.2,1.8-0.3,2.8-1.7,3.8c1,1.3,1.7,2.7,0.4,3.8c-1.2,1-1.3,2.1-1.6,3.4
		c-0.2,0.9-0.5,1.8-0.8,2.7c-0.4,1.2-0.7,2.5-1.4,3.6c-0.6,0.9-0.3,1.9-0.4,2.7c-0.3,3.2-1.5,6.1-2.8,8.9c-0.1,0.3-0.3,0.7-0.3,1
		c0.7,3.9-1.7,7.1-2.2,10.8c-0.1,0.9-0.9,1.9-1.5,2.7c-1.8,2.4-3.6,4.9-3.8,8.1c0,0.2,0,0.6-0.2,0.7c-4.2,2.3-3.4,8.1-7.5,10.4
		c-0.2,0.1-0.5,0.3-0.5,0.5c-0.1,4.5-3.7,7.1-5.8,10.5c-1.4,2.4-2.6,5.3-5.8,6.3c-0.4,0.1-1,0.9-0.9,1.3c0.4,2.3-1.3,3.6-2.5,4.8
		c-1.9,1.9-2.2,4.9-4.8,6.3c-3.7,2.1-5,6.2-7.3,9.5c-0.9,1.2-2.2,2-3.3,3.1c-1,1-1.9,2-2.7,3.1c-0.5,0.7-0.6,1.9-1.3,2.4
		c-4.1,3.3-7.1,7.7-10.7,11.6c-1.2,1.3-2.2,3.2-4.5,3c-0.4,0-1.2,0.8-1.4,1.4c-0.7,2.6-2.6,3.5-4.8,4.4c-1.1,0.4-2.5,1.5-2.6,2.3
		c-0.2,1.8-1.3,2.4-2.3,3.4c-1.5,1.5-2.7,3.2-4.3,4.6c-1.4,1.3-3,2.2-4.5,3.4c-1.4,1.1-2.6,2.3-4,3.4c-1.8,1.5-3.9,2.7-5.3,4.4
		c-1.5,1.8-3.1,3.3-5.3,4.3c-0.9,0.4-1.4,1.5-2.1,2.3c-0.5,0.6-0.8,1.5-1.4,1.9c-3.4,2.4-6.8,4.5-10.2,6.9c-2,1.4-3.9,2.9-5.5,4.7
		c-3,3.5-6.8,5.8-10.8,8.1c-0.7,0.4-1.7,0.7-1.9,1.4c-0.9,2.3-3,2.9-4.9,3.6c-0.5,0.2-1.4,0.4-1.5,0.7c-1,3.1-4,2.9-6.2,4.1
		c-1.8,1-3.4,2.4-5.1,3.5c-1.1,0.7-2.3,1.7-3.6,1.8c-1.8,0.1-2.8,1.1-3.6,2.4c-1.6,2.1-3.6,3.5-5.9,4.7c-3.3,1.8-7.1,2.9-9.7,5.8
		c-0.6,0.7-1.7,1.4-2.5,1.3c-2.3-0.3-3.6,1.3-5.3,2.3c-2,1.2-4,2.7-6.6,1.9c-0.7-0.2-1.6,0.1-2.3,0.4c-2.8,1.4-5.4,3.1-8.3,4.5
		c-3.4,1.6-6.9,3.1-10.4,4.4c-1.6,0.6-3.5,0.3-5.1,1c-1.9,0.8-3.7,1.6-5.9,1.2c-1.3-0.2-2.9,0.3-4,1.1c-2.7,1.8-5.8,1.9-8.7,2.8
		c-0.3,0.1-0.7,0.2-1,0.4c-3.4,2.5-3.3,2.6-5.8,2.9c-2.3,0.3-4.5,1.5-6.9,0.4c-0.6-0.3-1.7-0.3-2,0.1c-1.6,2-3.8,1.5-5.8,1.9
		c-0.8,0.2-1.7,0.3-2.3,0.7c-3.8,2.3-8,3.7-12.3,4.4c-2.8,0.5-5.6,0.9-8.3,1.8c-0.4,0.1-1,0.3-1.4,0.2c-2.9-1.2-5.4,0.1-8,1.1
		c-1.2,0.5-2.7,0.3-4.1,0.5c-2.7,0.5-5.3,1.5-8.1,0.9c-3.6,2.2-7.5,2-11.5,2.3c-3,0.2-6.1-0.2-9.1,0.3c-0.8,0.1-1.5-0.1-2.3,0.5
		c-0.6,0.5-1.7,0.9-2.3,0.6c-3.8-1.7-7.1,1.8-10.8,0.8c-1.4-0.3-2.9,1.1-4.6,0.3c-0.9-0.4-2-0.2-3-0.3c-2.3-0.4-4.5-0.1-6.4,1.1
		c-2.4,1.5-4.4,0-6.5-0.2c-0.1,0-0.2-0.2-0.2-0.2c-2.4,0-5.1-0.8-7,0.1c-2.5,1.1-4.8,0.6-7.2,0.7c-5.4,0.3-10.7-0.2-16-1.7
		c-0.4-0.1-0.9,0.1-1.2,0.3c-2,1.3-3.3,0.1-4.7-1.3c-0.4,0.4-0.8,0.7-1.5,1.3c-0.3-0.5-0.6-0.9-1-1.5c-1,0.3-2.6,1.2-3.1,0.8
		c-1.9-1.5-4,0.3-5.9-0.8c-1.9-1-4.3-0.8-6.2-2.3c-0.8-0.7-2.3-0.7-3.4-0.8c-2.6-0.2-5-0.9-7.2-2.4c-0.5-0.3-1.1-0.7-1.6-0.6
		c-2,0.4-3.3-1.1-4.8-1.9c-0.7-0.3-1.4-1.1-2.1-1c-2.1,0.3-3.7-0.5-5.1-1.9c-1.6-1.7-4.3-2-5.5-4.2c-0.1-0.2-0.7-0.4-0.9-0.3
		c-3.6,0.8-5.6-2.8-8.7-3.3c-0.5-0.1-0.8-1.1-1.3-1.6c-0.6-0.6-1.1-1.3-1.8-1.6c-2.1-0.8-4.2-1.5-6.3-2.2c-1-0.3-2.1-0.4-2.4-1.7
		c-0.1-0.4-0.8-0.8-1.3-1.1c-3.3-1.8-6.9-3.2-9.8-5.5c-3.7-3-8.1-5-11.9-7.8c-2-1.4-4.2-2.4-6.3-3.7c-1.6-1-3.4-1.9-4.5-3.3
		c-2.2-2.7-5.4-3.6-8.1-5.4c-1.3-0.8-2.3-2-3.5-3c-0.3-0.3-0.7-0.9-1-0.9c-1.9,0.3-2.3-1.3-3.3-2.3c-1.1-1-2.4-2.1-3.7-2.5
		c-2.8-0.8-4.9-2.4-6.5-4.9c-1-1.6-2.5-2.7-4.2-3.7c-3.8-2.2-7.7-4.4-8.8-9.3c-2.8-0.1-4.5-1.8-6.4-3.5c-2.5-2.3-4.6-5-7.3-7.3
		c-3.3-2.7-6.4-5.9-8.5-9.8c-0.7-1.3-1.7-2.8-3-3.4c-4.1-2-6-5.8-8.4-9.1c-1-1.4-2.3-2.2-3.5-3.3c-0.9-0.8-1.9-1.6-2.6-2.7
		c-1-1.5-1.2-3.6-3.5-4.1c-0.5-0.1-0.7-1.2-1.2-1.7c-1-1.3-2.2-2.4-3.2-3.7c-0.6-0.8-1.5-1.8-1.6-2.7c-0.2-2-1-3.3-2.8-4.1
		c-0.5-0.2-0.8-0.9-1-1.4c-1-2.2-2.2-4.2-4-5.8c-0.5-0.4-0.6-1.2-1-1.8c-0.4-0.7-0.6-1.5-1.2-2c-4.1-3.7-6.6-8.7-9.7-13.1
		c-1.3-1.9-2.8-3.5-4.1-5.4c-2.1-3-3.8-6.4-6.5-9c-0.5-0.5-0.4-1.5-0.7-2.3c-0.4-1.5-0.7-3.1-2.5-3.5c-0.3-0.1-0.5-0.8-0.7-1.2
		c-0.4-0.8-0.7-1.9-1.3-2.5c-1.1-0.9-1.2-1.9-1.4-3.1c-0.2-1.1-0.8-2.1-1.3-3.1c-1.2-2.4-2.4-4.8-3.6-7.1c-0.5-0.9-0.8-2.2-1.6-2.6
		c-2.3-1.1-2.5-3.3-3.1-5.3c-0.7-2-1.1-4.2-2.4-5.7c-0.9-1.1-2.5-1.8-3.5-3.1c-2-2.7-5-5.1-5.9-8.2c-1.2-4.3-4.8-7-6-11.2
		c-0.5-1.8-2.2-3.4-3.6-4.7c-1.3-1.2-1.2-2.6-1.4-4c-0.2-1.1-0.5-2.5-1.3-3c-2.9-1.8-3.9-4.8-4.8-7.6c-0.8-2.4-3.1-3.9-3.4-6.7
		c-0.3-2.4-1.4-4.7-2.2-7c-0.3-0.9-0.7-2-1.3-2.4c-1.2-0.6-0.1-0.8-0.1-1.2c0-1.2-0.3-2.5-0.9-3.6c-1.8-3.6-3.9-7.1-5.5-10.8
		c-1.7-4-3-8.1-4.4-12.2c-0.5-1.3-0.9-2.7-1.4-4c-1.4-3.3-2.3-6.8-3-10.4c-0.5-2.5-1.8-4.8-2.5-7.3c-1-3.6-1.2-7.1-1-10.7
		c0.1-2.2,0.1-4.8-2.4-6.3c1-1.5,1.6-3,0.5-4.8c-1-1.6-1.8-3.2-0.9-5.4c0.5-1.1-0.2-2.8-0.5-4.2c-0.2-0.9-0.9-1.7,0.1-2.6
		c0.3-0.2,0.4-1.1,0.2-1.3c-2-1.7-1.2-3.9-1.3-6.1c-0.8-0.1-1.4-0.2-2.1-0.2c0-0.2,0-0.5,0-0.7c0.5-0.1,1.6-0.2,1.6-0.4
		c0.1-2,0.2-4.1,0.1-6.1c0-0.2-1.1-0.4-1.7-0.6c0-0.2,0-0.5,0-0.7c0.9,0.1,2,0.2,2.2-1.1c0.2-1.6-0.7-2.3-2.2-2.5
		C10,381.3,10,381.1,10,380.9z M456.1,76.2c-2.2,0.4-3.4,1.1-4.6,0.9c-2.8-0.5-5,1.9-7.7,1.5c-2.9,2.3-6.7,1.9-9.9,3
		c-4.6,1.6-9.5,2.8-14.3,4c-3,0.8-6.1,1.3-9.1,1.9c-1.4,0.3-2.9,0.3-4.1,1c-3.6,2.1-7.7,2.2-11.5,3.5c-1,0.3-2.1,0.3-3.1,0.4
		c-1,0.1-2-1.4-3,0.3c-0.2,0.4-1.9-0.1-2.9,0c-0.9,0.1-2.1,0-2.7,0.6c-2.8,2.2-6.2,1.5-9.3,2.3c-2.2,0.6-4.9,1.2-7.3,0.8
		c-3-0.5-5.5,1.1-8.2,1.5c-1.3,0.2-2.5,0.6-3.8,0.8c-3.5,0.4-7.2-0.8-10.5,1.3c-0.3,0.2-0.9-0.2-1.3-0.2c-2.4,0.5-4.7-1-7.1,0
		c-1.1,0.5-2.5,0.4-3.7,0.6c-1.2,0.1-2.5,0.1-3.7,0.3c-1.4,0.2-2.7,0.5-4.1,0.7c-0.9,0.1-1.8-0.1-2.7,0.1c-2.7,0.6-5.3,1.4-8,2
		c-4.2,0.9-8.7,0.8-12.5,3.3c-0.1,0.1-0.2,0.1-0.3,0c-3.4-0.8-6.2,1-9.3,1.8c-3.8,1-7.5,2.3-11.6,2.1c-1.1-0.1-2.3,0.5-3.3,1
		c-1.7,1-3.4,1.8-5.4,1.9c-1.7,0.1-3.4,0.6-5.1,1c-3.4,0.7-6,4-9.9,3.2c-0.1,0-0.2,0.1-0.3,0.2c-1.6,1-3,2.2-4.7,2.8
		c-2,0.7-4.2,0.6-6.2,1.4c-1.9,0.8-3.5,2.5-5.4,3.2c-2.9,1.1-6.4,1-8.1,4.3c0,0.1-0.2,0.1-0.3,0.2c-4,2.6-9.5,2.8-12.5,7.2
		c-0.3,0.4-1,0.7-1.5,0.8c-2.4,0.4-4.1,1.7-5.4,3.7c-0.5,0.7-1.4,1.3-2.1,1.8c-2.2,1.4-4.8,2.1-6.7,4.2c-1.1,1.2-2.4,2.1-3.7,3.1
		c-2.9,2.3-5.8,4.7-8.6,7c-0.5,0.4-1,1-1.3,1.7c-0.7,1.8-1.9,3-3.6,3.7c-2,0.9-3.1,2.5-4,4.3c-1,1.8-2.1,3.5-3.5,5.1
		c-2.2,2.7-5.8,4.1-7.3,7.5c-0.3,0.6-0.6,1.4-1.1,1.8c-4.9,2.9-7.3,8.8-12.8,11c-0.6,0.3-0.9,1.3-1.4,2c-1.2,1.5-2.3,3-3.7,4.3
		c-1.7,1.5-3.6,2.8-5.4,4.3c-0.7,0.6-1.1,1.5-1.6,2.2c-0.4,0.6-0.7,1.2-1.2,1.7c-1.4,1.6-2.9,3.1-4.4,4.6c-0.6,0.6-1.1,1.4-1.8,1.6
		c-3,1.1-4,3.8-5.2,6.2c-0.4,0.8-0.5,1.4-1.3,2c-1.9,1.3-3.2,3.2-4.2,5.4c-0.8,1.7-1.7,3.5-3,4.8c-1.7,1.7-2.9,3.6-3.7,5.9
		c-0.6,1.8-1.5,3.3-3.2,4.4c-0.7,0.5-1.5,1.2-1.9,2c-2.5,4.8-5.9,8.9-8.4,13.7c-0.5,0.9-1.5,1.7-1.6,2.6c-0.3,2.2-1.6,3.6-2.9,5.1
		c-0.4,0.4-0.9,0.9-1,1.3c-0.3,2.1-1.6,3.6-3.1,5c-1.3,1.3-1.2,3.5-2.7,4.7c-1.3,1.1-1.7,2.4-1.9,4c-0.2,2.2-1,4-2.5,5.7
		c-1.1,1.2-2.3,2.7-2.4,4.2c-0.5,4.3-3.9,7.2-4.8,11.2c-0.3,1.5-1.1,3-1.6,4.5c-1.7,5.7-4.3,11.1-5.4,17.1
		c-1.1,6.1-1.9,12.4-4.6,18.2c-0.7,1.6-0.6,3.6-1.1,5.4c-0.5,1.8,0.9,3.8-0.8,5.3c1.3,3.3-1.2,6.1-1.2,9.2c0,1.6-0.1,3.3-0.4,4.9
		c-0.5,2.9-1.9,5.6-0.9,8.6c0.1,0.2-0.4,0.5-0.5,0.8c-0.2,0.5-0.6,1.3-0.4,1.5c1.1,1.1,0.3,1.9-0.3,2.8c-0.3,0.5-0.6,1.5-0.5,1.6
		c2.3,1.2,0.4,2.2,0,3.4c-0.6,1.9-1.1,3.9-1,5.8c0.1,1.6,0.5,3.3-0.4,4.6c-1.4,1.9,0.9,3.2,0.2,4.9c-0.1,0.2-0.2,0.5-0.2,0.7
		c-0.4,3.1-0.7,6.2-1.1,9.3c-0.2,1.9-0.7,3.8-0.7,5.7c0.1,6.7,0.8,13.4,0.3,20.1c-0.6,7.7,1,15,2.7,22.3c1.5,6.5,3,13.2,5.4,19.5
		c1.5,3.9,1.9,8.2,3.5,12.2c2.4,6,4.2,12.2,6.5,18.2c1.5,3.9,3.8,7.6,5.6,11.4c1.2,2.6,2.5,5,5,6.6c0.8,0.5,1.5,1.6,1.7,2.5
		c0.6,2.6,2.2,4.3,4.5,5.6c3.9,2.3,7.2,5.2,9.1,9.5c0.3,0.6,0.8,1.2,1.4,1.5c3.7,1.7,5.8,5.1,8,8.2c0.8,1.2,1.6,3.1,2.6,3.2
		c2.1,0.3,3.1,2,3.8,3.1c1.5,2.2,3.4,3.5,5.6,4.7c0.7,0.4,1.3,1,1.9,1.6c2.5,2.3,5,4.6,7.4,6.9c1.2,1.1,2.3,2.3,3.4,3.5
		c0.5,0.5,0.9,1,1.4,1.4c1.7,1.3,3.4,2.6,5.2,3.7c3.3,2.1,5.6,5.3,8.8,7.5c2.3,1.6,4,4,6.2,5.9c1,0.9,2.4,1.3,3.5,2.1
		c6.1,4.6,11.8,9.9,18,14.4c1.5,1.1,2.6,2.5,4.6,3c0.5,0.1,1,0.6,1.5,1c3.6,2.6,7,5.5,10.8,7.8c2.4,1.5,4.5,3.5,7.5,4.1
		c0.5,0.1,1,0.6,1.4,0.9c1.9,1.7,3.7,3.5,5.7,5c2.9,2.2,6.9,2.9,8.5,6.7c0,0.1,0.4,0,0.6,0.1c2.3,1.2,4.9,2.2,7,3.8
		c4,3.2,8.8,5.4,12,9.6c0.2,0.3,0.9,0.3,1.2,0.6c2.8,2,6.4,2,9.2,4.1c2,1.5,5,2,6.6,3.8c1.6,1.8,3.6,2.7,5.4,2.9
		c3.3,0.5,5.6,1.7,6.8,4.8c0.1,0.2,0.3,0.3,0.5,0.5c0.8,0.1,1.8-0.1,2.4,0.3c2.5,1.4,4.9,3,7.4,4.4c0.8,0.4,1.8,0.8,2.6,0.8
		c2.6-0.1,4.5,0.9,6.4,2.5c0.4,0.3,0.9,0.6,1.4,0.8c2.8,0.8,5.4,1.9,7.4,4.1c0.4,0.5,1.6,0.7,2.2,0.5c1.8-0.6,3.2-0.1,4.8,0.7
		c1.4,0.7,2.8,1.2,4.3,1.6c4.8,1.2,9.7,2.8,14.6,3.4c3.2,0.4,6.2,2.1,9.6,1.4c1.3-0.3,3,0.4,4.3,1c3,1.4,5.5,1.6,8.6,1.1
		c2.2-0.4,4.7-0.7,6.6,0c2.1,0.8,3.9,0.4,5.9,0.2c1.5-0.2,3-0.9,4.5-0.9c1.8,0,3.3-0.2,4.6-1.3c1.2-1,2.4-1,3.9-0.4
		c1.2,0.5,3.2,0.9,4.1,0.3c1.4-1,2.7-0.7,4.1-0.8c1.2-0.1,2.4-0.6,3.6-1c0.6-0.2,1.4-0.6,1.9-0.4c1.6,0.5,2.8-0.1,3.8-1.2
		c1.1-1.3,2.5-1.6,4.2-1.2c0.7,0.2,1.6-0.1,2.2-0.5c1.7-1.1,3.1-2.8,5.6-2.1c0.6,0.2,1.7-0.6,2.4-1.1c1.6-1.4,3.3-2,5.5-1.6
		c0.7,0.1,1.7-0.4,2.4-0.9c3.4-2.5,7.5-3.7,11.4-5.1c1.9-0.7,4.1-0.6,5.8-2.1c1-0.8,2.5-1,3.8-1.4c0.3-0.1,0.9,0.2,1,0.1
		c2-2.4,5.2-2.5,7.7-4c1-0.6,2.1-1.1,3.2-1.4c1.9-0.5,4.1-0.1,5-2.5c1.9,0.3,2.6-1.6,4.1-2.1c2.3-0.9,4.8-1.6,6.5-3.7
		c0.3-0.3,0.8-0.6,1.2-0.6c2-0.4,3.6-1.4,5.3-2.5c2.6-1.7,5.1-4,8.2-4.5c1.7-0.3,2.8-1.1,4.2-1.9c1.7-1,3.4-2.1,5.6-1.9
		c0.6,0.1,1.5-0.3,2-0.7c2.6-2.9,6.9-3.5,9.1-6.9c0.2-0.2,0.6-0.3,0.9-0.5c2.1-1.2,4.3-2.3,6.3-3.7c2.3-1.7,4.3-4,6.7-5.5
		c4-2.5,6.8-6.2,10.7-8.8c2.4-1.6,4.3-3.9,6.5-5.8c2.6-2.2,5-4.8,8.3-6.3c0.6-0.3,1-0.9,1.5-1.4c1.8-2.2,3.2-4.8,5.3-6.5
		c3.6-2.9,6.8-6,10-9.2c2.4-2.4,5.2-4.3,7.6-6.6c2.2-2,3.9-4.8,6.4-6.3c3.8-2.2,7-4.8,9.6-8.3c0.8-1,1.8-1.8,2.6-2.9
		c2.1-2.7,4.2-5.5,6.2-8.2c0.6-0.8,1.5-1.6,1.8-2.6c1.2-3.7,3.8-6.4,6.4-9.2c2.6-2.8,3.8-6.7,6.8-9.2c0.1-0.1,0.1-0.2,0.1-0.3
		c0.8-1.3,1.5-2.6,2.3-3.8c0.6-0.9,1.6-1.6,1.8-2.5c0.5-1.9,1.4-3.4,2.8-4.7c0.7-0.7,0.9-1.9,1.4-2.8c0.9-2.1,1.5-4.2,2.6-6.2
		c0.7-1.3,1.4-2.6,2.1-3.9c1.7-3.2,2.7-6.6,4.6-9.7c1.3-2.2,3.6-4.3,3.2-7.7c-0.2-1.8,0.9-3.8,1.7-5.6c1-2.3,3.2-4.2,2.4-7.2
		c-0.1-0.4,0.2-0.9,0.5-1.3c0.9-1.5,1.4-3.3,2.6-4.7c0.4-0.5,0.8-1.7,0.7-1.8c-1.3-0.8-0.2-1.3,0-2.1c0.6-3.3,3.3-6.2,1.8-9.9
		c-0.3,0.1-0.8,0-1,0.2c-1.6,2.2-3.8,2.3-6.3,2.1c-1.3-0.1-2.7,0.4-4,0.7c-1,0.2-2,0.8-3.1,0.8c-4.5,0.1-9,0.1-13.6,0.2
		c-0.6,0-1.3,0.1-1.7,0.5c-2.6,3-6,0.5-8.9,1.3c-1.8-2.4-4.6-0.8-6.8-1.5c-0.9-0.3-1.9-0.7-2.8-0.5c-1.8,0.3-3.3,0.1-4.8-0.9
		c-0.7-0.5-1.7-0.9-2.6-0.8c-3.7,0.3-6.7-1.4-9.9-2.9c-1.5-0.7-2.9-1.6-4.4-2.2c-2.9-1.1-5.8-2.4-7.6-4.8c-1.5-2.1-2.7-4.2-5.5-4.6
		c-0.5-0.1-1.2-0.7-1.3-1.1c-0.2-1.1-1.2-1.3-1.7-1.9c-2.9-3.3-6.7-5.9-7.4-10.7c-0.1-0.4-1-0.7-1.6-1c0.4-0.5,1.7-0.5,0.1-1.5
		c-1.5-1-2.6-2.8-3.7-4.4c-0.4-0.5,0.1-1.9-0.3-2.2c-1.4-0.9-1.4-2.3-1.7-3.7c-0.4-2.2-2.1-4.1-1.8-6.5c0-0.3-0.4-0.9-0.8-1.1
		c-1.6-1-1.7-2.4-1.6-4c0.1-1.9,0.1-3.7-0.1-5.6c-0.3-2-0.9-4-1.5-6.3c0.6-0.9,1.4-2.2,2.4-3.7c-0.9-0.5-1.6-0.9-2.4-1.3
		c1.8-2.5,3.5-5,1.7-8.4c-0.5-0.8,0.2-2.3,0.3-3.4c0.3-2.3,1.7-4.4,1.2-7c-0.3-1.6,0.1-3.4,0.7-5c0.8-2,2-3.8,3.1-5.7
		c2.7-4.3,4.3-9.2,7.2-13.5c2.3-3.5,3.1-8,6.6-10.8c0.2-0.2,0.1-0.7,0.2-1.1c-2.1-1-4.5-1.7-6.4-0.3c-1.2,0.9-2.4,0.8-3.6,1.2
		c-0.8,0.3-1.9,0.1-2.7,0.5c-2.4,1.3-4.9,1.8-7.6,1.2c-0.5-0.1-1.4-0.1-1.7,0.2c-1.8,1.8-3.9,0.5-5.9,0.6c-0.8,0-1.5,0-2.3,0
		c-2.5,0-5.2,0.7-7.4,0c-3.5-1.1-7.5,1.9-10.5-1.5c0,0-0.2,0-0.3,0c-4.1,0.1-7.7-1.3-10.8-3.8c-1.5-1.2-3.2-2.2-5-2.1
		c-1.7,0-2.7-0.8-3.6-1.9c-2.3-2.6-4.5-5.3-6.7-7.9c-0.8-1-1.7-1.9-2.6-2.8c-1.3-1.3-2.8-2.5-3.8-4c-0.9-1.3-1.9-3-2-4.6
		c-0.3-4-1.5-7.9-1.5-12c0-1.6-1-3.1-1.7-5c0.1-0.4,0.4-1.3,0.6-2.2c0.4-2.9,0-5.9,0.8-8.8c0.5-1.8,1.2-3.8,0.8-5.5
		c-0.7-2.8,0.7-4.4,2.4-6.1c0.3-0.3,0.8-0.5,1.1-0.8c0.1-0.1,0-0.4-0.1-0.7c-0.5,0-1.1,0.1-1.7,0.1c0.2-1,0.1-2.4,0.4-2.4
		c2.8-0.7,1.8-3.5,2.8-5.1c0.5-0.8,0.3-1.9,0.8-2.7c1-1.7,2.5-3.1,3.3-4.9c0.6-1.3,1.4-2,2.6-2.7c0.1-0.1,0.1-0.4,0.2-0.6
		c-0.4-0.4-0.8-0.7-1.2-1c1.5-1.6,2.9-3,3.4-5c0.2-0.8,1-1.5,1.5-2.2c0.4-0.6,0.9-1.3,1.2-1.9c0.4-0.8,0.8-1.7,1.3-2.4
		c1.8-2.6,3.7-5.2,6.2-7.2c2-5.3,6.7-8.2,10.4-11.9c1.3-1.2,2.4-2.4,2.6-4.8c-3.8,2.5-7.4,4.3-11.4,5c-1.1,0.2-2.4,0.1-3.4,0.5
		c-5.1,2.3-10.1,4.4-15.9,2.6c-0.4,0.7-0.7,1.8-1.1,1.8c-2,0-3.7,1.5-6,0.4c-1.9-0.9-4.4-0.5-6.6-0.7c-0.2,0-0.6,0.2-0.6,0.3
		c-0.1,2.6-1.5,1.1-2.5,0.9c-0.4-0.1-0.9-0.2-1.3-0.1c-2.7,0.6-5.3,0.7-7.8-0.6c-0.3-0.2-0.9,0.2-1.4,0.1c-1.7-0.4-3.8,0.2-5.1-1.7
		c-1.6-2.3-4.1-2.6-6.5-3c-1.5-0.3-3-0.3-4.4-0.9c-2-0.9-3.8-2.2-5.6-3.4c-0.6-0.4-1.2-0.9-1.5-1.4c-0.6-1.1-1.6-1.6-2.5-2.3
		c-2.7-2-5.6-3.8-6.1-7.7c-0.2-2.1-0.9-4.1-1.7-6.1c-0.8-1.9-1.9-3.8-3-5.6c-1.2-1.9-1.1-4.1-1.6-6.1c-0.9-3.2-2.1-6.6-1.6-10.2
		c0.2-1.3,0-2.9,0.7-3.8c1.7-2.1,0.5-3.5-0.5-4.8c0.4-1.4,0.9-2.5,1.1-3.7c0.3-2.3,2-4.5,0.9-7c-0.1-0.1,0.2-0.4,0.3-0.6
		c1.8-1.7,2.2-4.1,3-6.2c1.3-3.2,1.6-6.9,2.9-10.1c1.3-3.1,2.4-6.6,5-8.9c2.1-1.9,2.8-4,3.3-6.5c0.2-0.9,0.2-2.1,0.8-2.7
		c3.5-3.7,4.7-9,8.7-12.4c0.3-0.3,0.7-0.9,0.6-1.3c-0.4-2.3,1.5-3.3,2.6-4.7c0.8-1,1.7-1.8,1.8-3.2c0.1-0.7,0.7-1.5,1.3-2
		c0.9-1,2-1.8,2.9-2.8c2.8-3.4,4.1-8,7.8-10.8c0.2-0.2,0.4-0.6,0.4-1c0.1-2.3,1.9-3.5,3.2-5C451.9,81.5,453.8,79.2,456.1,76.2z"
      />
      <path fill="#222" d="M10,385.9c0.6,0.2,1.6,0.4,1.7,0.6c0.1,2,0.1,4.1-0.1,6.1c0,0.2-1,0.3-1.6,0.4C10,390.6,10,388.2,10,385.9z" />
      <path
        fill="#222"
        d="M762.1,317.4c0.5,1.1,0.9,1.9,1.3,2.9c-2.5,3.6-4.9,7.5-7.7,10.9c-2.2,2.7-4.7,5.2-7.5,7.3
		c-1.6,1.2-3.6,2.6-6.1,2.1c-0.8-0.2-1.8,0.7-2.7,1c-3.5,1.1-6.1,4.4-10.2,4.4c-2.1,0-4.3,1.1-6.3,1c-3.5-0.3-6.9,0.7-10.3,0.8
		c-2.8,0.1-5.9,0.5-8.7-1.3c-1-0.6-2.8-0.5-4.1-0.2c-3.5,0.8-4.4,0.7-8.6-0.8c-2-0.8-4.3-0.9-6.4-1.4c-1.8-0.4-3.8-0.5-5.4-1.2
		c-1.3-0.6-2.8-1.6-3.4-2.8c-1.5-2.7-4.2-3.8-6.6-4.7c-3.1-1.1-4.5-3.6-6.7-5.5c-1-0.9-1.7-2.1-2.7-3.1c-1.5-1.6-3.5-2.9-3.8-5.4
		c-0.2-1.2-1.2-2.2-1.7-3.4c-0.3-0.7-0.6-1.5-0.6-2.2c0-3.2,0-6.5,0.1-9.7c0-0.9,0-1.6,0.7-2.5c1.3-1.6,1.9-3.7,1.3-6
		c-0.7-2.5,2.3-8.9,4.6-10.2c2.6-1.5,4.1-4,5.7-6.4c0.6-0.8,0.5-1.8,2-2.1c1.1-0.2,2.1-1.4,3-2.3c2.9-3,6.3-5,10-6.8
		c1.9-0.9,3.6-2.3,5.5-3.3c1.8-0.9,3.7-1.7,5.6-2.1c2.9-0.6,5.6-1.3,8.2-2.7c0.6-0.3,1.3-0.6,1.9-0.7c2.9-0.2,5.9,0.2,8.7-0.4
		c4-0.9,7.7,0.2,11.6,0.2c1.1,0,2.7,0.8,3.4-0.9c0.1-0.1,0.4-0.2,0.6-0.1c3.9,1.1,7.9,2.1,11.8,3.3c1.9,0.6,3.4,2,5.6,2
		c0.9,0,1.8,1.4,2.8,2.1c2.3,1.6,4.9,2.7,6.7,4.7c2.6,3,6.3,5.1,7.4,9.3c0.2,0.9,1,1.6,1.3,2.4c0.7,2,1.3,4,1.8,6
		c0.2,0.7-0.2,1.6,0.1,2.1c2.1,2.6,0.6,6.1,2.1,8.9c0.3,0.6,0.1,1.6,0,2.4c0,0.6-0.5,1.4-0.2,1.7c2.5,3.3-1.8,6.1-0.7,9.3
		c0.1,0.2-0.6,0.5-0.9,0.8C763.8,315.6,763,316.5,762.1,317.4z M681.2,304.5c0,3.2,0.6,4.1,3.3,5.6c1.5,0.8,2.7,2,4.1,3
		c1.4,0.9,2.8,2.1,4.5,2.6c3.2,0.9,6.5,1.3,9.8,1.9c1.6,0.3,3.3,1.1,4.9,0.8c2.1-0.3,4.4-0.4,6.4-1.8c1.6-1.1,3.7-1.5,5.5-2.3
		c2.9-1.3,5.9-2.3,8.6-4c2.9-1.8,5.5-4,7.9-6.4c2-2,4.6-4.3,2-7.7c-0.2-0.2,0-0.7-0.1-1c-0.7-2.4-1.3-4.8-2.1-7.2
		c-0.2-0.6-0.7-1.3-1.3-1.7c-2.2-1.3-4.5-2.5-6.7-3.8c-1.1-0.6-2.3-1.3-3.4-1.9c-0.6-0.3-1.4-0.9-1.9-0.7c-2.4,0.7-4.7-0.4-6.9-0.2
		c-4.4,0.3-8.9,0.8-12.6,3.7c-2.2,1.7-4.4,3.5-7.4,4.1c-0.8,0.2-1.6,0.9-2.2,1.5c-2.6,2.7-4.5,6.1-8.2,7.5c-0.5,0.2-0.8,0.8-1.1,1.3
		c-0.7,1.2-1.5,2.4-2.1,3.6C681.8,302.6,681.5,303.8,681.2,304.5z"
      />
      <path
        fill="#222"
        d="M717.1,183.5c2,3.9,1.2,7.2-0.3,10.6c-0.5,1.1-0.7,2.2-0.9,3.4c-0.7,4.9-3.4,8-8.1,9.5
		c-0.7,0.2-1.3,0.5-1.9,0.9c-1.5,0.9-2.9,1.5-4.7,1c-0.5-0.1-1.2,0-1.7,0.2c-2.8,1.2-5.4,1.1-8.1-0.3c-2.3-1.1-4.8-1.9-7.2-2.9
		c-2.7-1.1-4.4-3.3-5.2-5.9c-1-3-1.5-6.2-0.1-9.3c0.5-1.2,0.3-2.8,0.9-3.9c1.9-3.8,5.2-5.8,8.9-7.6c3.3-1.6,6.8-3,9.4-5.9
		c1.4-1.6,3-2,5.1-1.5c5,1.4,10.1,2.5,14.6,5.3c1.4,0.9,2.6,2.4,3.4,3.9c0.8,1.4-0.2,2.7-2,2.9C718.7,183.8,718.1,183.7,717.1,183.5
		z"
      />
      <path
        fill="#222"
        d="M768.8,205.1c0.7-1.2,1.4-2.3,2.9-0.8c0.1,0.1,0.5,0.3,0.5,0.3c2.3-2.2,3.5,0.4,5.1,1.2c1.6,0.8,3.3,1.6,5,2.3
		c1.8,0.7,3.4,1.5,4.1,3.5c1.2,3.2,2.7,6.3,3.4,9.5c0.2,1.3,0.5,3.5-1.1,4.8c-1.7,1.3-1,2.8-0.3,4.5c-1.7,2.1-2.6,5-5.6,5.9
		c-0.5,0.2-1.1,0.7-1.6,0.6c-2.6-0.5-5.2-1.2-7.8-1.6c-1.8-0.3-3.7-0.4-5.6-0.4c-1.9,0.1-3.3-1-4.2-2.3c-1.1-1.4-1.7-3.1-2.3-4.8
		c-1.1-3.2-1.2-6.6-1.3-9.9c0-1,1.1-1.9,0.7-3.4c-0.2-0.6,1.6-1.7,2.4-2.6c0.7-0.9,1.8-1.9,1.7-2.9c-0.1-2.5,1.4-3,3.3-3.4
		c0,0.6,0.1,1.2,0.1,1.9c0.3,0,0.5,0,0.8,0C769.1,206.7,771.5,206.1,768.8,205.1z"
      />
      <path
        fill="#222"
        d="M768.8,205.1c2.7,1,0.3,1.6,0.1,2.4c-0.3,0-0.5,0-0.8,0c0-0.6-0.1-1.2-0.1-1.9
		C768.3,205.5,768.6,205.3,768.8,205.1z"
      />
      <path
        fill="#222"
        d="M286.9,163.7c2,0.7,4.3,1.4,6.5,2.2c2.1,0.7,4.5,1,6.1,2.3c2.6,2,5.5,3.5,8,5.4c0.5,0.4,0.7,1.4,0.9,2.2
		c0.6,2.2,0.9,4.5,2.7,5.6c-0.2,1.2-0.7,2.4-0.4,3.3c0.7,2.5,2,4.9,0.5,7.5c-0.1,0.2,0,0.5,0.1,0.7c1.3,5.5-0.9,10.6-1.3,16
		c-0.1,0.7-0.9,1.3-1.3,2c-0.6,1.4-1.3,2.7-1.7,4.2c-0.6,2.2-0.9,4.5-1.3,6.8c-0.1,1-1.5,2-0.2,3.2c0.2,0.2-0.1,1.2-0.4,1.5
		c-1.8,1.3-1.7,3.3-2.3,5.1c-0.4,1.4-1,2.8-1.8,4.1c-1.9,2.9-4.4,5.4-5.6,8.8c-0.9,2.6-3.6,4.2-5.9,5.7c-1.9,1.2-3.8,3-6.4,1.6
		c-3.1,1.6-6.4,0-9.5,0.2c-1,0.1-2.3,0-3-0.5c-2.9-2.5-6.4-1.1-9.6-1.7c-0.9-0.2-2,0.1-2.7-0.3c-3.2-2.1-6.7-2-10.3-1.6
		c-1,0.1-2.4,0-3-0.6c-1.3-1.5-2.7-1.5-4.4-1.4c-2.5,0-4.4-1.7-6.3-3c-2.5-1.7-4.7-3.9-7.1-5.7c-3.8-2.9-5.2-6.7-5.5-11.5
		c-0.3-3.7-0.1-7.2,0.1-10.8c0.2-3.4,1.6-6.3,3-9.2c3.7-7.8,10.6-12.9,15.9-19.4c0.1-0.1,0.2-0.2,0.3-0.2c4.7-0.5,6.5-5.7,11-6.6
		c1-0.2,2-0.7,3-1.1c1.3-0.4,2.8-0.4,3.7-1.9c0.4-0.6,1.3-1.2,2.1-1.3c3-0.3,4.6-2.4,6.5-4.2c2.5-2.3,5.5-3.9,8.9-4.4
		c2.3-0.3,4.7-0.6,7-1.1C284.2,165,285.3,164.3,286.9,163.7z"
      />
      <path
        fill="#222"
        d="M147.7,380c1.1-3.8,0.6-7.6,1.8-11.4c1.6-5,3.9-9.6,6.2-14.2c0.8-1.7,2.3-3,3.4-4.5c1.2-1.6,2.6-3.1,3.6-4.9
		c2.3-3.8,5-7.1,8.4-9.9c0.3-0.2,0.6-0.6,0.6-0.8c-0.1-2.7,1.7-3.9,3.6-5.3c0.7-0.6,1.5-1.3,1.8-2.2c0.9-2.2,3.3-3.5,4.7-3.1
		c1.8,0.5,2.3-1.9,4-0.8c1,0.6,2.6,0.3,3.9,0.3c0.8,0,1.7,0.2,2.4-0.1c2-0.7,3.4-0.1,4.7,1.4c0.6,0.6,1.6,1.1,2.4,1.1
		c3.2,0.3,5.9,1.3,7.4,4.4c0.1,0.3,0.5,0.6,0.8,0.7c3.1,0.9,4.4,3.7,6.4,5.8c4.7,4.9,5.8,11.1,6.7,17.4c0.1,1,0.5,2.1,0.8,3.1
		c0.1,0.6,0.6,1.3,0.4,1.6c-1.5,2.1-0.3,3.8,0.6,5.7c0.5,1.2,0.2,2.8,0.2,4.3c0,2.9-0.1,5.9-0.3,8.8c0,0.6-0.5,1.2-0.8,1.8
		c-0.3,0.6-0.9,1.2-0.9,1.8c-0.1,3.2-1.5,6-2.4,8.9c-0.7,2.2-0.5,4.5-1.7,6.6c-1.2,2.1-1.6,4.5-3.6,6.1c-0.6,0.5-1.1,1.3-1.3,2.1
		c-0.8,3-3.2,5.1-4.7,7.7c-0.3,0.5-0.7,1.1-1.2,1.3c-2.8,1.1-4.9,3.1-7.2,4.9c-0.8,0.6-1.6,1.4-2.5,1.8c-1.6,0.8-3,2.3-5.2,1.6
		c-1.1-0.3-2.5-0.5-3.5-0.1c-2.9,1.2-5.6,0.8-8.4-0.3c-0.8-0.3-1.9-0.2-2.8-0.4c-0.8-0.1-1.7,0-2.4-0.4c-3.6-2.1-6.9-4.5-9.3-8.1
		c-1.2-1.8-1.9-3.9-3.4-5.3c-4.5-4.2-6.3-10.1-9.6-15c-1.3-2-1.8-4.6-2.4-7C148.4,383.7,148.1,381.8,147.7,380z"
      />
      <path
        fill="#222"
        d="M418.3,604.2c-1.1,0-2.4-0.2-3.6,0c-3.5,0.8-7,1.3-10.6,1.6c-1.9,0.1-3.4-0.5-5-0.8c-3.2-0.6-6.4-1.6-9.6-2.4
		c-2.3-0.6-3.5-2-3.7-4.5c-0.1-1.1-0.6-2.6-1.4-3.3c-2.6-2.1-3.3-5-3-7.8c0.2-2.3-0.8-4.3-0.8-6.4c0-1.4-0.3-2.7-0.6-4.1
		c-0.3-1.6,0.8-3.4-0.6-4.8c1.9-3.2,0.6-6.9,1.5-10.2c0.6-2.3,1.8-4.5,2.6-6.8c0.8-2.3,1.8-4.4,3.1-6.3c0.8-1.1,1.8-2.1,2.8-3.1
		c1.1-1.2,2.4-2.4,3.3-3.8c1-1.6,1.9-3.2,4.1-3.6c2-3.4,6.4-2.7,9.1-5.2c1-0.9,3.1-0.9,4.7-1c3.1-0.1,6-0.3,9-1.4
		c1.8-0.6,4.1-0.1,6.2,0.2c1.2,0.2,1.8,0,2.2-1.3c2.5,1.5,5,2.3,8,1.6c1.2-0.3,3.2,0.1,3.8,1c1.2,1.6,2.7,1.4,4.1,1.6
		c5.1,0.9,8.3,4.5,11.9,7.7c2.4,2.2,3.3,5.2,5.1,7.7c0.9,1.3,0.9,4,0.2,5.5c-1.1,2.3-0.4,4.5,0.4,6.1c1.2,2.6,0.2,4.6-0.1,6.9
		c-0.4,3.3-2.4,5.8-4.2,8.3c-1.2,1.6-3.5,2.4-4.6,4c-1.2,1.8-1.8,3.7-3.9,4.8c-1.8,1-3.9,2.1-5,3.8c-1.6,2.3-3,4.1-5.8,5.2
		c-2.3,0.9-4.1,3.2-6.1,4.9c-0.5,0.4-1.2,1.1-1.8,1.1c-3.1-0.2-5,2.5-7.9,2.8C420.7,602.4,419.7,603.4,418.3,604.2z"
      />
      <path
        fill="#222"
        d="M416.3,445.3c-1-7.9,3.8-13.4,7.8-19.2c0.5-0.8,1.5-1.1,2.2-1.8c0.7-0.8,1.6-1.6,1.8-2.6
		c0.3-2.1,1.4-3.6,2.9-4.7c3.7-2.8,7.4-5.6,11.6-7.5c1-0.5,2.4-0.9,3.4-0.5c3.4,1.2,6.5,0,9.7-0.6c1.3-0.2,2.4-1.1,3.8,0
		c0.6,0.5,2,0,3.1-0.2c3.5-0.9,6.5-0.7,8.6,2.8c0.5,0.8,2.3,1.4,3.4,1.3c2.4-0.2,3.9,1.1,5.2,2.6c2.1,2.3,3.5,5.3,5.9,7.1
		c2.7,2.1,3.9,5.2,5.9,7.7c0.6,0.7,1.1,1.7,1.1,2.6c-0.2,4.5,1.7,8.8,0.6,13.3c-0.1,0.2,0,0.5,0.1,0.7c1.4,1.8,0.1,3.5-0.3,5.2
		c-0.9,4-2.3,7.8-4,11.6c-1,2.2-2.1,4-4.7,4.5c-2.6,0.5-4.3,2.5-6,4.3c-1.3,1.4-2.5,2.6-4.7,2.1c-0.8-0.2-1.9,0.1-2.8,0.5
		c-3.3,1.7-6.7,2.8-10.4,1.9c-1,1.6-2.5,0.9-3.8,0.7c-4.9-1-10-0.2-14.7-2.3c-4.3-1.9-9.1-2.3-12.9-5.4c-1.9-1.6-4-2.9-5.8-4.5
		c-1.4-1.3-2.6-2.9-3-5.1c-0.6-3.1-2-6-2.9-9C416.8,448.9,416.6,447.1,416.3,445.3z"
      />
      <path
        fill="#222"
        d="M268.9,538.3c0.2-0.3,0.8-0.9,0.7-1.2c-0.6-1.4-1.2-2.8-2.1-4c-1.9-2.4-2.1-4.7-1-7.5c0.6-1.6,0.4-3.4,0.7-5.1
		c0.4-2,0.8-4,1.6-5.8c0.9-2.1,2-4.2,4.7-4.5c0.3,0,0.7-0.2,0.9-0.5c1.7-2.8,4.6-3.4,7.5-4c1.6-0.3,3-1.4,4.6-2
		c1.2-0.4,2.5-0.5,3.7-0.7c0.3-0.1,0.7,0.1,1.1,0c2.5-0.8,5-1.6,7.5-2.6c1.5-0.6,2.8-0.9,4.4-0.3c2,0.7,4.1,0.3,5.8-1.4
		c0.9,2.2,3,1.6,4.5,1.8c2.1,0.3,4.1,0.3,5.9,1.5c0.5,0.4,1.2,0.7,1.9,0.8c2.6,0.3,4.6,2.1,6.7,3.4c0.4,0.3,0.7,0.9,0.9,1.5
		c0.4,1.8,1,3.3,2.5,4.5c2.4,2,2.7,4.9,2.8,7.7c0.1,3-0.1,6-0.5,8.9c-0.4,3.4-1.6,6.5-4.1,9.1c-0.9,1-1.6,2.3-2.4,3.3
		c-1,1.1-2,2.2-3.2,3.2c-1.2,0.9-2.6,1.5-3.8,2.4c-3.4,2.5-6.8,4.8-11.2,5.2c-1.3,0.1-2.5,1.3-3.7,2c-3.2,1.9-6.6,1.8-10.2,1.3
		c-1.1-0.1-2.4-0.1-3.5,0.2c-1.9,0.6-3.6,0.8-5.5,0c-2.1-0.9-4.5-1-6.6-1.9c-3.5-1.4-5.3-4.7-7.3-7.6c-0.8-1.2-1.2-2.8-1.7-4.2
		C269.9,540.8,269.4,539.7,268.9,538.3z"
      />
      <path
        fill="#222"
        d="M387.6,308.8c-0.1,2.5-0.2,4.4-0.4,6.3c-0.1,0.6-0.2,1.3-0.5,1.9c-1.7,2.9-3.2,5.8-5,8.6
		c-1.7,2.5-3.3,5-5.3,7.3c-2,2.4-4,4.9-6.3,7.1c-1.1,1.1-1.6,2.6-3.2,3.6c-2.9,1.9-6.1,2.4-9.3,3.4c-2.3,0.7-4.7,1.4-7,1.9
		c-1.6,0.3-3.3,0.2-4.9,0.2c-0.3,0-0.9,0.1-1,0c-0.9-2.5-3.1-1.5-4.7-2c-4.8-1.4-7.9-4.7-10-9.1c-1-2-2.4-3.8-3.1-5.9
		c-0.8-2.5-1.1-5.2-1.3-7.9c-0.1-1.6,0.5-3.2,0.7-4.8c0.1-1.6,0-3.2,0.1-4.9c0.1-1.3,0.5-2.5,0.7-3.7c2.2,0.6,1.2-1.6,2.1-2.3
		c2.9-2.3,5-5.5,6.5-8.9c0.8-1.8,2.1-3,3.9-3.2c1.9-0.3,3.4-0.9,4.4-2.5c1-1.5,2.6-2.3,4.2-2.4c2.8-0.1,5.4-2.1,8.4-1.1
		c2.2-1.7,4.4,0,6.6,0.1c2.3,0,4.6,0.1,6.9,0.3c0.8,0.1,1.6,0.6,2.5,1c1.2,0.4,2.3,0.9,3.5,1.3c1.7,0.5,3.2,1.2,4.2,2.8
		c0.5,0.8,0.9,1.8,1.6,2.3c2.5,1.7,3.3,4.5,4,6.9C386.2,306.7,386.6,308,387.6,308.8z"
      />
      <path
        fill="#222"
        d="M159.3,288.8c-1.9,2.4-3.7,4-6.8,2.7c-0.8-0.3-2.2-0.3-2.7,0.2c-1.2,1.1-2.6,0.9-4,1c-0.2,0-0.6,0-0.7,0.1
		c-1.9,1.6-3.8,0.3-5.7,0c-2.4-0.3-5-0.6-7.3-1.3c-1.8-0.6-3.9-1.2-4.1-3.8c-0.1-0.7-0.7-1.6-1.2-2.1c-3.3-3.2-3.7-6.7-1.6-10.7
		c0.3-0.6,0.2-1.5,0.2-2.3c0-1.1-0.3-2.3,0-3.3c1.7-6.1,4.9-10.9,11.7-12.1c2.7-0.4,3.8-2.5,5-4.2c3.4-4.5,7.6-7.4,13.1-8.3
		c2.2-0.3,4.2-0.7,5.5-3.1c1.5,0.4,3,0.8,4.6,1.2c0.7,0.2,1.6,0.7,2,0.4c2.4-1.5,4.4,0.7,6.7,0.6c0.8,0,1.7,0.7,2.4,1.3
		c1.3,1.2,2.3,2.6,4.2,3.2c0.6,0.2,1.1,1.1,1.5,1.8c1.4,2.1,2.8,4.4,5.6,5.1c0,4.3,0,8.6,0,12.9c0,0.8-0.1,1.8-0.5,2.3
		c-1.3,1.4-2.7,2.6-3.6,4.5c-0.8,1.7-2.7,2.9-4,4.4c-1.7,1.9-4.1,2.8-6.3,3.6c-1.6,0.5-3,1.3-4,2.4c-2.3,2.5-5.8,2.4-8.3,4.4
		C160.5,289.9,159.8,289.2,159.3,288.8z"
      />
      <path
        fill="#222"
        d="M303.2,444.3c-0.4-1-0.7-1.9-1.1-2.9c-0.7,0.3-1.4,0.5-1.8,0.9c-0.5,0.6-0.5,1.7-1.1,2
		c-1.1,0.5-2.5,1.1-3.6,0.8c-2.2-0.6-4.2,0-6.3,0.4c-2.5,0.5-5.2,1.2-7.6-0.4c-1.2-0.8-2.5-1.8-3.4-2.9c-2.1-2.5-4.4-4.8-5.4-8.1
		c-0.5-1.7-1.9-3.2-2.5-4.9c-1.1-2.7-1.4-5.8-0.6-8.4c0.8-2.4,3-4.5,4.8-6.4c2.8-2.8,5.7-5.4,9.4-7c1.9-0.8,4-1.2,6.1-1.3
		c1.8-0.1,3.7,0.4,5.6,0.6c0.7,0.1,1.4,0.2,2.1,0.4c1.6,0.6,3.2,1.3,4.9,1.8c3.1,0.9,5.6,3.9,5.9,7c0.2,2.2,0.2,4.5,0.5,6.7
		c0.3,2,0.1,4.1,0.3,6.1c0.2,2.4-0.5,5-2.4,7.1C304.9,438.1,303,440.7,303.2,444.3z"
      />
      <path
        fill="#222"
        d="M261.8,611c0.2-0.4,0.7-1.4,1.3-2.6c-0.6-1.3-1.2-2.8-0.1-4.8c1.1-2,1-4.6,3.1-6.1c1-0.8,2.1-1.6,3.3-1.9
		c1.8-0.5,3.7-0.7,5.5-0.8c2.2-0.1,4.5-0.1,6.7,0.1c1.9,0.2,3.8,0,5.8,0.3c3.2,0.5,5.2,2.3,7.4,4.1c1.7,1.3,2.7,2.9,3.3,5.1
		c0.6,2.2,2.1,4.1,2.9,6.3c0.5,1.3,0.7,2.8,0.5,4.4c-0.2,2.6-1.1,5-1,7.5c0,2.4-1.3,4.2-3.1,5.7c-1.6,1.4-3.1,2.8-5.5,2.4
		c-0.9-0.1-1.9,0.2-2.8,0.5c-4.8,1.6-9.4-0.1-14-0.8c-1.1-0.2-2-1.5-3.1-2.2c-3.4-2.2-5.3-5.4-7.1-8.8c-0.6-1.3-1.4-2.2-1.5-3.8
		C263.4,614.2,262.5,612.8,261.8,611z"
      />
      <path
        fill="#222"
        d="M122,486.1c-0.5-0.4-1.1-0.8-1.6-1.3c0.1-0.3,0.2-0.7,0.4-0.9c1.9-2,3.7-4.2,5.9-6c1.3-1.1,3-1.9,4.7-2.3
		c3.6-0.9,7.3-1.6,10.7,0.9c0.6,0.5,1.5,1,2.2,1c3.6,0.1,5.9,2.4,7.8,4.8c1.2,1.5,1.7,3.6,2.2,5.5c0.5,2.1,0,4.4,1.6,6.2
		c-1.2,1.8,0.3,4.4-2,5.8c-0.7,0.4-0.9,1.6-1.3,2.4c-0.4,0.7-0.5,1.8-1,2.2c-3,1.9-6,3.9-9.9,3.7c-2.4-0.1-4.8-0.3-7.1-0.1
		c-2.7,0.2-4.5-1.1-6-3c-0.5-0.6-0.8-1.6-1.4-2c-3.7-2.7-4.5-6.7-4.9-10.9c-0.1-1-0.3-2.1-0.8-3C120.3,487.3,120.2,487.4,122,486.1z
		"
      />
      <path
        fill="#222"
        d="M363.1,160.8c-2.4-0.6-4.8-1.1-7.1-1.8c-1.6-0.5-2.9-1.5-3-3.5c-0.1-2.5,0-5-0.4-7.4c-0.7-3.6,0.3-6.6,2.8-9.2
		c2.5-2.6,5.8-4.3,9.1-5.2c2.4-0.6,5.6-0.1,7.7,1.2c1.9,1.1,2.9,3.9,4.2,6c0.2,0.4-0.1,1.1,0,1.7c0.1,1.5,0.2,3,0.3,4.5
		c0.1,1.8,1.3,3.7-0.9,5c-0.2,0.1-0.4,0.4-0.3,0.5c1.2,2.9-1.5,3.3-2.9,4.5c-1.2,1-2.4,2.2-3.8,2.8C367,160.4,365,160.5,363.1,160.8
		z"
      />
    </g>
  </svg>
)

export default CookieIcon
