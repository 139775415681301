import { Box, Text } from 'atomic'
import { FC } from 'react'
import { item, list } from './InfoBox.css'
import { Trans } from 'next-i18next'
import { ThemeColors } from 'atomic/styles/theme.css'

const InfoBox: FC<{ keys: string[]; background: keyof ThemeColors }> = ({ keys, background }) => {
  return (
    <Box className={list} borderRadius={'m'} background={background}>
      <ul>
        {keys.map((key) => {
          return (
            <li key={key} className={item}>
              <Trans
                key={key}
                i18nKey={key}
                components={{
                  facttitle: (
                    <Text as="span" fontWeight="semibold">
                      title
                    </Text>
                  ),
                }}
              />
            </li>
          )
        })}
      </ul>
    </Box>
  )
}

export default InfoBox
