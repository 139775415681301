/* eslint-disable @next/next/no-img-element */
import React from 'react'
import * as css from './Quote.css'
import { Text, Container } from 'atomic'
import { ouTheme, ThemeColors } from 'atomic/styles/theme.css'
import Box, { BoxProps } from 'atomic/components/atoms/box/Box'
import { BuilderProps, removeKeys } from '../helpers/BuilderPropCleaner'
import Illustration from 'atomic/components/atoms/illustrations/Illustration'

type QuoteProps = {
  modelType?: 'pages' | 'blog'
  fontFamily?: keyof typeof ouTheme.fontFamilies
  fontSize?: keyof typeof ouTheme.fontSizes
  lineHeight?: keyof typeof ouTheme.lineHeights
  textAlign?: 'left' | 'center' | 'right'
  quote: string
  illustration?: string
  illustrationPosition: 'left' | 'right'
  fontWeight?: keyof typeof ouTheme.fontWeights
  backgroundColor?: keyof ThemeColors
  signature?: string
} & BoxProps &
  BuilderProps

const Quote: React.FC<QuoteProps> = ({
  modelType = 'blog',
  quote,
  illustration,
  illustrationPosition,
  fontFamily = 'accent',
  fontSize = 'xxl',
  lineHeight = 'xs',
  textAlign = 'left',
  fontWeight,
  backgroundColor,
  signature,
  ...props
}) => {
  const flexDirection = illustrationPosition === 'right' ? 'row' : 'row-reverse'
  const cleanProps = removeKeys(props, { builderBlock: true, builderState: true })

  return (
    <>
      {modelType === 'blog' ? (
        <Container className={css.container[modelType]} flexDirection={{ mobile: 'column-reverse', desktop: flexDirection }} {...cleanProps}>
          <Text
            lineHeight={lineHeight}
            fontWeight={fontFamily === 'accent' ? 'normal' : 'light'}
            fontFamily={fontFamily}
            fontSize={fontSize}
            textAlign={textAlign}
            className={illustration ? css.quote : css.fullWidthQuote}
          >
            {quote}
          </Text>
          {illustration ? <img src={illustration} alt="Illustration" className={css.illustration[modelType]} /> : null}
        </Container>
      ) : (
        <Box background={backgroundColor ?? 'mossa4'} marginBottom={props.marginBottom ?? 'xxl'} {...cleanProps}>
          <Container className={css.container[modelType]} {...props}>
            <Box justifyContent="center" textAlign="center">
              <Box>
                <Box textAlign="left">
                  <Illustration name={'quotation-marks'} className={css.illustration[modelType]} />
                </Box>
                <Text textAlign={textAlign} fontSize="xl" fontWeight={fontWeight ?? 'bold'} as="p" marginBottom="l">
                  {quote}
                </Text>
                <Text fontSize="base" textAlign={textAlign} color="malm0">
                  {signature}
                </Text>
              </Box>
            </Box>
          </Container>
        </Box>
      )}
    </>
  )
}

export default Quote
