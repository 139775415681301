import { Box, Carousel, Container, Heading, Text } from 'atomic'
import React from 'react'
import * as css from './StatisticsContainer.css'
import Illustrations from 'atomic/components/atoms/illustrations/Illustration'
import { ThemeColors, ouTheme, themeVars } from 'atomic/styles/theme.css'
import Image from 'next/image'
import { BuilderProps, removeKeys } from 'components/builder_components/helpers/BuilderPropCleaner'

type Slide = {
  src: string
  color: keyof ThemeColors
  title: string
  text: string
}

type StatisticsContainerProps = {
  heading: string
  intro: string
  source: string
  statisticsSlides: Slide[]
} & BuilderProps

const StatisticsContainer: React.FC<StatisticsContainerProps> = ({ heading, intro, source, statisticsSlides, ...props }) => {
  // Remove Builder.io specific props
  const cleanProps = removeKeys(props, { builderBlock: true, builderState: true })

  return (
    <Container
      className={css.container}
      alignItems={'center'}
      justifyContent={'center'}
      gridColumnGap={{ mobile: 'none', desktop: 'xxl' }}
      gridRowGap={{ mobile: 'l', desktop: 'none' }}
      {...cleanProps}
    >
      <Box display={'flex'} flexDirection={'column'}>
        <Heading as={'h2'} fontSize={'xxxl'} textAlign={{ mobile: 'center', desktop: 'left' }}>
          {heading}
        </Heading>
        <Box display={{ mobile: 'flex', desktop: 'none' }}>
          <Text fontSize={'base'} fontWeight={'semibold'} textAlign={'center'}>
            {source}
          </Text>
        </Box>
        <Text
          fontFamily={'accent'}
          fontSize={{ mobile: 'xxl', desktop: 'xl' }}
          fontWeight={'normal'}
          textAlign={{ mobile: 'center', desktop: 'left' }}
        >
          {intro}
        </Text>
        <Box display={{ mobile: 'none', desktop: 'flex' }} justifyContent={'flex-end'}>
          <Illustrations name={'arrow-7'} height={52} style={{ transform: `scaleY(-1) rotate(300deg)` }} />
        </Box>
        <Box display={{ mobile: 'flex', desktop: 'none' }} justifyContent={'flex-end'}>
          <Illustrations name={'arrow-7'} height={25} style={{ transform: `scaleY(1) rotate(45deg)`, marginTop: -15 }} />
        </Box>
      </Box>
      <Box display={'flex'} alignItems={'center'} justifyContent={'center'} style={{ width: '100%' }}>
        <Carousel
          paginatorPosition="bottom"
          style={{
            minHeight: 374,
            height: '100%',
            borderRadius: 8,
            backgroundColor: themeVars.colors.kantarell3,
            boxShadow:
              '0px 108px 30px 0px rgba(0, 0, 0, 0.00), 0px 69px 28px 0px rgba(0, 0, 0, 0.01), 0px 39px 23px 0px rgba(0, 0, 0, 0.05), 0px 17px 17px 0px rgba(0, 0, 0, 0.09), 0px 4px 10px 0px rgba(0, 0, 0, 0.10)',
          }}
          items={statisticsSlides?.map((slide, index) => {
            return (
              <Box
                key={index}
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'space-between'}
                backgroundColor={slide.color ?? 'kantarell3'}
                gap={'l'}
                padding={'m'}
                style={{
                  minHeight: 374,
                  borderRadius: 8,
                  height: '100%',
                }}
              >
                <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
                  <Image
                    src={slide.src}
                    width={374}
                    height={374}
                    style={{ objectFit: 'contain' }}
                    alt={`carousel_image_${index}`}
                    sizes={`(max-width: ${ouTheme.breakpoints.desktop}) 100vw, 50vw`}
                    draggable={false}
                  />
                </Box>
                <Text fontSize={'l'} fontWeight={'extrabold'} margin={'none'}>
                  {slide.text}
                </Text>
              </Box>
            )
          })}
        />
      </Box>
      <Box display={{ mobile: 'none', desktop: 'flex' }} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
        <Box position={'absolute'} display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'} style={{ top: -30 }}>
          <Illustrations name={'speech-bubble'} width={350} color="blåbär4" />
          <Text fontFamily={'accent'} fontSize={'l'} fontWeight={'normal'} textAlign={'center'} position={'absolute'} paddingX={'l'}>
            {source}
          </Text>
        </Box>
      </Box>
    </Container>
  )
}

export default StatisticsContainer
