import { Box, Container, Heading, Text } from 'atomic'
import { useBackend } from 'context/BackendContext'
import React, { FC } from 'react'
import { BuilderProps, removeKeys } from '../../helpers/BuilderPropCleaner'
import { themeVars } from 'atomic/styles/theme.css'
import { useCmsOrTranslation } from 'hooks/useSafeTranslation'

type DeliveryProps = {
  heading: string
} & BuilderProps

const Delivery: FC<DeliveryProps> = ({ heading, ...props }) => {
  // Remove Builder.io specific props
  const cleanProps = removeKeys(props, { builderBlock: true, builderState: true })
  const { deliveryInfo } = useBackend()
  const { cmsOrLokalise } = useCmsOrTranslation()

  if (!deliveryInfo) {
    return null
  }

  return (
    <Box paddingX={'m'}>
      <Container
        display={'flex'}
        flexDirection={'column'}
        alignItems={'flex-start'}
        justifyContent={'center'}
        gap={'m'}
        paddingX={'none'}
        style={{ maxWidth: 940 }}
        {...cleanProps}
      >
        <Box paddingX={{ mobile: 'none', desktop: 'm' }}>
          <Heading as={'h3'} fontSize={'l'} marginBottom={'none'}>
            {cmsOrLokalise(heading, 'Delivery_page_subtitle')}
          </Heading>
        </Box>
        <Box style={{ width: '100%' }} display={'flex'} flexDirection={'column'} gap={'s'}>
          {deliveryInfo.countries.map((country) => (
            <Box
              key={country.country_code}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
              paddingBottom={'s'}
              paddingX={{ mobile: 'none', desktop: 'm' }}
              style={{ width: '100%', borderBottom: `1px solid ${themeVars.colors.malm2}` }}
            >
              <Text fontSize={'base'} fontWeight={'extrabold'} marginBottom={'none'}>
                {country.country_name}
              </Text>
              <Text fontSize={'base'} fontWeight={'light'} marginBottom={'none'} textAlign={'right'}>
                {country.delivery_copy}
              </Text>
            </Box>
          ))}
        </Box>
      </Container>
    </Box>
  )
}

export default Delivery
