import { Box, Text } from 'atomic'
import { themeVars } from 'atomic/styles/theme.css'
import React, { FC, FormHTMLAttributes, useState } from 'react'
import * as css from '../form_input/FormInput.css'

type FormTextAreaProps = {
  translatedText: string
  className: string
} & FormHTMLAttributes<HTMLTextAreaElement>

const FormTextArea: FC<FormTextAreaProps> = ({ translatedText, className, onChange, ...props }) => {
  const [selected, setSelected] = useState(false)
  const [error, setError] = useState(false)

  const handleOnChange: React.ChangeEventHandler<HTMLTextAreaElement> = (event) => {
    if (error) {
      setError(false)
    }
    onChange?.(event)
  }

  return (
    <Box position={'relative'}>
      {selected && (
        <Box position={'absolute'} style={{ top: -8, left: themeVars.spaces.xs }} backgroundColor={'white'} paddingX={'xxxs'}>
          <Text fontWeight={'semibold'} fontSize={'xs'} color={error ? 'errorText' : 'malm0'} marginBottom={'none'}>
            {translatedText}
          </Text>
        </Box>
      )}
      <textarea
        placeholder={!selected ? translatedText : ''}
        onFocus={() => setSelected(true)}
        onBlur={() => setSelected(false)}
        onInvalid={() => setError(true)}
        onChange={handleOnChange}
        className={error ? `${className} ${css.error}` : `${className} ${css.formInput}`}
        {...props}
      />
      {error && (
        <Text color={'errorText'} fontSize={'xs'} fontWeight={'normal'} marginTop={'xxxs'} marginBottom={'none'}>
          Oops! This field is required
        </Text>
      )}
    </Box>
  )
}

export default FormTextArea
