/* eslint-disable @next/next/no-img-element */
import React from 'react'
import { ouTheme } from 'atomic/styles/theme.css'
import { Box, Container, Text } from 'atomic'
import Illustration from 'atomic/components/atoms/illustrations/Illustration'
import * as css from './LogosComponent.css'
import ArrowIllustration from 'atomic/components/atoms/illustrations/ArrowIllustration'
import { Link } from 'components/link/Link'
import { BuilderProps, removeKeys } from 'components/builder_components/helpers/BuilderPropCleaner'

type FontSettingsItem = {
  family: keyof typeof ouTheme.fontFamilies
  size: keyof typeof ouTheme.fontSizes
  weight: keyof typeof ouTheme.fontWeights
  align: 'left' | 'center' | 'right'
}

type LogoItem = {
  src: string
  alt: string
  maxHeight: number
  link: string
}

type LogosComponentProps = {
  intro: string
  fontSettings: FontSettingsItem
  logos: LogoItem[]
} & BuilderProps

const LogosComponent: React.FC<LogosComponentProps> = ({ intro, fontSettings, logos, ...props }) => {
  // Remove Builder.io specific props
  const cleanProps = removeKeys(props, { builderBlock: true, builderState: true })
  return (
    <Container
      position={'relative'}
      display={'flex'}
      flexDirection={'column'}
      alignItems={fontSettings.align === 'left' ? 'flex-start' : fontSettings.align === 'right' ? 'flex-end' : 'center'}
      gap={{ mobile: 'xxl', desktop: 'xxxl' }}
      {...cleanProps}
    >
      <Box display={'flex'} position={'relative'} style={{ maxWidth: 670, width: '100%' }}>
        <Text
          fontFamily={fontSettings.family ?? 'accent'}
          fontSize={fontSettings.size ?? 'xl'}
          fontWeight={fontSettings.weight ?? 'normal'}
          textAlign={fontSettings.align ?? 'center'}
          style={{ maxWidth: 640 }}
          marginX={'auto'}
        >
          {intro}
        </Text>
        <ArrowIllustration name={'arrow-7'} color={'malm0'} className={css.illustration} />
      </Box>
      <Box
        display={'flex'}
        flexDirection={{ mobile: 'column', desktop: 'row' }}
        gap={{ mobile: 'xxl', desktop: 'xxxl' }}
        alignItems={'center'}
        justifyContent={'center'}
        flexWrap={'wrap'}
        paddingTop={'l'}
      >
        {logos &&
          logos?.map((logo, index) => (
            <Link key={index} href={logo?.link} target="_blank">
              <img src={logo?.src} alt={logo?.alt} style={{ width: 'auto', height: '100%', minHeight: 25, maxHeight: logo?.maxHeight }} />
            </Link>
          ))}
      </Box>
      <Box display={'flex'} alignItems={'center'} justifyContent={'flex-end'} style={{ width: '100%' }}>
        <Illustration name={'satisfaction-hand'} className={css.satisfactionIllustration} />
      </Box>
    </Container>
  )
}

export default LogosComponent
