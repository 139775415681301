import { StoryblokLink } from '../types/StoryblokLink'

export enum LinkType {
  INTERNAL,
  EXTERNAL,
}

export const getLinkType = (link: StoryblokLink): LinkType | null => {
  if (link.linktype === 'story' && link.story) {
    return LinkType.INTERNAL
  } else if (link.linktype === 'url' || link.linktype === 'asset') {
    return LinkType.EXTERNAL
  } else {
    null
  }
}
