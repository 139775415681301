import StoryblokImage from 'components/storyblokimage/StoryblokImage'
import { FC } from 'react'
import { StoryblokContent } from 'types/StoryblokContent'

import { storyblokEditable } from '@storyblok/react'

import styles from './Seamless.module.scss'
import { ouTheme } from 'atomic/styles/theme.css'

type PropTypes = {
  blok?: StoryblokContent
}

const Seamless: FC<PropTypes> = ({ blok }) => {
  return (
    <section {...storyblokEditable(blok)}>
      <div className={styles.container}>
        <div className={styles.image}>
          <StoryblokImage
            src={blok.seamless_illustration.filename}
            fill
            style={{ objectFit: 'contain' }}
            sizes={`(max-width: ${ouTheme.breakpoints.desktop}) 100vw, 50vw`}
            alt={blok.seamless_headline}
          />
        </div>
        <div className={styles.text}>
          <h1>{blok.seamless_headline}</h1>
          <p>{blok.seamless_text}</p>
        </div>
      </div>
    </section>
  )
}

export default Seamless
