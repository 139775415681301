import styled from 'styled-components'

interface PropTypes {
  shouldWrap?: boolean
  gridGap?: string
  children: React.ReactNode | React.ReactNode[]
}

const Grid = styled.div<PropTypes>`
  box-sizing: content-box;
  display: ${(props) => (props.shouldWrap ? 'grid' : 'block')};
  justify-items: ${(props) => props.shouldWrap && `center`};
  gap: ${(props) => props.gridGap && props.gridGap};

  @media (min-width: ${(props) => props.theme.screenSizes.large}) {
    grid-template-columns: ${(props) => props.shouldWrap && `repeat(auto-fit, minmax(200px, 1fr))`};
    gap: ${(props) => (props.gridGap ? props.gridGap : 0)};
  }
  @media (min-width: ${(props) => props.theme.screenSizes.xlarge}) {
    gap: ${(props) => props.gridGap && props.gridGap};
  }
`
export default Grid
