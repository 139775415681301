import DynamicLink from 'components/dynamiclink/DynamicLink'
import Image from 'next/image'
import { FC, useState } from 'react'
import { render } from 'storyblok-rich-text-react-renderer'
import { StoryblokContent } from 'types/StoryblokContent'

import { storyblokEditable } from '@storyblok/react'

import ActionDiv from '../../actiondiv/ActionDiv'
import DownArrow from '../../icons/DownArrow'
import ContentContainer from '../../styled/ContentContainer'

import styles from './CollapsibleSection.module.scss'
import { ouTheme } from 'atomic/styles/theme.css'

type PropTypes = {
  blok?: StoryblokContent
}

const CollapsibleSection: FC<PropTypes> = ({ blok }) => {
  const [collapsibleState, setCollapsibleState] = useState(
    blok.items.reduce((accumulator: StoryblokContent, currentValue: StoryblokContent) => {
      const key = currentValue._uid
      accumulator[key] = false
      return accumulator
    }, {})
  )

  const toggleOpenState = (collapsibleItem: StoryblokContent): void => {
    setCollapsibleState((prevState: StoryblokContent) => ({
      ...prevState,
      [collapsibleItem._uid]: !prevState[collapsibleItem._uid],
    }))
  }

  const collapsibleItemIsOpen = (collapsibleItem: StoryblokContent): boolean => {
    return collapsibleState[collapsibleItem._uid]
  }

  const renderItems = (collapsibleItem: StoryblokContent): JSX.Element => {
    let classNames = styles.bodyContainer
    if (collapsibleItemIsOpen(collapsibleItem)) {
      classNames = classNames.concat(' ', styles.expanded)
    }
    if (!collapsibleItem.icon?.filename) {
      classNames = classNames.concat(' ', styles.noIcon)
    }
    return collapsibleItem.items.map((item: StoryblokContent) => (
      <div className={classNames} key={item._uid}>
        <p className={styles.bodyTitle}>{item.title}</p>
        <p className={styles.bodyText}>{render(item.textarea)}</p>

        {item.link && <DynamicLink linkObject={item.link}>{item.link_label}</DynamicLink>}
      </div>
    ))
  }

  function capitalizeFirstLetterOf(string: string): string {
    const firstLetter = string.match(/[A-Öa-ö]/)[0]
    const indexOfFirstLetter = string.indexOf(firstLetter)
    return replaceCharAtIndex(string.toLowerCase(), indexOfFirstLetter, firstLetter.toUpperCase())
  }

  function replaceCharAtIndex(string: string, index: number, character: string): string {
    if (index > string.length - 1) return string
    return string.substring(0, index) + character + string.substring(index + 1)
  }

  return (
    <ContentContainer className={styles.container} {...storyblokEditable(blok)}>
      {blok.items.map((collapsibleItem: StoryblokContent) => (
        <div id={collapsibleItem.anchor_link} className={styles.itemContainer} key={collapsibleItem._uid}>
          <ActionDiv className={styles.actionHeader} onMouseDown={() => toggleOpenState(collapsibleItem)} ariaLabel="expand collapsible item">
            <div className={styles.headerContainer} style={!collapsibleItem.icon?.filename ? { gridTemplateColumns: '0 1fr 30px', gap: 0 } : null}>
              <div className={styles.iconContainer}>
                {collapsibleItem.icon?.filename && (
                  <Image
                    src={collapsibleItem.icon.filename}
                    fill
                    sizes={`(max-width: ${ouTheme.breakpoints.desktop}) 100vw, 50vw`}
                    alt={collapsibleItem.icon.alt}
                  />
                )}
              </div>
              <h2 className={styles.header}>{capitalizeFirstLetterOf(collapsibleItem.title)}</h2>
              <div className={collapsibleItemIsOpen(collapsibleItem) ? `${styles.arrowContainer} ${styles.open}` : styles.arrowContainer}>
                <DownArrow dark />
              </div>
            </div>
          </ActionDiv>
          {renderItems(collapsibleItem)}
        </div>
      ))}
    </ContentContainer>
  )
}

export default CollapsibleSection
