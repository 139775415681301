import { useRouter } from 'next/router'
import { FC, useEffect, useState } from 'react'
import { StoryblokContent } from 'types/StoryblokContent'

import { storyblokEditable } from '@storyblok/react'

import AssetSliderItem from '../../../types/AssetSliderItem'
import isVideo from '../../../utils/is-video'
import AssetSlider from '../../assetslider/AssetSlider'
import StoryblokImage from '../../storyblokimage/StoryblokImage'

import styles from './PhotobookDetails.module.scss'
import { ouTheme } from 'atomic/styles/theme.css'

type PropTypes = {
  blok?: StoryblokContent
}

const PhotobookDetails: FC<PropTypes> = ({ blok }) => {
  const [selectedAssetItem, setSelectedAssetItem] = useState<StoryblokContent>(null)
  const router = useRouter()

  useEffect(() => {
    const handleRouteChange = (): void => {
      setSelectedAssetItem(null) // reset to force reloading of text so we get the correct locale
    }

    router.events.on('routeChangeComplete', handleRouteChange)

    // If the component is unmounted, unsubscribe from the event with the `off` method:
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [])

  const handleAssetItemClick = (assetItem: StoryblokContent): void => {
    setSelectedAssetItem(assetItem)
  }

  const renderAssetItems = (): JSX.Element[] => {
    return blok.asset_items.map((assetItem: StoryblokContent) => {
      const filename = assetItem.asset.filename
      if (isVideo(filename)) {
        return (
          <div
            key={assetItem._uid}
            tabIndex={0}
            role="button"
            onKeyDown={null}
            onClick={() => handleAssetItemClick(assetItem)}
            className={styles.videoContainer}
          >
            <video
              muted
              playsInline={true}
              width={93}
              height={93}
              onClick={() => handleAssetItemClick(assetItem)}
              className={`${assetItem._uid === selectedAssetItem?._uid ? `${styles.outline} ${styles.playable}` : styles.playable}`}
            >
              <source src={filename} />
            </video>
          </div>
        )
      }
      return (
        <div key={assetItem._uid} className={styles.imageContainer}>
          <StoryblokImage
            src={filename}
            fill
            alt={assetItem.asset.name}
            quality={100}
            onClick={() => handleAssetItemClick(assetItem)}
            className={`${assetItem._uid === selectedAssetItem?._uid ? styles.outline : ''}`}
          />
        </div>
      )
    })
  }

  const renderSelectedAssetItem = (): JSX.Element => {
    if (selectedAssetItem === null && blok.asset_items !== null && blok.asset_items.length > 0) {
      setSelectedAssetItem(blok.asset_items[0])
    }

    if (selectedAssetItem !== null) {
      const filename = selectedAssetItem.asset.filename

      if (isVideo(filename)) {
        return (
          <div className={styles.videoContainer}>
            <video autoPlay loop muted playsInline={true} width={623} height={623}>
              <source src={filename} />
            </video>
            <p>{selectedAssetItem.description}</p>
          </div>
        )
      }

      return (
        <div>
          <StoryblokImage
            src={filename}
            fill
            sizes={`(max-width: ${ouTheme.breakpoints.desktop}) 100vw, 50vw`}
            alt={selectedAssetItem.asset.name}
            quality={100}
          />
          <p className={styles.descriptionText}>{selectedAssetItem.description}</p>
        </div>
      )
    }
  }

  const renderTextContainers = (): JSX.Element[] => {
    return blok.text_containers.map((textContainer: StoryblokContent) => {
      return (
        <div className={styles.textContainer} key={textContainer._uid}>
          <h2 className={styles.title}>{textContainer.title}</h2>
          <p className={styles.sizeAndPrice}>{textContainer.size_and_price}</p>
          <p className={styles.subtitle}>{textContainer.subtitle}</p>
          <p>{textContainer.text}</p>
        </div>
      )
    })
  }

  const assetSliderItems: AssetSliderItem[] = blok.asset_items.map((item) => ({
    id: item._uid,
    filename: item.asset.filename,
    alt: item.asset.name,
    width: 265,
    height: 265,
  }))

  return (
    <section className={styles.container} {...storyblokEditable(blok)}>
      <div className={styles.textSection}>
        <h3>{blok.title}</h3>
        <p>{blok.intro_text}</p>
      </div>
      <div className={styles.contentContainer}>
        <div className={`${styles.assetHolder} largerThanLarge`}>
          <div className={styles.assetContainer}>{renderAssetItems()}</div>
          <div className={styles.selectedAssetContainer}>{renderSelectedAssetItem()}</div>
        </div>
        <div className="smallerThanLarge">
          <AssetSlider assets={assetSliderItems}></AssetSlider>
        </div>
        <div className={styles.coverTextContainer}>{renderTextContainers()}</div>
      </div>
      {blok.bottom_border && <div className={styles.bottomBorder} />}
    </section>
  )
}

export default PhotobookDetails
