export type BuilderProps = { builderBlock?: unknown; builderState?: unknown }
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function removeKeys<T extends object, K extends keyof T>(baseObj: T, keysToRemove: Record<K, boolean>): Partial<T> {
  const result: Partial<T> = { ...baseObj }
  for (const key in keysToRemove) {
    if (keysToRemove.hasOwnProperty(key) && keysToRemove[key]) {
      delete result[key]
    }
  }
  return result
}
