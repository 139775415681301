import React, { FC } from 'react'
import { NODE_PARAGRAPH, NODE_UL, render } from 'storyblok-rich-text-react-renderer'
import { StoryblokContent } from 'types/StoryblokContent'

import { storyblokEditable } from '@storyblok/react'

import isVideo from '../../../utils/is-video'
import StoryblokImage from '../../storyblokimage/StoryblokImage'

import styles from './Article.module.scss'
import { ouTheme } from 'atomic/styles/theme.css'

type PropTypes = {
  blok?: StoryblokContent
}

const Article: FC<PropTypes> = ({ blok }) => {
  return (
    <div className={styles.container} key={blok._uid} {...storyblokEditable(blok)}>
      <div className={styles.headerContainer}>
        <div>
          <h1 className={styles.title}>{blok.title}</h1>

          {blok.description && <p className={styles.description}>{blok.description}</p>}
        </div>
      </div>
      <div className={styles.contentContainer}>
        <div>
          {render(blok.content, {
            blokResolvers: {
              ['article_asset_video']: (props) => {
                const assetItem = props.asset
                const filename = assetItem.filename
                if (isVideo(filename)) {
                  return (
                    <p key={assetItem._uid} className={`${styles.videoContainer} ${styles.pFullWidth}`}>
                      <video autoPlay loop muted playsInline={true}>
                        <source src={filename} />
                      </video>
                    </p>
                  )
                }
              },
              ['article_small_image']: (props) => {
                const imageItem = props.image
                const filename = imageItem.filename
                if (!isVideo(filename)) {
                  return (
                    <div key={imageItem._uid} className={`${styles.pFullWidth}`}>
                      <div className={`${styles.smallImageContainer}`}>
                        <StoryblokImage
                          src={imageItem.filename}
                          fill
                          style={{ objectFit: 'contain' }}
                          sizes={`(max-width: ${ouTheme.breakpoints.desktop}) 100vw, 50vw`}
                          quality={100}
                          alt={imageItem.name}
                        />
                      </div>
                    </div>
                  )
                }
              },
            },
            nodeResolvers: {
              // eslint-disable-next-line react/display-name
              [NODE_PARAGRAPH]: (children, props) => {
                let paragraphContainsImg = false
                let paragraphContainsPureText = false

                // if the paragraph doesn't have StoryblokContent children it can only contain pure text
                if (!children) {
                  paragraphContainsPureText = true
                }

                for (let i = 0; i < children?.length; i++) {
                  if (children[i].type === 'img') {
                    paragraphContainsImg = true
                  } else if (children[i].type === undefined) {
                    // if type is missing it means the child contains pure text
                    paragraphContainsPureText = true
                  } else {
                    paragraphContainsPureText = true
                  }
                }
                return (
                  // if a node contains both an image and pure text we prioritize styling for the text, a.k.a. narrower width :)
                  <p
                    className={
                      paragraphContainsImg && !paragraphContainsPureText ? `${styles.imageContainer} ${styles.pFullWidth}` : styles.pNarrowWidth
                    }
                    {...props}
                  >
                    {children}
                  </p>
                )
              },
              // eslint-disable-next-line react/display-name
              [NODE_UL]: (children, props) => {
                return (
                  // fix styling for unordered lists so the chosen alignment moves the entire list, but the items in it are always left-aligned
                  <div className={`${styles.pNarrowWidth}`} {...props}>
                    <ul className={`${styles.unorderedList}`}>{children}</ul>
                  </div>
                )
              },
            },
          })}

          <p className={`${styles.pNarrowWidth} ${styles.signatureContainer}`}>
            <span className={styles.signatureName}>{blok.signature_name}</span>
            <a href={`mailto:${blok.signature_email?.email}`}>{blok.signature_email?.email}</a>
          </p>
        </div>
      </div>
    </div>
  )
}

export default Article
