import { Container, Heading, Box, Text } from 'atomic'
import React, { FC } from 'react'

import * as css from './ListBlog.css'
import { ThemeColors } from 'atomic/styles/theme.css'
import { BoxProps } from 'atomic/components/atoms/box/Box'
import { BuilderProps, removeKeys } from '../../helpers/BuilderPropCleaner'
import SmoothScrollToId from 'utils/smooth-scroll-to-id'

type ListItem = {
  title: string
  paragraph: string
  anchorRef?: boolean
  anchorRefName?: string
  anchorLink?: boolean
  anchorLinkName?: string
}

type ListBlogProps = {
  dotColor: keyof ThemeColors
  startIndex?: number
  list: ListItem[]
} & BoxProps &
  BuilderProps

const ListBlog: FC<ListBlogProps> = ({ list, dotColor, startIndex = 1, ...props }) => {
  const cleanProps = removeKeys(props, { builderBlock: true, builderState: true })
  return (
    <Container
      marginBottom={props.marginBottom ?? 'xl'}
      display={'flex'}
      flexDirection={'column'}
      paddingLeft={'none'}
      paddingRight={'none'}
      className={css.listContainer}
      {...cleanProps}
    >
      {list &&
        list.map((item, index) => (
          <Box key={index} display={'flex'} alignItems={'center'} marginBottom={'m'}>
            <Box>
              <Heading
                as={'h4'}
                fontWeight={item.title ? 'semibold' : 'light'}
                fontSize={'base'}
                marginBottom={item.title ? 'xs' : 'none'}
                alignItems={'flex-start'}
                gap={'xs'}
                className={css.title}
                id={item.anchorRef ? item.anchorRefName : null}
                onClick={
                  item.anchorLink
                    ? () => SmoothScrollToId(item.anchorLinkName, { scrollMarginTop: '100px', scrollBehavior: 'smooth', scrollBlock: 'start' })
                    : null
                }
                style={item.anchorLink ? { cursor: 'pointer' } : null}
              >
                <Box as={'div'} background={dotColor} justifyContent={'center'} textAlign={'center'} className={css.dot}>
                  <Text fontFamily="accent" className={css.dotText} lineHeight={'xl'}>
                    {index + startIndex}
                  </Text>
                </Box>
                {item.title && item.title}
                {!item.title && <Box dangerouslySetInnerHTML={{ __html: item.paragraph }} />}
              </Heading>
              {item.title && <Box fontSize={'base'} dangerouslySetInnerHTML={{ __html: item.paragraph }} />}
            </Box>
          </Box>
        ))}
    </Container>
  )
}

export default ListBlog
