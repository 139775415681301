/* eslint-disable @next/next/no-img-element */
import { Container, Box, Text } from 'atomic'
import { CSSProperties, FC } from 'react'
import * as css from './ImagesComponent.css'
import { BoxProps } from 'atomic/components/atoms/box/Box'
import { BuilderProps, removeKeys } from '../helpers/BuilderPropCleaner'
import { useMediaQuery } from 'react-responsive'
import { ouTheme } from 'atomic/styles/theme.css'

type ImagesComponentProps = {
  images: ImageItem[]
  fullBleed?: boolean
  showGutter?: boolean
  globalBorderRadius?: number
} & BoxProps &
  BuilderProps

type ImageItem = {
  src: string
  alt: string
  maxHeight?: number
  caption?: string
  objectFit: 'cover' | 'contain'
  fractionInGrid: string
}

const ImagesComponent: FC<ImagesComponentProps> = ({ showGutter = true, fullBleed = false, globalBorderRadius = 8, images, ...props }) => {
  const [image1 = null, image2 = null] = images ?? []

  // Assert we have added images
  if (image1 == null && image2 == null) {
    return (
      <Container marginBottom={props.marginBottom ?? 'xxl'}>
        <Text>Add images in the Block options panel</Text>
      </Container>
    )
  }

  const isDesktopMobile = useMediaQuery({ maxWidth: ouTheme.breakpoints.tablet })

  const maxHeightStyleImage1: CSSProperties = image1?.maxHeight && image1?.maxHeight !== 0 ? { maxHeight: image1.maxHeight } : {}
  const maxHeightStyleImage2: CSSProperties = image2?.maxHeight && image2?.maxHeight !== 0 ? { maxHeight: image2.maxHeight } : {}

  const gridTemplateColumns: CSSProperties = {
    gridTemplateColumns: `${image1?.fractionInGrid} ${(image2 && image2?.fractionInGrid) ?? ''}`,
  }

  const gridGapStyle: CSSProperties = showGutter ? {} : { gridGap: 0 }
  const fullBleedStyle: CSSProperties = fullBleed ? { maxWidth: '100%', paddingLeft: 0, paddingRight: 0 } : {}

  const radius = fullBleed || !showGutter ? 0 : globalBorderRadius

  const imageCaptionSize = 's'

  const cleanProps = removeKeys(props, { builderBlock: true, builderState: true })

  return (
    <Container
      marginBottom={props.marginBottom ?? 'xxl'}
      className={css.grid}
      style={isDesktopMobile ? { ...gridGapStyle, ...fullBleedStyle } : { ...gridTemplateColumns, ...fullBleedStyle, ...gridGapStyle }}
      gridColumnGap={!isDesktopMobile ? 'm' : 'none'}
      gridRowGap={isDesktopMobile ? 'm' : 'none'}
      {...cleanProps}
    >
      <Box className={css.imageContainer}>
        <img
          alt={image1?.alt}
          className={css.img}
          src={image1?.src}
          style={{ objectFit: image1.objectFit, ...maxHeightStyleImage1, borderRadius: radius }}
        />
      </Box>
      {image2 && (
        <Box className={css.imageContainer}>
          <img
            alt={image2.alt}
            className={css.img}
            src={image2.src}
            style={{ objectFit: image2.objectFit, ...maxHeightStyleImage2, borderRadius: radius }}
          />
        </Box>
      )}
      {image1?.caption && (
        <Text className={css.caption} fontSize={imageCaptionSize}>
          {image1?.caption}
        </Text>
      )}
      {image2 && image2?.caption && (
        <Text className={css.caption} fontSize={imageCaptionSize}>
          {image2?.caption}
        </Text>
      )}
    </Container>
  )
}

export default ImagesComponent
