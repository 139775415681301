import React, { FC, ReactNode } from 'react'

import Box, { BoxProps } from '../../atoms/box/Box'
import * as styles from './Hint.css'

type HintProps = Omit<BoxProps, 'position' | 'alignItems' | 'textAlign' | 'justifyContent' | 'flexDirection' | 'gap'> & {
  illustration?: ReactNode
  rotation?: 'none' | '-10deg' | '-5deg' | '5deg' | '10deg'
  children?: React.ReactNode
}

const Hint: FC<HintProps> = ({ children, rotation = 'none', padding = 'xs', className, illustration, background = 'kantarell2', ...props }) => {
  return (
    <Box
      display="inline-flex"
      padding={padding}
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      gap="xxs"
      textAlign="center"
      className={`${styles.container} ${styles.containerRotation[rotation]}, ${className}`}
      {...props}
    >
      <Box className={styles.circle} background={background} borderRadius="full" />
      {illustration && <div className={styles.illustration}>{illustration} </div>}
      <Box marginBottom="none" className={styles.text}>
        {children}
      </Box>
    </Box>
  )
}

export default Hint
