import React, { CSSProperties } from 'react'
import { useSnapCarousel } from 'react-snap-carousel'

import * as css from './CardSlider.css'
import { CardSliderProps } from './types'

const styles: Record<string, CSSProperties> = {
  paginationButtonActive: { opacity: 0.3 },
}

const CardSlider = <T extends object>({ items, renderItem }: CardSliderProps<T>): JSX.Element => {
  const { scrollRef, pages, activePageIndex, goTo, snapPointIndexes } = useSnapCarousel()
  return (
    <div className={css.root}>
      <ul className={css.scroll} ref={scrollRef}>
        {items.map((item, i) =>
          renderItem({
            item,
            isSnapPoint: snapPointIndexes.has(i),
          })
        )}
      </ul>

      {pages?.length > 1 ? (
        <div className={css.controls} aria-hidden>
          {pages.map((_, i) => (
            <button
              key={i}
              className={css.paginationButton}
              style={{
                ...(activePageIndex === i ? styles.paginationButtonActive : {}),
              }}
              onClick={() => goTo(i)}
            >
              &bull;
            </button>
          ))}
        </div>
      ) : null}
    </div>
  )
}

export default CardSlider
