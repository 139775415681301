import { Box } from 'atomic'
import React, { FC } from 'react'
import { BuilderProps, removeKeys } from '../helpers/BuilderPropCleaner'
import { ouTheme, ThemeColors } from 'atomic/styles/theme.css'
import SocialMediaIcon, { SocialMediaIconType } from 'atomic/components/atoms/illustrations/SocialMediaIcon'
import { Link } from 'components/link/Link'
import { trackOutboundLink } from 'lib/analytics-utils'

export type SocialMediaLink = {
  label: string
  link: string
  icon: SocialMediaIconType
}

type SocialMediaProps = {
  className?: string
  direction?: 'vertical' | 'horizontal'
  iconSize?: 's' | 'm' | 'l'
  iconColor?: keyof ThemeColors
  iconSpacing?: keyof typeof ouTheme.spaces
  socialLink: SocialMediaLink[]
} & BuilderProps

const SocialMedia: FC<SocialMediaProps> = ({
  className,
  direction = 'horizontal',
  iconSize = 'm',
  iconColor = 'malm0',
  iconSpacing = 'xxxs',
  socialLink,
  ...props
}) => {
  const cleanProps = removeKeys(props, { builderBlock: true, builderState: true })

  let iconSizeNumber

  switch (iconSize) {
    case 's':
      iconSizeNumber = 16
      break
    case 'm':
      iconSizeNumber = 24
      break
    case 'l':
      iconSizeNumber = 32
      break
    default:
      iconSizeNumber = 32
  }

  return (
    <Box className={`${className}`} display={'flex'} flexDirection={direction === 'vertical' ? 'column' : 'row'} gap={iconSpacing} {...cleanProps}>
      {socialLink?.map((link, index) => (
        <Link
          key={index}
          onClick={() => trackOutboundLink(link.link)}
          href={link.link}
          target={'_blank'}
          rel={'noreferrer'}
          aria-label={link.label}
          style={{ outline: 0 }}
        >
          <SocialMediaIcon name={link.icon} color={iconColor} width={iconSizeNumber} height={iconSizeNumber} />
        </Link>
      ))}
    </Box>
  )
}

export default SocialMedia
