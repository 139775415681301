import { FC } from 'react'
import { StoryblokContent } from 'types/StoryblokContent'

import { Button } from '@onceuponapp/ui'
import { storyblokEditable } from '@storyblok/react'

import { useModalState } from '../../../context/ModalStateContext'
import ContentContainer from '../../styled/ContentContainer'
import IllustrationContainer from '../illustrationcontainer/IllustrationContainer'

import styles from './GetNewsletterSection.module.scss'

type PropTypes = {
  blok?: StoryblokContent
}

const GetNewsletterSection: FC<PropTypes> = ({ blok }) => {
  const { setShowGetNewsletterModal } = useModalState()

  return (
    <section className={styles.container} {...storyblokEditable(blok)}>
      <ContentContainer className={styles.contentContainer}>
        <IllustrationContainer blok={blok.illustration_container[0]} className={styles.illustrationContainer} />
        <Button onClick={() => setShowGetNewsletterModal(true)}>{blok.button_title}</Button>
      </ContentContainer>
    </section>
  )
}

export default GetNewsletterSection
