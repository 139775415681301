import { builder } from '@builder.io/sdk'
import { useRouter } from 'next/router'
import { useState, useEffect } from 'react'

type BlogArticleData = {
  author: { name: string; email: string; photo: string }
  slug: string
  tag: string[]
  categories: string[]
  intro: string
  title: string
  readingTime: number
}

const useBlogArticleInfo = (): { createdDate: number; startDate: number; blogData: BlogArticleData } | null => {
  const [createdDate, setCreatedDate] = useState<number>(null)
  const [startDate, setStartDate] = useState<number>(null)
  const [blogData, setBlogData] = useState<BlogArticleData>(null)
  const contentType = 'blog-article'

  const router = useRouter()

  useEffect(() => {
    const fetchData = async () => {
      try {
        const article =
          (await builder
            .get('blog-article', {
              staleCacheSeconds: 0,
              cacheSeconds: 0,
              options: { includeRefs: true, locale: router.locale },
              userAttributes: { locale: router.locale },
              fields: 'createdDate,startDate,data.author.value.data,data.slug,data.tag,data.categories,data.intro,data.title,data.readingTime',
              query: {
                'data.slug': router.query.slug,
              },
            })
            .toPromise()) || null

        setCreatedDate(article.createdDate)
        setStartDate(article.startDate)
        setBlogData({
          author: article?.data?.author?.value?.data,
          slug: article?.data?.slug ?? '',
          tag: article?.data?.tag ?? [],
          categories: article?.data?.categories ?? [],
          intro: article?.data?.intro ?? '',
          title: article?.data?.title ?? '',
          readingTime: article?.data?.readingTime ?? undefined,
        })
      } catch (error) {
        setCreatedDate(null)
        setStartDate(null)
      }
    }

    fetchData()
  }, [contentType])

  return { createdDate, startDate, blogData }
}

export default useBlogArticleInfo
