/* eslint-disable @typescript-eslint/no-empty-function */

import { createContext, Dispatch, FC, ReactNode, SetStateAction, useContext, useState } from 'react'

type ModalStateContextType = {
  showGetAppModal: boolean
  setShowGetAppModal: Dispatch<SetStateAction<boolean>>
  showGetNewsletterModal: boolean
  setShowGetNewsletterModal: Dispatch<SetStateAction<boolean>>
  showGetStoriesModal: boolean
  setShowGetStoriesModal: Dispatch<SetStateAction<boolean>>
  showGetAppModalFromProductPage: boolean
  setShowGetAppModalFromProductPage: Dispatch<SetStateAction<boolean>>
}

const ModalStateContextDefaultValues: ModalStateContextType = {
  showGetAppModal: false,
  setShowGetAppModal: () => {},
  showGetNewsletterModal: false,
  setShowGetNewsletterModal: () => {},
  showGetStoriesModal: false,
  setShowGetStoriesModal: () => {},
  showGetAppModalFromProductPage: false,
  setShowGetAppModalFromProductPage: () => {},
}

const ModalStateContext = createContext<ModalStateContextType>(ModalStateContextDefaultValues)

export function useModalState(): ModalStateContextType {
  return useContext(ModalStateContext)
}

type PropTypes = {
  children: ReactNode
}

export const ModalStateProvider: FC<PropTypes> = ({ children }) => {
  const [showGetAppModal, setShowGetAppModal] = useState<boolean>(false)
  const [showGetNewsletterModal, setShowGetNewsletterModal] = useState<boolean>(false)
  const [showGetStoriesModal, setShowGetStoriesModal] = useState<boolean>(false)
  const [showGetAppModalFromProductPage, setShowGetAppModalFromProductPage] = useState<boolean>(false)

  const value = {
    showGetAppModal,
    setShowGetAppModal,
    showGetNewsletterModal,
    setShowGetNewsletterModal,
    showGetStoriesModal,
    setShowGetStoriesModal,
    showGetAppModalFromProductPage,
    setShowGetAppModalFromProductPage,
  }

  return (
    <>
      <ModalStateContext.Provider value={value}>{children}</ModalStateContext.Provider>
    </>
  )
}
