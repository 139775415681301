import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'

import { getIsDevelopment, getIsProduction } from 'utils/get-environment'

export const getJwtToken = async (): Promise<string> => {
  if (!firebase.auth().currentUser) {
    await firebase.auth().signInWithEmailAndPassword(process.env.NEXT_FIREBASE_EMAIL, process.env.NEXT_FIREBASE_PASSWORD)
  }

  const result = await firebase.auth().currentUser.getIdToken()
  return result
}

export const firebaseUser = { email: process.env.NEXT_FIREBASE_EMAIL, password: process.env.NEXT_FIREBASE_PASSWORD }
export const firebaseStagingConfig = {
  apiKey: process.env.NEXT_FIREBASE_API_KEY,
  authDomain: 'once-upon-staging.firebaseapp.com',
  databaseURL: 'https://once-upon-staging.firebaseio.com',
  projectId: 'once-upon-staging',
  storageBucket: 'once-upon-staging.appspot.com',
  messagingSenderId: '759590667561',
  appId: '1:759590667561:web:09b5fcaf0a7b48018ce338',
  measurementId: 'G-E17K015H5Q',
}

const firebaseDevConfig = {
  apiKey: process.env.NEXT_FIREBASE_API_KEY,
  authDomain: 'once-upon-dev.firebaseapp.com',
  projectId: 'once-upon-dev',
}
const firebaseProductionConfig = {
  apiKey: process.env.NEXT_FIREBASE_API_KEY,
  authDomain: 'once-upon-4f42c.firebaseapp.com',
  databaseURL: 'https://once-upon-4f42c.firebaseio.com',
  projectId: 'once-upon-4f42c',
  storageBucket: 'once-upon-4f42c.appspot.com',
  messagingSenderId: '516538798264',
  appId: '1:516538798264:web:05e989a68e7b486c2ea44d',
  measurementId: 'G-TZ43WR8TE7',
}

if (!firebase.apps.length) {
  const config = getIsProduction() ? firebaseProductionConfig : getIsDevelopment() ? firebaseDevConfig : firebaseStagingConfig
  firebase.initializeApp(config)
}

export default firebase
