import { IllustrationType, KluddIllustrationType } from 'atomic/components/atoms/illustrations'
import { ArrowIllustrationType } from 'atomic/components/atoms/illustrations/ArrowIllustration'
import React, { FC } from 'react'
import { BuilderProps, removeKeys } from '../helpers/BuilderPropCleaner'
import { Box } from 'atomic'
import { ThemeColors } from 'atomic/styles/theme.css'
import Illustrations from 'atomic/components/atoms/illustrations/Illustration'

type ArrowOptions = {
  arrowDirection: 'up' | 'down'
  arrowRotation: number
}

type ImageItem = {
  src: string
  alt: string
  width: number
  height: number
}

type IllustrationProps = {
  illustration: IllustrationType | KluddIllustrationType | ArrowIllustrationType
  illustrationColor: keyof ThemeColors
  illustrationPosition: 'left' | 'center' | 'right'
  illustrationSize: 's' | 'm' | 'l'
  arrowOptions?: ArrowOptions
  useImage?: boolean
  image: ImageItem
} & BuilderProps

const Illustration: FC<IllustrationProps> = ({
  illustration = 'arrow-5',
  illustrationColor = 'malm0',
  illustrationPosition = 'left',
  illustrationSize = 'm',
  arrowOptions,
  useImage,
  image,
  ...props
}) => {
  // Remove Builder.io specific props
  const cleanProps = removeKeys(props, { builderBlock: true, builderState: true })

  const position = illustrationPosition === 'center' ? 'center' : illustrationPosition === 'right' ? 'flex-end' : 'flex-start'

  let size
  switch (illustrationSize) {
    case 's':
      size = 51
      break
    case 'm':
      size = 102
      break
    case 'l':
      size = 153
      break
  }

  let direction
  switch (arrowOptions?.arrowDirection) {
    case 'up':
      direction = 'scaleY(-1)'
      break
    default:
      direction = 'scaleY(1)'
      break
  }

  return (
    <Box display={'flex'} alignItems={'center'} justifyContent={position} {...cleanProps}>
      {!useImage && (
        <Illustrations
          name={illustration}
          color={illustrationColor}
          height={size}
          style={{ transform: `${direction} rotate(${arrowOptions?.arrowRotation * 3.6}deg)` }}
        />
      )}
      {useImage && (
        <img src={image.src} alt={image.alt} style={{ objectFit: 'contain', width: image.width ?? 400, height: image.height ?? 'auto' }} />
      )}
    </Box>
  )
}

export default Illustration
