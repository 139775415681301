import { FC } from 'react'
import { StoryblokContent } from 'types/StoryblokContent'

import { storyblokEditable } from '@storyblok/react'

import { useBackend } from '../../../context/BackendContext'
import ContentContainer from '../../styled/ContentContainer'

import styles from './DeliveryOptions.module.scss'

type PropTypes = {
  blok?: StoryblokContent
}

const DeliveryOptions: FC<PropTypes> = ({ blok }) => {
  const { deliveryInfo, paymentOptions } = useBackend()

  if (!deliveryInfo) {
    return null
  }

  return (
    <section className={styles.container} {...storyblokEditable(blok)}>
      <ContentContainer className={styles.contentContainer}>
        <p className={styles.introText}>{deliveryInfo.home_story_shipping_body}</p>

        <p className={styles.title}>{blok.title}</p>
        <p className={styles.text}>{blok.text}</p>
        <div className={styles.iconContainer}>
          {/* Use this instead if you want to use the 
              icons fetched from the backend instead */}
          {paymentOptions.map((option) => (
            // eslint-disable-next-line @next/next/no-img-element
            <img src={option.image?.url} alt={option.name} key={option.name} />
          ))}
        </div>
        <div></div>

        {deliveryInfo.countries.map((country) => (
          <div className={styles.countryContainer} key={country.country_code}>
            <p className={styles.countryName}>{country.country_name}</p>
            <p className={styles.delivery}>{country.delivery_copy}</p>
          </div>
        ))}
      </ContentContainer>
    </section>
  )
}

export default DeliveryOptions
