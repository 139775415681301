import DynamicComponent from 'components/StoryblokComponents'
import Compressor from 'compressorjs'
import dynamic from 'next/dynamic'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { Dispatch, FC, FormEvent, Fragment, SetStateAction, useEffect, useRef, useState } from 'react'

import ReCAPTCHA from 'react-google-recaptcha'
import { StoryblokContent } from 'types/StoryblokContent'
import log from 'utils/logger'

import { Button } from '@onceuponapp/ui'
import { storyblokEditable } from '@storyblok/react'

import Dropdown, { ListItem } from '../../dropdown/Dropdown'
import AddImage from '../../icons/AddImage'
import Loader from '../../icons/Loader'
import Modal from '../../modal/Modal'
import StoryblokImage from '../../storyblokimage/StoryblokImage'
import { SubmitMessage, SubmitMessageStatus } from '../../submitmessage/SubmitMessage'
import { CategoryDropdownItem } from '../supportform/SupportForm'

import styles from './FormSection.module.scss'

const SubmitMessageComponent = dynamic(() => import('../../submitmessage/SubmitMessage'), { ssr: false })

type DropdownItem = {
  label: string
  key: string
}

export type FormInput = {
  uuid: string
  label: string
  name: string
  placeholder: string
  type: string
  items?: DropdownItem
  component?: string
  header?: string
  description?: string
  full_width?: boolean
  validation_text?: string
  validators: Validator[]
}

export type Validator = { type: string; validate: (value: string) => string }

const createPatternValidator = (regex: string, errorMessage: string): Validator => {
  return {
    type: 'pattern',
    validate: (value: string) => {
      if (new RegExp(regex).test(value)) {
        return ''
      }

      return errorMessage
    },
  }
}

type PropTypes = {
  blok?: StoryblokContent
  hide?: boolean
  clearSubmitMessageStatus?: boolean
  handleOnSubmit: (event: React.SyntheticEvent, token: string) => Promise<void>
  selectedDropdownItem?: CategoryDropdownItem
  onSelectedDropdownItem?: (listItem: ListItem) => void
  setSelectedDropdownItem?: Dispatch<SetStateAction<CategoryDropdownItem>>
  setFiles?: Dispatch<SetStateAction<{ [key: string]: File }>>
  getFiles?: { [key: string]: File }
  isModal?: boolean
}

const NUMBER_OF_FILE_INPUTS = 3

const FormSection: FC<PropTypes> = ({
  blok,
  hide,
  clearSubmitMessageStatus,
  handleOnSubmit,
  selectedDropdownItem,
  onSelectedDropdownItem,
  setSelectedDropdownItem,
  setFiles,
  getFiles,
  isModal,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [submitMessage, setSubmitMessage] = useState<SubmitMessage | null>(null)
  const [errors, setErrors] = useState<{ [key: string]: string }>({})
  const [submitted, setSubmitted] = useState<boolean>()
  const [showSucceedModal, setShowSucceedModal] = useState(false)
  const formRef = useRef<HTMLFormElement>(null)
  const recaptchaRef = useRef(null)
  const router = useRouter()

  useEffect(() => {
    resetForm()
  }, [router.locale])

  useEffect(() => {
    if (clearSubmitMessageStatus) {
      setSubmitMessage(null)
    }
  }, [clearSubmitMessageStatus])

  useEffect(() => {
    if (submitted) {
      fieldsAreValid(formRef.current.elements)
    }
  }, [selectedDropdownItem, submitted])

  const handleSelectedFile = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, files } = e.target

    if (files.length > 0) {
      const file = files[0]
      const tenMbInBytes = 10485760
      if (file.size > tenMbInBytes) {
        log.error(`Uploaded file is too large, ${file.size}`)
        setSubmitMessage({ status: SubmitMessageStatus.Negative, text: getValidationText('Photos') })
        return
      }
      setSubmitMessage(null)
      new Compressor(file, {
        quality: 0.5,
        success(result: File) {
          setFiles((prevState) => ({
            ...prevState,
            [name]: result,
          }))
        },
        error(err) {
          log.error(err.message)
        },
      })
    }
  }

  const deleteFile = (id: string): void => {
    const newFiles = { ...getFiles }
    if (newFiles[id]) {
      delete newFiles[id]
      setFiles(newFiles)
    }
  }

  const getUrlForFile = (id: string): string | null => {
    const file = getFiles[id]
    if (file) {
      return URL.createObjectURL(file)
    } else {
      return null
    }
  }

  const getNameForFile = (id: string): string | null => {
    const file = getFiles[id]
    if (file) {
      return file.name
    } else {
      return null
    }
  }

  const fieldIsRequired = (id: string): boolean => {
    if (!id) {
      // default input, should always be required
      return true
    }
    if (selectedDropdownItem) {
      return !!selectedDropdownItem.required_input_fields?.find((fieldId) => fieldId === id)
    }
  }

  const mergedFields = (): FormInput[] => {
    let formInputs = blok.fields

    if (selectedDropdownItem && selectedDropdownItem.input_fields) {
      const dynamicInputs: FormInput[] = selectedDropdownItem.input_fields.map((field) => ({ ...field.content, uuid: field.uuid }))
      formInputs = [...formInputs, ...dynamicInputs]
    }

    if (blok.global_fields) {
      const globalInputs: FormInput[] = blok.global_fields.map((field) => ({ ...field.content, uuid: field.uuid }))
      formInputs = [...formInputs, ...globalInputs]
    }

    return formInputs.map((input) => ({
      ...input,
      validators: input.validators?.map((validator) => {
        switch (validator.component) {
          case 'pattern':
            return createPatternValidator(validator.regex, validator.message)
        }
      }),
    }))
  }

  const helpText = (): React.ReactElement => {
    if (!selectedDropdownItem || !selectedDropdownItem.information_text || selectedDropdownItem.information_text.length < 1) {
      return null
    }
    const helpBlok = selectedDropdownItem.information_text[0]
    return (
      <div className={styles.helpContainer}>
        <StoryblokImage alt="help icon" src={helpBlok.icon?.filename} width={80} height={80} className={styles.helpIcon} />
        <div>
          <p className={styles.helpHeader}>{helpBlok.title}</p>
          <p className={styles.helpText}>{helpBlok.text}</p>
        </div>
      </div>
    )
  }

  const fields = (): React.ReactElement[] => {
    return mergedFields().map((input, index) => {
      switch (input.component) {
        case 'form_input':
          if (input.type === 'checkbox') {
            return (
              <div key={index} className={styles.inputContainer} style={input.full_width ? { gridColumn: '1 / -1' } : null}>
                <label
                  className={fieldIsRequired(input.uuid) && !isModal ? `${styles.label} ${styles.required}` : `${styles.label} ${styles.flex}`}
                  htmlFor={input.name}
                >
                  <span>{InputField(input)}</span>
                  <span className={styles.checkboxLabel}>{input.label}</span>
                </label>
                {input.description && <p className={styles.description}>{input.description}</p>}
              </div>
            )
          } else if (input.type === 'helpText') {
            return helpText()
          }

          return (
            <div key={index} className={styles.inputContainer} style={input.full_width ? { gridColumn: '1 / -1' } : null}>
              <label className={fieldIsRequired(input.uuid) && !isModal ? `${styles.label} ${styles.required}` : styles.label} htmlFor={input.name}>
                {input.label}
              </label>
              {input.description && <p className={styles.description}>{input.description}</p>}
              {InputField(input)}
            </div>
          )
        case 'form_dropdown':
          return (
            <Fragment key={index}>
              <Dropdown
                list={input.items as unknown as ListItem[]}
                label={input.label}
                header={input.header}
                selectedItem={selectedDropdownItem}
                onItemSelected={onSelectedDropdownItem}
                className={!selectedDropdownItem && submitted ? `${styles.invalid} ${styles.dropdown}` : styles.dropdown}
              />
            </Fragment>
          )
        default:
          break
      }
    })
  }

  const idFromName = (name: string): string => {
    return name.replace(/ /g, '').toLowerCase()
  }

  const InputField = (input: FormInput): React.ReactElement => {
    const hasErrors = errors[idFromName(input.name)]

    switch (input.type) {
      case 'textarea':
        return (
          <>
            <textarea
              className={hasErrors ? styles.invalid : null}
              required={fieldIsRequired(input.uuid)}
              id={idFromName(input.name)}
              rows={8}
              name={input.name}
              placeholder={input.placeholder}
              onBlur={(e) => validateField(e.target)}
            />
            {hasErrors && <span className={styles.errorMessage}>{errors[idFromName(input.name)]}</span>}
          </>
        )
      case 'file':
        return (
          <div className={styles.fileInputContainer}>
            {Array.from({ length: NUMBER_OF_FILE_INPUTS }, (_, i) => {
              const id = `${idFromName(input.name)}_${i}`
              const uploadedImageFile = getUrlForFile(id)
              if (uploadedImageFile) {
                return (
                  <div key={i} className={styles.imagePreviewContainer}>
                    <span />
                    <button onClick={() => deleteFile(id)} className={styles.closeButton}>
                      &#215;
                    </button>
                    <Image src={uploadedImageFile} fill style={{ objectFit: 'contain' }} alt="preview" />
                    <p className={styles.fileName}>{getNameForFile(id)}</p>
                  </div>
                )
              }
              return (
                <label key={i} className={`${errors[id] && styles.invalid} ${styles.customFileLabel}`}>
                  <input
                    id={id}
                    onChange={(e) => {
                      handleSelectedFile(e)
                    }}
                    name={id}
                    type={input.type}
                    placeholder={input.placeholder}
                    required={fieldIsRequired(input.uuid)}
                    accept="image/*"
                  />
                  <div className={styles.customFileButton}>
                    <AddImage />
                  </div>
                </label>
              )
            })}
          </div>
        )
      default:
        const classNames = [...(hasErrors ? [styles.invalid] : []), ...(isModal ? [styles.isModal] : [])].join(' ')
        return (
          <>
            <input
              required={fieldIsRequired(input.uuid)}
              className={classNames}
              onBlur={(e) => validateField(e.target)}
              id={idFromName(input.name)}
              name={input.name}
              type={input.type}
              placeholder={input.placeholder}
            />
            {hasErrors && <span className={styles.errorMessage}>{errors[idFromName(input.name)]}</span>}
          </>
        )
    }
  }

  const getValidationText = (name: string): string => {
    const formInputs: FormInput[] = mergedFields()

    return formInputs.find((input) => input.name === name)?.validation_text
  }

  const getField = (name: string): FormInput => {
    return mergedFields().find((input) => input.name === name)
  }

  // const getPlaceholderText = (name: string): string => {
  //   const formInputs: FormInput[] = mergedFields()

  //   return formInputs.find((input) => input.name === name)?.placeholder
  // }

  const filesAreValid = (): boolean => {
    return getFiles && Object.keys(getFiles).length > 0
  }

  const fieldsAreValid = (elements: HTMLFormControlsCollection): boolean => {
    const result = Array.from(elements)
      .reverse()
      .map((input: HTMLInputElement) => {
        const valid = validateField(input)
        if (!valid) window.scrollTo({ behavior: 'smooth', top: input.offsetTop - 120 })

        return validateField(input)
      })
      .every((f) => f)

    return result
  }

  const validateField = (input: HTMLInputElement | HTMLTextAreaElement): boolean => {
    input.setCustomValidity('')
    const field = getField(input.name)

    setErrors((errors) => ({ ...errors, [input.id]: undefined }))

    if (input.type === 'file') {
      if (input.required && !filesAreValid()) {
        input.setCustomValidity('error')
        setErrors((errors) => ({ ...errors, [input.id]: field?.validation_text ? field?.validation_text : ' ' }))

        return false
      }

      return true
    }

    if (!input.required && !input.value) {
      return true
    }

    if (!input.value) {
      setErrors((errors) => ({ ...errors, [input.id]: field?.validation_text ? field?.validation_text : ' ' }))
      input.setCustomValidity('error')
      return false
    }

    if (field?.validators) {
      field.validators.forEach((validator) => {
        const errorMessage = validator.validate(input.value)
        input.setCustomValidity(errorMessage)
        setErrors((errors) => ({ ...errors, [input.id]: errorMessage }))
      })
    }

    return input.validity.valid
  }

  const onSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault()
    setSubmitted(true)

    if (!fieldsAreValid(event.currentTarget.elements)) {
      return
    }

    const token = await recaptchaRef.current.executeAsync()

    setIsLoading(true)

    if (!token) {
      setIsLoading(false)
      setSubmitMessage(null)
      return
    }

    try {
      await handleOnSubmit(event, token)
      setShowSucceedModal(true)
      resetForm()
    } catch (error) {
      log.error(error)
      setSubmitMessage({ status: SubmitMessageStatus.Negative, text: null })
    }

    // Reset the reCAPTCHA so that it can be executed again if user submits another email.
    recaptchaRef.current.reset()
    setIsLoading(false)
  }

  const message = (): SubmitMessage | null => {
    if (submitMessage?.text) {
      return submitMessage
    }

    switch (submitMessage?.status) {
      case SubmitMessageStatus.Negative:
        return blok.error_message
      default:
        return null
    }
  }

  const resetForm = (): void => {
    formRef.current.reset()
    setSubmitMessage(null)
    setSelectedDropdownItem && setSelectedDropdownItem(null)
    setFiles && setFiles({})
    setErrors({})
    setSubmitted(false)
  }

  const succeedView = (): JSX.Element[] => {
    return blok.succeed_view?.map((item: StoryblokContent) => {
      return <DynamicComponent key={item._uid} blok={item} />
    })
  }

  return (
    <div {...storyblokEditable(blok)}>
      <Modal show={showSucceedModal} onClosed={() => setShowSucceedModal(false)}>
        <div className={styles.modalContainer}>
          {succeedView()}
          <div className={styles.buttonContainer}>
            <Button onClick={() => setShowSucceedModal(false)}>{blok.success_button_label}</Button>
          </div>
        </div>
      </Modal>
      <form noValidate className={styles.form} ref={formRef} onSubmit={onSubmit} style={{ display: hide && 'none' }}>
        <fieldset>
          {fields()}

          {/* fix for hiding infobox at end of page on primarly cancel order dropdown choice
          if any of the form inputs have 'helpText' as type do not show infobox */}
          {!mergedFields().some((i) => i.type === 'helpText') && helpText()}
          <div className={styles.buttonContainer}>
            <Button theme="secondary" onClick={() => resetForm()} disabled={isLoading}>
              {blok.reset_button}
            </Button>
            <Button type="submit" theme="primary" disabled={isLoading || (onSelectedDropdownItem && !selectedDropdownItem)}>
              {isLoading ? <Loader /> : blok.submit_button}
            </Button>
          </div>
        </fieldset>
      </form>

      <ReCAPTCHA
        ref={recaptchaRef}
        onExpired={() => recaptchaRef.current.reset()}
        size="invisible"
        sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}
      />

      <SubmitMessageComponent message={message()} style={{ display: hide && 'none' }} />
    </div>
  )
}

export default FormSection
