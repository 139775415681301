import { FC, useRef, useState } from 'react'

import AssetSliderItem from '../../types/AssetSliderItem'
import isVideo from '../../utils/is-video'
import ActionDiv from '../actiondiv/ActionDiv'
import StoryblokImage from '../storyblokimage/StoryblokImage'

import styles from './AssetSlider.module.scss'
import { ouTheme } from 'atomic/styles/theme.css'

type PropTypes = {
  assets?: AssetSliderItem[]
}

const AssetSlider: FC<PropTypes> = ({ assets }) => {
  const assetSliderContainerRef = useRef(null)
  const [showPreviousButton, setShowPreviousButton] = useState<boolean>(false)
  const [showNextButton, setShowNextButton] = useState<boolean>(true)
  let scrollDistance = 0

  const renderAssetItemsForSlider = (): JSX.Element[] => {
    return assets.map((assetItem: AssetSliderItem) => {
      // could be replaced by getting the width of the images, as it might change if the window scales down
      if (scrollDistance === 0) {
        scrollDistance = assetItem.width + 24 // 20 is the total padding on the left and right side
      }

      const filename = assetItem.filename
      if (isVideo(filename)) {
        return (
          <div key={assetItem.id} className={styles.assetContainer}>
            <video autoPlay loop muted playsInline={true}>
              <source src={filename} />
            </video>
          </div>
        )
      }

      return (
        <div key={assetItem.id} className={styles.assetContainer}>
          <StoryblokImage
            key={assetItem.id}
            src={filename}
            alt={assetItem.alt}
            quality={100}
            fill
            style={{ objectFit: 'contain' }}
            sizes={`(max-width: ${ouTheme.breakpoints.desktop}) 100vw, 50vw`}
          />
        </div>
      )
    })
  }

  const handleNextPreviousVisibility = (scrollLeft?: number): void => {
    if (!scrollLeft) {
      scrollLeft = assetSliderContainerRef.current.scrollLeft
    }
  }

  const handleScrollClick = (scrollAlteration): void => {
    // some weird timing issue required this approach, setting scrollLeft and passing it forward
    const scrollLeft = assetSliderContainerRef.current.scrollLeft + scrollAlteration
    assetSliderContainerRef.current.scrollLeft = scrollLeft
    handleNextPreviousVisibility(scrollLeft)
  }

  const onContainerScroll = (e: React.UIEvent<HTMLElement>): void => {
    const scrollLeft = e.currentTarget.scrollLeft
    const maxScrollLeft = assetSliderContainerRef.current.scrollWidth - assetSliderContainerRef.current.clientWidth

    setShowNextButton(scrollLeft < maxScrollLeft)
    setShowPreviousButton(scrollLeft !== 0)
  }

  return (
    <div className={styles.assetSliderContainer}>
      <div className={styles.previousButtonContainer} style={{ visibility: showPreviousButton ? 'visible' : 'hidden' }}>
        <ActionDiv ariaLabel="previous" className={`${styles.scrollButton} ${styles.previous}`} onClick={() => handleScrollClick(-scrollDistance)} />
      </div>
      <div className={styles.assetItemsContainer} ref={assetSliderContainerRef} onScroll={onContainerScroll}>
        {renderAssetItemsForSlider()}
      </div>
      <div className={styles.nextButtonContainer} style={{ visibility: showNextButton ? 'visible' : 'hidden' }}>
        <ActionDiv ariaLabel="next" className={`${styles.scrollButton} ${styles.next}`} onClick={() => handleScrollClick(scrollDistance)}></ActionDiv>
      </div>
    </div>
  )
}

export default AssetSlider
