import InternalLink from 'components/internallink/InternalLink'
import { useRouter } from 'next/router'
import { FC } from 'react'
import Slider from 'react-slick'
import { StoryblokContent } from 'types/StoryblokContent'

import { Button } from '@onceuponapp/ui'

import { useLink } from '../../../context/LinkContext'
import StoryblokImage from '../../storyblokimage/StoryblokImage'

import styles from './ArticleList.module.scss'

type PropTypes = {
  blok?: StoryblokContent
}

const ArticleList: FC<PropTypes> = ({ blok }) => {
  const router = useRouter()
  const { getLocalePath } = useLink()

  const getTranslatedLocalePath = (fullSlug: string, locale: string): string => {
    const slugWithoutLocale = fullSlug.replace(`${locale}/`, '')
    const localePath = getLocalePath(slugWithoutLocale, locale)
    return localePath
  }

  const renderArticleList = (blok: StoryblokContent): JSX.Element => {
    const articlelist = blok.articles
    return articlelist?.map((article) => (
      <div key={article.id}>
        <div className={`${styles.articleContainer}`}>
          <div className={`${styles.column} ${styles.articleImage}`}>
            <StoryblokImage
              src={article.content?.presentation_image?.filename}
              width={640}
              height={640}
              quality={100}
              alt={article.content?.presentation_image?.name}
              style={{ objectPosition: article.content?.presentation_image_focus ?? 'center' }}
            />
          </div>
          <div className={`${styles.column} ${styles.articleText}`}>
            <div className={styles.columnContentContainer}>
              <div className={styles.signature}>{article.content?.signature_name}</div>
              <div className={styles.title}>
                <h2>{article.content?.title}</h2>
              </div>
              <div className={styles.description}>{article.content?.description}</div>
              <div className={styles.buttonContainer}>
                <InternalLink href={getTranslatedLocalePath(article.full_slug, router.locale)} passHref>
                  <Button theme="secondary">{article.content?.read_more}</Button>
                </InternalLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    ))
  }

  const sliderSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 15000,
  }

  // only show articles if swedish locale
  if (router.locale.toLowerCase() !== 'sv-se') {
    return <div></div>
  }

  if (blok.articles.length === 0) {
    // fix/hack for known issue with infinite: true + fade: true, https://github.com/akiran/react-slick/issues/1343
    return <Slider key="0" />
  }

  return (
    <section className={styles.sectionContainer}>
      <div className={styles.contentContainer}>
        <div className={styles.articleListContainer}>
          <Slider {...sliderSettings}>{renderArticleList(blok)}</Slider>
        </div>
      </div>
    </section>
  )
}

export default ArticleList
