import log from 'utils/logger'
import LocalizedObject from '../../types/LocalizedObject'

const LanguagesStrings: LocalizedObject = {
  'en-gb': 'English (UK)',
  'en-au': 'English (Australia)',
  'en-us': 'English (US)',
  'en-ca': 'English (Canada)',
  'sv-se': 'Svenska',
  'de-de': 'Deutsch',
  'es-es': 'Español',
  'nb-no': 'Norsk',
  'nl-nl': 'Nederlands',
  'it-it': 'Italiano',
  'fr-fr': 'Français',
  'da-dk': 'Dansk',
}

export const languageStringFromLocale = (locale: string): string => {
  const languageString = LanguagesStrings[locale]
  if (!languageString) {
    log.error('Could not find language string')
    return 'Not found'
  }

  return languageString
}

export const localeFromLanguageString = (language: string): string => {
  return Object.keys(LanguagesStrings).find((key) => LanguagesStrings[key] === language)
}

export const defaultLocale = 'en-gb'
