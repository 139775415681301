import React, { CSSProperties } from 'react'

import * as css from './CardSliderItem.css'
import { CardSliderItemProps } from './types'

const styles: Record<string, CSSProperties> = {
  itemSnapPoint: {
    scrollSnapAlign: 'start',
  },
}

const CardSliderItem = ({ isSnapPoint, children }: CardSliderItemProps): JSX.Element => (
  <li
    className={css.item}
    style={{
      ...(isSnapPoint ? styles.itemSnapPoint : {}),
    }}
  >
    {children}
  </li>
)

export default CardSliderItem
