import { FC } from 'react'
import { useMediaQuery } from 'react-responsive'
import { withTheme } from 'styled-components'
import { StoryblokContent } from 'types/StoryblokContent'

import { storyblokEditable } from '@storyblok/react'

import { Theme } from '../../../styles/Theme'
import StoryblokImage from '../../storyblokimage/StoryblokImage'

import styles from './TipsAndTricks.module.scss'

type PropTypes = {
  blok?: StoryblokContent
  theme: Theme
}

const TipsAndTricks: FC<PropTypes> = ({ blok, theme }) => {
  const isMobile = useMediaQuery({ maxWidth: theme.screenSizes.small })
  const onlyTitle = blok.image.filename?.length === 0 && blok.text.length === 0

  return (
    <div className={styles.container} style={{ backgroundColor: blok.background_color?.color }} {...storyblokEditable(blok)}>
      {blok.image.filename.length > 0 && (
        <div className={styles.imageContainer}>
          <StoryblokImage
            src={blok.image.filename}
            height={isMobile ? blok.image_height_mobile || 118 : blok.image_height || 158}
            width={isMobile ? blok.image_width_mobile || 118 : blok.image_width || 158}
            alt={blok.image?.name}
          />
        </div>
      )}
      <div className={styles.textContainer}>
        <h2 className={onlyTitle ? styles.onlyTitle : ''}>{blok.title}</h2>
        {blok.text.length > 0 && <p>{blok.text}</p>}
      </div>
    </div>
  )
}

export default withTheme(TipsAndTricks)
