import { FC, ReactNode } from 'react'
import { CSSProperties } from 'styled-components'

import styles from './ActionDiv.module.scss'

type PropTypes = {
  onClick?: (e?: React.KeyboardEvent | React.MouseEvent) => void
  onMouseDown?: (e?: React.KeyboardEvent | React.MouseEvent) => void
  onMouseUp?: (e?: React.KeyboardEvent | React.MouseEvent) => void
  children?: ReactNode | ReactNode[]
  index?: number
  id?: string
  className?: string
  ariaLabel: string
  style?: CSSProperties
  noEffect?: boolean
}

const ActionDiv: FC<PropTypes> = ({ onClick, onMouseDown, onMouseUp, children, index, id, className, ariaLabel, style, noEffect }) => {
  const handleKeypress = (e: React.KeyboardEvent): void => {
    if (e.code === 'Enter') {
      onClick && onClick(e)
    }
  }

  return (
    <div
      id={id}
      className={`${styles.container} ${className} ${noEffect ? null : styles.hoverEffect}`}
      onMouseDown={(e) => onMouseDown && onMouseDown(e)}
      onMouseUp={(e) => onMouseUp && onMouseUp(e)}
      onClick={(e) => onClick && onClick(e)}
      role="button"
      onKeyDown={handleKeypress}
      tabIndex={index}
      aria-label={ariaLabel}
      style={style}
    >
      {children && children}
    </div>
  )
}

export default ActionDiv
