import React, { AllHTMLAttributes, FC } from 'react'

import { Sprinkles, sprinkles } from '../../../styles/sprinkles.css'
import { parseSprinkleProps } from '../helpers/sprinkleHelper'
import { input, inputSize } from './input.css'

export type InputSize = 's' | 'm' | 'l'

type InputProps = Omit<AllHTMLAttributes<HTMLInputElement>, 'className' | 'content' | 'size'> &
  Sprinkles & {
    className?: string
    size?: InputSize
  }

const Input: FC<InputProps> = ({ className, fontWeight = 'normal', size = 'm', borderRadius = 's', ...props }) => {
  const { sprinkleProps, otherProps } = parseSprinkleProps(props)
  return <input className={`${className} ${inputSize[size]} ${input} ${sprinkles({ ...sprinkleProps, borderRadius, fontWeight })}`} {...otherProps} />
}

export default Input
