import { AUTH_TYPE, AuthOptions, createAuthLink } from 'aws-appsync-auth-link'
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link'

import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, createHttpLink } from '@apollo/client'

import { getJwtToken } from '../lib/firebase'

const url = process.env.NEXT_PUBLIC_APPSYNC_URL
const region = process.env.NEXT_PUBLIC_APPSYNC_REGION
const builderApiToken = process.env.NEXT_PUBLIC_BUILDER_API_KEY

const auth: AuthOptions = {
  type: AUTH_TYPE.OPENID_CONNECT,
  jwtToken: getJwtToken,
}

const link = ApolloLink.from([
  createAuthLink({ url, region, auth }),
  createSubscriptionHandshakeLink({ url, region, auth }, new HttpLink({ uri: url, fetch })),
])

const builderLink = createHttpLink({
  uri: `https://cdn.builder.io/api/v1/graphql/${builderApiToken}`,
})

const builderClient = new ApolloClient({
  link: builderLink,
  cache: new InMemoryCache(),
})

const apiClient = new ApolloClient({
  link,
  cache: new InMemoryCache(),
})
export { builderClient }
export default apiClient
