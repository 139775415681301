import React, { FC } from 'react'
import { BuilderProps, removeKeys } from '../../helpers/BuilderPropCleaner'
import { Box, Container, Heading, Text } from 'atomic'
import { useCmsOrTranslation } from 'hooks/useSafeTranslation'
import { Link } from 'components/link/Link'
import RightArrow from 'components/icons/RightArrow'
import { useMediaQuery } from 'react-responsive'
import { ouTheme } from 'atomic/styles/theme.css'
import { useJobList } from 'context/JobylonListContext'

type JobOptionsProps = {
  title: string
  deadline: string
} & BuilderProps

const Jobs: FC<JobOptionsProps> = ({ title, deadline, ...props }) => {
  // Remove Builder.io specific props
  const cleanProps = removeKeys(props, { builderBlock: true, builderState: true })
  const { cmsOrLokalise } = useCmsOrTranslation()
  const isDesktopMobile = useMediaQuery({ maxWidth: ouTheme.breakpoints.tablet })

  const { jobylonlist } = useJobList()

  return (
    <Container {...cleanProps} display={'flex'} flexDirection={'column'} gap={'xl'}>
      <Heading as={'h3'} fontSize={'xl'} textAlign={'center'} marginBottom={'none'} position={'relative'} style={{ zIndex: 2 }}>
        {cmsOrLokalise(title, 'work_with_us_job_list_title')}
      </Heading>

      <Box display={'flex'} flexDirection={'column'} gap={'m'}>
        {jobylonlist?.map((job) => (
          <Box key={job.id} background="lav4">
            <Link href={job.urls.ad} target="_blank" rel="noreferrer">
              <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'space-between'}
                paddingY={{ mobile: 'm', desktop: 'xl' }}
                paddingX={{ mobile: 'm', desktop: 'xxl' }}
              >
                <Box display={'flex'} flexDirection={'column'} gap={'xs'}>
                  <Heading as={'h3'} fontSize={'base'} fontWeight={'light'} marginBottom={'none'}>
                    {job.function}
                  </Heading>
                  <Box display={'flex'} flexDirection={'column'} gap={'xxxs'}>
                    <Heading as={'h4'} fontSize={'l'} fontWeight={'bold'} marginBottom={'none'}>
                      {job.title}
                    </Heading>
                    <Text fontSize={'s'} fontWeight={'light'} marginBottom={'none'}>
                      {cmsOrLokalise(deadline, 'work_with_us_job_list_deadline_text')} {job.to_date}
                    </Text>
                  </Box>
                </Box>
                <Box>
                  <RightArrow width={!isDesktopMobile ? 48 : 36} height={!isDesktopMobile ? 48 : 36} />
                </Box>
              </Box>
            </Link>
          </Box>
        ))}
      </Box>
    </Container>
  )
}

export default Jobs
