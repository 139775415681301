import React, { CSSProperties } from 'react'
import { useSnapCarousel } from 'react-snap-carousel'
import * as css from './ImageSlider.css'

const styles = {
  itemSnapPoint: {
    scrollSnapAlign: 'start',
  },
  nextPrevButtonDisabled: { opacity: 0.3 },
  nextButton: {
    backgroundImage: "url('/icons/next-bracket.svg')",
  },
  prevButton: {
    backgroundImage: "url('/icons/prev-bracket.svg')",
  },
} satisfies Record<string, CSSProperties>

interface ImageSliderProps<T> {
  readonly items: T[]
  readonly renderItem: (props: ImageSliderRenderItemProps<T>) => React.ReactElement<ImageSliderItemProps>
}

interface ImageSliderRenderItemProps<T> {
  readonly item: T
  readonly isSnapPoint: boolean
}

export function ImageSlider<T>({ items, renderItem }: ImageSliderProps<T>): JSX.Element {
  const { scrollRef, pages, activePageIndex, prev, next, snapPointIndexes } = useSnapCarousel()
  return (
    <div className={css.root}>
      <ul className={css.scroll} ref={scrollRef}>
        {items.map((item, i) =>
          renderItem({
            item,
            isSnapPoint: snapPointIndexes.has(i),
          })
        )}
      </ul>
      <div className={css.controls} aria-hidden>
        <button
          className={css.nextPrevButton}
          style={{
            ...styles.prevButton,
            ...(activePageIndex === 0 ? styles.nextPrevButtonDisabled : {}),
          }}
          onClick={() => prev()}
        ></button>

        <button
          className={css.nextPrevButton}
          style={{
            ...styles.nextButton,
            ...(activePageIndex === pages.length - 1 ? styles.nextPrevButtonDisabled : {}),
          }}
          onClick={() => next()}
        ></button>
      </div>
    </div>
  )
}

interface ImageSliderItemProps {
  readonly isSnapPoint: boolean
  readonly children?: React.ReactNode
}

export const ImageSliderItem = ({ isSnapPoint, children }: ImageSliderItemProps): JSX.Element => (
  <li
    className={css.item}
    style={{
      ...(isSnapPoint ? styles.itemSnapPoint : {}),
      position: 'relative',
    }}
  >
    {children}
  </li>
)
