import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { isAndroid, isIOS } from 'react-device-detect'
import { getCampaignParameters } from 'utils/get-campaign-parameters'

type AppLinks = {
  appStoreUrl: string
  googlePlayUrl: string
  activeOsUrl: string
}

const useAppLinks = (): AppLinks => {
  const router = useRouter()
  const campaignParameters = getCampaignParameters(router.query)
  const onelinkBaseUrl = 'https://onceuponapp.onelink.me'
  const [appStoreUrl, setAppStoreUrl] = useState(onelinkBaseUrl)
  const [googlePlayUrl, setGooglePlayUrl] = useState(onelinkBaseUrl)
  const [activeOsUrl, setActiveOsUrl] = useState(onelinkBaseUrl)

  useEffect(() => {
    if (campaignParameters) {
      const onelinkParameters = Object.entries(campaignParameters)
        .map((entry) => entry.join('='))
        .join('&')
      const appStoreUrlWithCampaignParameters = onelinkBaseUrl + `/faM8?${onelinkParameters}`
      const googlePlayUrlWithCampaignParameters = onelinkBaseUrl + `/hTrF?${onelinkParameters}`
      setAppStoreUrl(appStoreUrlWithCampaignParameters)
      setGooglePlayUrl(googlePlayUrlWithCampaignParameters)
      if (isAndroid) {
        setActiveOsUrl(googlePlayUrlWithCampaignParameters)
      } else {
        setActiveOsUrl(appStoreUrlWithCampaignParameters)
      }
    } else {
      const appStoreUrlWithoutCampaignParameters = onelinkBaseUrl + '/RLy8/bc075980'
      const googlePlayUrlWithoutCampaignParameters = onelinkBaseUrl + '/baCm/dbd68733'
      setAppStoreUrl(appStoreUrlWithoutCampaignParameters)
      setGooglePlayUrl(googlePlayUrlWithoutCampaignParameters)
      if (isAndroid) {
        setActiveOsUrl(googlePlayUrlWithoutCampaignParameters)
      } else {
        setActiveOsUrl(appStoreUrlWithoutCampaignParameters)
      }
    }
  }, [isAndroid, isIOS, router.query])

  return {
    appStoreUrl,
    googlePlayUrl,
    activeOsUrl,
  }
}

export default useAppLinks
