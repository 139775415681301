import { FC } from 'react'
import { StoryblokContent } from 'types/StoryblokContent'

import { storyblokEditable } from '@storyblok/react'

import BulletArrow from '../../icons/BulletArrow'

import styles from './BulletListSection.module.scss'

type PropTypes = {
  blok?: StoryblokContent
}

type BulletListItem = {
  _uid: string
  text: string
}

const BulletListSection: FC<PropTypes> = ({ blok }) => {
  const renderItems = (): JSX.Element => {
    return blok.bullet_list.map((bulletListItem: BulletListItem) => (
      <div key={bulletListItem._uid} className={styles.itemContainer}>
        <BulletArrow />
        <p>{bulletListItem.text}</p>
      </div>
    ))
  }

  return (
    <div {...storyblokEditable(blok)}>
      <h3>{blok.title}</h3>
      {renderItems()}
    </div>
  )
}

export default BulletListSection
