import axios from 'axios'
import { FC, useState } from 'react'
import { StoryblokContent } from 'types/StoryblokContent'

import { storyblokEditable } from '@storyblok/react'

import { ListItem } from '../../dropdown/Dropdown'
import ContentContainer from '../../styled/ContentContainer'
import FormSection, { FormInput } from '../formsection/FormSection'

import styles from './SupportForm.module.scss'

type PropTypes = {
  blok?: StoryblokContent
}

export type Target = {
  firstname: { value: string; name: string }
  surname: { value: string; name: string }
  email: { value: string; name: string }
  [key: string]: { value: string; name: string }
}

export type CategoryDropdownItem = ListItem & {
  input_fields: [{ uuid: string; name: string; content: FormInput }]
  required_input_fields: string[]
  information_text: StoryblokContent
}

const SupportForm: FC<PropTypes> = ({ blok }) => {
  const [selectedCategory, setSelectedCategory] = useState<CategoryDropdownItem | null>()
  const [files, setFiles] = useState<{ [key: string]: File }>({})
  const subject = (): string => {
    return `${selectedCategory.key}`
  }

  const message = (target: Target): string => {
    const dynamicInputfields = selectedCategory.input_fields
      .filter((field) => field.name !== 'images' && field.name !== 'screenshots' && field.name !== 'custom infobox')
      .map((field) => field.content.name)
    const dynamicValues: string[] = dynamicInputfields.map(
      (dynamicField) => `<b>${dynamicField}:</b> <span style="white-space: break-spaces;">${target[dynamicField].value}</span>`
    )
    return `
    ${dynamicValues.join('<br/>')}
    <br/>
    <b>${target.firstname.name}:</b> ${target.firstname.value}<br/>
    <b>${target.surname.name}:</b> ${target.surname.value}<br/>
    <b>${target.email.name}:</b> ${target.email.value}<br/>
    <br/>
    `
  }

  const handleOnSubmit = async (event: React.SyntheticEvent, token: string): Promise<void> => {
    const url = process.env.NEXT_PUBLIC_SUPPORT_FORM_URL
    const target = event.target as typeof event.target & Target

    const formData = new FormData()
    formData.append('email', target.email.value)
    formData.append('captcha', token)
    formData.append('subject', subject())
    formData.append('message', message(target))

    const images = Object.values(files)
    images.forEach((image) => {
      formData.append('files', image)
    })

    const config = {
      headers: { 'content-type': 'multipart/form-data' },
    }

    window.scrollTo(0, 0)
    return await axios.post(url, formData, config)
  }

  return (
    <section className={styles.container} {...storyblokEditable(blok)}>
      <ContentContainer>
        <FormSection
          blok={blok.form[0]}
          handleOnSubmit={handleOnSubmit}
          selectedDropdownItem={selectedCategory}
          setSelectedDropdownItem={setSelectedCategory}
          getFiles={files}
          setFiles={setFiles}
          onSelectedDropdownItem={(item: CategoryDropdownItem) => setSelectedCategory(item)}
        />
      </ContentContainer>
    </section>
  )
}

export default SupportForm
