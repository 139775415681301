import DynamicComponent from 'components/StoryblokComponents'
import { FC, useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import Slider from 'react-slick'
import { withTheme } from 'styled-components'
import { StoryblokContent } from 'types/StoryblokContent'

import { storyblokEditable } from '@storyblok/react'

import { Theme } from '../../../styles/Theme'

import styles from './Carousel.module.scss'

type PropTypes = {
  blok?: StoryblokContent
  theme: Theme
}

const Carousel: FC<PropTypes> = ({ blok, theme }) => {
  const [showCarousel, setShowCarousel] = useState(false)

  const renderItems = (blok: StoryblokContent): JSX.Element => {
    return blok.items?.map((item: StoryblokContent) => (
      <div key={item._uid} className={`${styles.itemContainer}`}>
        <DynamicComponent blok={item} />
      </div>
    ))
  }

  const renderItemsWithCarousel = (blok: StoryblokContent): JSX.Element => {
    return (
      <div className={styles.carouselContainer}>
        {/* https://react-slick.neostack.com/docs/get-started */}
        <Slider {...sliderSettings}>{renderItems(blok)}</Slider>
      </div>
    )
  }

  const renderItemsWithoutCarousel = (blok: StoryblokContent): JSX.Element => {
    return <div className={styles.notCarouselContainer}>{renderItems(blok)}</div>
  }

  const renderContent = (blok: StoryblokContent): JSX.Element => {
    if (showCarousel) return renderItemsWithCarousel(blok)
    else return renderItemsWithoutCarousel(blok)
  }

  const autoplay = blok.autoplay ?? false
  const sliderSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: autoplay,
    speed: 2000,
    autoplaySpeed: 15000,
  }

  if (blok.items?.length === 0) {
    // fix/hack for known issue with infinite: true + fade: true, https://github.com/akiran/react-slick/issues/1343
    return <Slider key="0" />
  }

  const isDesktopOrTablet = useMediaQuery({ minWidth: theme.screenSizes.medium })
  useEffect(() => {
    const carouselOnDesktop = blok.when_to_be_a_carousel === 'always' || blok.when_to_be_a_carousel === 'desktop'
    const carouselOnMobile = blok.when_to_be_a_carousel === 'always' || blok.when_to_be_a_carousel === 'mobile'

    if (isDesktopOrTablet) {
      setShowCarousel(carouselOnDesktop)
    } else {
      setShowCarousel(carouselOnMobile)
    }
  }, [isDesktopOrTablet])

  return (
    <section className={styles.sectionContainer} {...storyblokEditable(blok)}>
      <div className={styles.contentContainer}>{renderContent(blok)}</div>
    </section>
  )
}

export default withTheme(Carousel)
