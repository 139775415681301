/* eslint-disable @next/next/no-img-element */
import React, { FC } from 'react'
import { BuilderProps, removeKeys } from '../../helpers/BuilderPropCleaner'
import { Box, Container, Heading, Text } from 'atomic'
import { useBackend } from 'context/BackendContext'

type PayOption = {
  title: string
  description: string
}

type PaymentsProps = {
  heading: string
  payOptions: PayOption[]
} & BuilderProps

const Payments: FC<PaymentsProps> = ({ heading, payOptions, ...props }) => {
  // Remove Builder.io specific props
  const cleanProps = removeKeys(props, { builderBlock: true, builderState: true })
  const { paymentOptions } = useBackend()

  return (
    <Box paddingX={'m'}>
      <Container
        display={'flex'}
        flexDirection={'column'}
        alignItems={'flex-start'}
        justifyContent={'center'}
        gap={'m'}
        paddingX={'m'}
        paddingY={'l'}
        style={{ maxWidth: 940 }}
        {...cleanProps}
      >
        <Heading as={'h3'} fontSize={'l'} marginBottom={'none'}>
          {heading}
        </Heading>
        <Box display={'flex'} alignItems={'center'} justifyContent={'flex-start'} flexWrap={'wrap'} gap={'s'}>
          {paymentOptions?.map((option) => (
            // eslint-disable-next-line @next/next/no-img-element
            <img src={option.image?.url} alt={option.name} key={option.name} style={{ maxHeight: 32 }} />
          ))}
          <img src="/icons/PoweredByStripe_blurple.svg" alt="Powered by Stripe" style={{ maxHeight: 32 }} />
        </Box>
        <Box display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={'center'} gap={'xxxs'}>
          {payOptions &&
            payOptions.map((option, index) => (
              <Box
                display={'flex'}
                flexDirection={{ mobile: 'column', desktop: 'row' }}
                alignItems={{ mobile: 'flex-start', desktop: 'center' }}
                justifyContent={'flex-start'}
                gap={'xxxs'}
                key={index}
              >
                <Text fontSize={'s'} fontWeight={'semibold'} marginBottom={'none'}>
                  {option.title}
                </Text>
                <Text fontSize={'s'} fontWeight={'light'} marginBottom={'none'}>
                  {option.description}
                </Text>
              </Box>
            ))}
        </Box>
      </Container>
    </Box>
  )
}

export default Payments
