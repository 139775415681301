import { CSSProperties, FC } from 'react'
import React from 'react'

import Box, { BoxProps } from '../../atoms/box/Box'
import Container from '../../atoms/container/Container'
import Text from '../../atoms/text/Text'
import * as css from './ProductCard.css'

type HeaderCardProps = {
  title: string
  imageSource: string
  sizeBoxes: string[]
  actionButtonIcon: string
  clickAction: () => void
} & BoxProps

const ProductCard: FC<HeaderCardProps> = ({ title, imageSource, sizeBoxes, actionButtonIcon, clickAction }) => {
  const styles: Record<string, CSSProperties> = {
    background: {
      backgroundImage: `url('${imageSource}')`,
    },
  }

  return (
    <Box style={styles.background} className={css.headerCard}>
      <ProductTitle title={title} clickAction={clickAction} actionButtonIcon={actionButtonIcon} />
      <ProductSizes sizes={sizeBoxes} />
    </Box>
  )
}

const ProductTitle: FC<{ actionButtonIcon: string; title: string; clickAction: () => void }> = ({ title, clickAction, actionButtonIcon }) => {
  return (
    <Container className={css.headerCardTitleContainer}>
      <Text fontWeight="bold" fontSize="l">
        {title}
      </Text>
      <button className={css.actionButton} style={{ backgroundImage: `url("${actionButtonIcon}")` }} onClick={clickAction} />
    </Container>
  )
}

const ProductSizes: FC<{ sizes: string[] }> = ({ sizes }) => {
  return (
    <Container className={css.productSizes}>
      {sizes.map((size, i) => (
        <div className={css.productSizeBox} key={`size${i}`}>
          <Text as="span" key={size}>
            {size}
          </Text>
        </div>
      ))}
    </Container>
  )
}

export default ProductCard
