import { useModalState } from 'context/ModalStateContext'
import { FC } from 'react'
import { useMediaQuery } from 'react-responsive'
import { theme } from 'styles/Theme'
import { StoryblokContent } from 'types/StoryblokContent'

import { Button } from '@onceuponapp/ui'
import { storyblokEditable } from '@storyblok/react'

import styles from './CreateBookButton.module.scss'

type PropTypes = {
  blok?: StoryblokContent
}

const CreateBookButton: FC<PropTypes> = ({ blok }) => {
  const { setShowGetAppModal } = useModalState()
  const isMobile = useMediaQuery({ maxWidth: theme.screenSizes.small })
  const url = blok.create_book_button_url ?? 'https://app.onceupon.photo/'

  const createBook = (): void => {
    window.open(url)
  }

  return (
    <section className={styles.container} {...storyblokEditable(blok)}>
      {isMobile ? (
        <Button theme="cta" onClick={() => setShowGetAppModal(true)}>
          {blok.create_book_button_text_mobile}
        </Button>
      ) : (
        <Button onClick={createBook}>{blok.create_book_button_text}</Button>
      )}
    </section>
  )
}

export default CreateBookButton
