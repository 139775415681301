/* eslint-disable @typescript-eslint/no-empty-function */

import { createContext, FC, ReactNode, useContext } from 'react'

import ChristmasDeliveryDate from '../types/ChristmasDeliveryDates'
import DeliveryInfo from '../types/DeliveryInfo'
import PaymentOption from '../types/PaymentOption'
import { Product } from 'utils/get-store-data'

type BackendContextType = {
  deliveryInfo: DeliveryInfo
  paymentOptions: PaymentOption[]
  christmasDeliveryDates: ChristmasDeliveryDate
  storeData: Product[]
}

const BackendContextDefaultValues: BackendContextType = {
  deliveryInfo: null,
  paymentOptions: null,
  christmasDeliveryDates: null,
  storeData: null,
}

const BackendContext = createContext<BackendContextType>(BackendContextDefaultValues)

export function useBackend(): BackendContextType {
  return useContext(BackendContext)
}

type PropTypes = {
  children: ReactNode
  deliveryInfo: DeliveryInfo
  paymentOptions: PaymentOption[]
  christmasDeliveryDates: ChristmasDeliveryDate
  storeData: Product[]
}

export const BackendProvider: FC<PropTypes> = ({ children, deliveryInfo, paymentOptions, christmasDeliveryDates, storeData }) => {
  const value = {
    deliveryInfo,
    paymentOptions,
    christmasDeliveryDates,
    storeData,
  }

  return (
    <>
      <BackendContext.Provider value={value}>{children}</BackendContext.Provider>
    </>
  )
}
