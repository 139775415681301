import { FC } from 'react'
import { StoryblokContent } from 'types/StoryblokContent'

import { storyblokEditable } from '@storyblok/react'

import StoryblokImage from '../../storyblokimage/StoryblokImage'

import styles from './TextSectionImage.module.scss'

type PropTypes = {
  blok?: StoryblokContent
}

const TextSectionImage: FC<PropTypes> = ({ blok }) => {
  return (
    <div key={blok._uid} className={styles.subimage} {...storyblokEditable(blok)}>
      <div className={styles.imageContainer}>
        <StoryblokImage src={blok.image.filename} width={1271} height={659} alt={blok.image.name} />
      </div>
      {blok.title && <h2>{blok.title}</h2>}
    </div>
  )
}

export default TextSectionImage
