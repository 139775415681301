import Head from 'next/head'
import { useRouter } from 'next/router'
import { FC, useEffect, useState } from 'react'

import ContentContainer from '../../styled/ContentContainer'

import styles from './CollabInvite.module.scss'

const CollabInvite: FC = () => {
  const router = useRouter()
  const [inviteId2, setInviteId] = useState(null)
  const [referrer, setReferrer] = useState('')

  useEffect(() => {
    const { inviteId } = router.query
    setInviteId(inviteId)
    setReferrer(document.referrer)
  })

  return (
    <>
      <Head>
        <meta name="robots" content="noindex" />
        <meta name="googlebot" content="noindex" />
      </Head>
      <section className={styles.container}>
        <ContentContainer className={styles.contentContainer}>
          <div className={styles.contentContainer}>
            <div>InviteId: _{inviteId2}_</div>
            <div>Referrer: _{referrer}_</div>
          </div>
        </ContentContainer>
      </section>
    </>
  )
}

export default CollabInvite
