import React, { FC } from 'react'
import { ThemeColors, ouTheme } from 'atomic/styles/theme.css'
import { Box, Container } from 'atomic'
import FactsItem, { FactItem } from './FactItem'
import * as css from './Facts.css'
import { BuilderProps, removeKeys } from 'components/builder_components/helpers/BuilderPropCleaner'

type FactsProps = {
  backgroundColor: keyof ThemeColors
  funFact1: FactItem
  funFact2: FactItem
  funFact3: FactItem
} & BuilderProps

const Facts: FC<FactsProps> = ({ backgroundColor, funFact1, funFact2, funFact3, ...props }) => {
  // Remove Builder.io specific props
  const cleanProps = removeKeys(props, { builderBlock: true, builderState: true })
  return (
    <Box display={'flex'} justifyContent={'center'} style={{ width: '100%' }} {...cleanProps}>
      <Box paddingX={'s-l'} style={{ width: '100%', maxWidth: ouTheme.containerWidth }}>
        <Container
          display={{ mobile: 'flex' }}
          flexDirection={{ mobile: 'column' }}
          backgroundColor={backgroundColor}
          gap={'xl'}
          paddingY={'xxxl'}
          paddingX={{ mobile: 's-l', desktop: 'xxxl' }}
          className={css.container}
        >
          <FactsItem fact={funFact1} />
          <FactsItem fact={funFact2} />
          <FactsItem fact={funFact3} />
        </Container>
      </Box>
    </Box>
  )
}

export default Facts
