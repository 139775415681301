import clsx from 'clsx'
import { useInView } from 'framer-motion'
import React, { FC, ReactNode, RefObject, useEffect, useRef, useState } from 'react'

import Box, { BoxProps } from '../../atoms/box/Box'
import * as styles from './Carousel.css'

type CarouselProps = BoxProps & {
  items: ReactNode[]
  className?: string
  children?: React.ReactNode
  paginatorPosition?: 'floating' | 'bottom'
  paginatorColor?: 'light' | 'dark'
}

const useHorizontalDragToScroll = (): RefObject<HTMLDivElement> => {
  const containerRef = useRef<HTMLDivElement>(null)
  const startXRef = useRef(0)
  const scrollLeftRef = useRef(0)

  useEffect(() => {
    const container = containerRef.current
    if (!container) return

    const handleMouseDown = (event: MouseEvent): void => {
      event.preventDefault()
      startXRef.current = event.pageX - container.offsetLeft
      scrollLeftRef.current = container.scrollLeft
      document.addEventListener('mousemove', handleMouseMove)
      document.addEventListener('mouseup', handleMouseUp)
    }

    const handleMouseUp = (): void => {
      document.removeEventListener('mouseup', handleMouseUp)
      document.removeEventListener('mousemove', handleMouseMove)
    }

    const handleMouseMove = (event: MouseEvent): void => {
      event.preventDefault()
      const x = event.pageX - container.offsetLeft
      const walk = (x - startXRef.current) * 3

      container.scrollLeft = scrollLeftRef.current - walk
    }

    container.addEventListener('mousedown', handleMouseDown)

    return () => {
      container.removeEventListener('mousedown', handleMouseDown)
      document.removeEventListener('mousemove', handleMouseMove)
      document.removeEventListener('mouseup', handleMouseUp)
    }
  }, [])

  return containerRef
}

const Carousel: FC<CarouselProps> = ({ items = [], className, paginatorPosition = 'floating', children, paginatorColor = 'dark', ...props }) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const containerRef = useHorizontalDragToScroll()
  const allItems = [...items, ...React.Children.toArray(children)]
  const elements = useRef<HTMLDivElement[]>([])
  if (allItems.length === 0) return null

  return (
    <Box className={clsx(styles.container, className)} {...props}>
      <div ref={containerRef} className={styles.carousel}>
        {allItems.map((item, index) => {
          return (
            <CarouselItem onActive={() => setActiveIndex(index)} key={index} onRefAssigned={(el) => (elements.current[index] = el)}>
              {item}
            </CarouselItem>
          )
        })}
      </div>
      {allItems?.length > 1 ? (
        <Box className={clsx(styles.paginator, styles.paginatorLayout[paginatorPosition])} display="flex" gap="xs" padding="m">
          {allItems.map((_, index) => {
            return (
              <button
                onClick={() => {
                  elements.current[index]?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' })
                }}
                key={`nav-${index}`}
                className={clsx(styles.paginatorItem[paginatorColor], activeIndex === index && styles.paginatorItemActive[paginatorColor])}
              />
            )
          })}
        </Box>
      ) : null}
    </Box>
  )
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CarouselItem: FC<{ onActive: () => void; children?: React.ReactNode; onRefAssigned: (element: HTMLDivElement) => void }> = ({
  children,
  onActive,
  onRefAssigned,
}) => {
  const ref = useRef(null)
  const isInView = useInView(ref, { amount: 0.3 })

  useEffect(() => {
    if (isInView) {
      onActive()
    }
  }, [isInView])

  return (
    <div ref={(el) => ((ref.current = el), onRefAssigned(el))} className={styles.item}>
      {children}
    </div>
  )
}

export default Carousel
