import styled from 'styled-components'

const ContentContainer = styled.div`
  box-sizing: content-box;
  display: grid;
  max-width: 1270px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
`
export default ContentContainer
