import { FC } from 'react'
import { StoryblokContent } from 'types/StoryblokContent'

import { storyblokEditable } from '@storyblok/react'

import ContentContainer from '../../styled/ContentContainer'
import Checklist from '../checklist/Checklist'

import styles from './ChecklistSection.module.scss'

type PropTypes = {
  blok?: StoryblokContent
}

const ChecklistSection: FC<PropTypes> = ({ blok }) => {
  return (
    <>
      <section className={styles.sectionContainer} {...storyblokEditable(blok)}>
        <ContentContainer className={styles.contentContainer}>
          <div className={styles.checklistContainer}>
            <Checklist blok={blok.checklist[0]} />
          </div>
        </ContentContainer>
      </section>
    </>
  )
}

export default ChecklistSection
