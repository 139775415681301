import DynamicComponent from 'components/StoryblokComponents'
import { FC } from 'react'
import { StoryblokContent } from 'types/StoryblokContent'

import { storyblokEditable } from '@storyblok/react'

import ContentContainer from '../../styled/ContentContainer'

import styles from './Column.module.scss'

type PropTypes = {
  blok?: StoryblokContent
}

const Column: FC<PropTypes> = ({ blok }) => {
  return (
    <section className={styles.container} style={{ backgroundColor: blok.background_color.color }} {...storyblokEditable(blok)}>
      <ContentContainer>
        <div className={styles.items}>
          {blok.items.map((item: StoryblokContent) => (
            <DynamicComponent key={item._uid} blok={item} />
          ))}
        </div>
      </ContentContainer>
    </section>
  )
}

export default Column
