import { ParsedUrlQuery } from 'node:querystring'

export const getCampaignParameters = (routerQuery: ParsedUrlQuery): Record<string, string> | null => {
  const { c, af_c_id, af_channel, af_adset, af_adset_id, af_ad, af_ad_id, clickid, pid, af_sub1, af_sub2, af_sub3, af_sub4, af_sub5 } = routerQuery

  if (c) {
    return {
      c: c as string,
      af_c_id: (af_c_id as string) ?? '',
      af_channel: (af_channel as string) ?? '',
      af_adset: (af_adset as string) ?? '',
      af_adset_id: (af_adset_id as string) ?? '',
      af_ad: (af_ad as string) ?? '',
      af_ad_id: (af_ad_id as string) ?? '',
      clickid: (clickid as string) ?? '',
      pid: (pid as string) ?? '',
      af_sub1: (af_sub1 as string) ?? '',
      af_sub2: (af_sub2 as string) ?? '',
      af_sub3: (af_sub3 as string) ?? '',
      af_sub4: (af_sub4 as string) ?? '',
      af_sub5: (af_sub5 as string) ?? '',
    }
  } else {
    return null
  }
}

export const getUrlWithCampaignParameters = (url: string, routerQuery: ParsedUrlQuery): string => {
  const campaignParameters = getCampaignParameters(routerQuery)
  const campaignParametersAsQueryString = new URLSearchParams(campaignParameters).toString()
  const hasParametersAlreadyBeenAppended = url?.indexOf(campaignParametersAsQueryString) > -1

  if (campaignParameters && !hasParametersAlreadyBeenAppended) {
    return `${url}?${campaignParametersAsQueryString}`
  } else {
    return url
  }
}
