import { FC } from 'react'
import { StoryblokContent } from 'types/StoryblokContent'

import { storyblokEditable } from '@storyblok/react'

import styles from './TextSectionItem.module.scss'

type PropTypes = {
  blok?: StoryblokContent
}

const TextSectionItem: FC<PropTypes> = ({ blok }) => {
  return (
    <div key={blok._uid} className={styles.subSection} {...storyblokEditable(blok)}>
      <h3>{blok.subtitle}</h3>
      <p>{blok.text}</p>
    </div>
  )
}

export default TextSectionItem
