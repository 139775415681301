import { FC } from 'react'
import { StoryblokContent } from 'types/StoryblokContent'

import { storyblokEditable } from '@storyblok/react'

import StoryblokImage from '../../storyblokimage/StoryblokImage'

import styles from './IllustrationContainer.module.scss'

type PropTypes = {
  blok?: StoryblokContent
  className?: string
}

const IllustrationContainer: FC<PropTypes> = ({ blok, className }) => {
  if (!blok) return null

  const classNames = (): string => {
    let containerClassNames = styles.container
    if (className) {
      containerClassNames = containerClassNames.concat(' ', className)
    }
    if (blok.single_item) {
      containerClassNames = containerClassNames.concat(' ', styles.singleItem)
    }
    return containerClassNames
  }

  return (
    <div className={classNames()} {...storyblokEditable(blok)}>
      {blok.illustration?.filename && (
        <div>
          <StoryblokImage
            src={blok.illustration.filename}
            width={blok.illustration_width || 200}
            height={blok.illustration_height || 200}
            alt={blok.illustration.name}
          />
        </div>
      )}
      <h2>{blok.title}</h2>
      <p className={styles.text}>{blok.text}</p>
    </div>
  )
}

export default IllustrationContainer
