import { useRouter } from 'next/router'
import { FC } from 'react'
import { StoryblokContent } from 'types/StoryblokContent'

import { useBackend } from '../../../context/BackendContext'
import ContentContainer from '../../styled/ContentContainer'

import styles from './ChristmasDeliveryDatesList.module.scss'

type PropTypes = {
  blok?: StoryblokContent
}

const ChristmasDeliveryDatesList: FC<PropTypes> = () => {
  const { christmasDeliveryDates } = useBackend()
  const router = useRouter()

  const getDate = (dateString: string): string => {
    const date = new Date(dateString)
    const localeString = date.toLocaleString(router.locale, {
      month: 'long',
      day: 'numeric',
      timeZone: 'Europe/Stockholm',
    })

    return localeString
  }

  return (
    <section className={styles.container}>
      <ContentContainer className={styles.contentContainer}>
        {christmasDeliveryDates?.countries.map((country) => (
          <div className={styles.countryContainer} key={country.country_code}>
            <p>{country.country}</p>
            <p>{getDate(country.last_order_date)}</p>
          </div>
        ))}
      </ContentContainer>
    </section>
  )
}

export default ChristmasDeliveryDatesList
