import React, { FC } from 'react'
import { StoryblokContent } from 'types/StoryblokContent'
import { storyblokEditable } from '@storyblok/react'
import isVideo from '../../../utils/is-video'
import styles from './Video.module.scss'

type PropTypes = {
  blok?: StoryblokContent
}

const Video: FC<PropTypes> = ({ blok }) => {
  const shouldAutoplay = blok.autoplay ?? true
  const filename = blok.video.filename
  const alignCenter = blok.alignCenter
  const fullWidth = blok.fullWidth

  if (isVideo(filename)) {
    return (
      <div className={styles.container} {...storyblokEditable(blok)}>
        <div className={`${styles.videoContainer} ${alignCenter ? styles.alignCenter : null} ${fullWidth ? styles.fullWidth : null}`}>
          {shouldAutoplay && (
            <video autoPlay loop muted playsInline={true}>
              <source src={filename} />
            </video>
          )}
          {!shouldAutoplay && (
            <video controls={true} muted playsInline={true}>
              <source src={filename} />
            </video>
          )}
        </div>
      </div>
    )
  }
}

export default Video
