/* eslint-disable @next/next/no-img-element */
import { ImageSlider, ImageSliderItem } from 'components/imageslider/ImageSlider'
import { FC } from 'react'
import { container } from './ImageSliderComponent.css'
import Image from 'next/image'
import { Container } from 'atomic'
import { BoxProps } from 'atomic/components/atoms/box/Box'
import { BuilderProps, removeKeys } from 'components/builder_components/helpers/BuilderPropCleaner'
import { ouTheme } from 'atomic/styles/theme.css'

export type Slide = { id: number; src: string }
export type Slides = Omit<Slide, 'id'>[]
type ImageSliderProps = { slides: Slides } & BoxProps & BuilderProps

const ImageSliderComponent: FC<ImageSliderProps> = ({ ...props }) => {
  const slides: Slide[] = props?.slides?.map((s, index) => ({ id: index, src: s.src }))

  const cleanProps = removeKeys(props, { builderBlock: true, builderState: true })

  return (
    <Container className={container} {...cleanProps}>
      {slides && slides.length > 0 ? (
        <ImageSlider
          items={slides}
          renderItem={({ item, isSnapPoint }) => (
            <ImageSliderItem key={item.id ?? item.src} isSnapPoint={isSnapPoint}>
              <Image
                src={item.src}
                style={{ objectFit: 'cover' }}
                alt="Placeholder"
                fill
                sizes={`(max-width: ${ouTheme.breakpoints.desktop}) 100vw, 50vw`}
                priority
              />
            </ImageSliderItem>
          )}
        />
      ) : null}
    </Container>
  )
}

export default ImageSliderComponent
