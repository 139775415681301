import { Box, Text } from 'atomic'
import { ThemeColors } from 'atomic/styles/theme.css'
import { FC } from 'react'
import * as css from './Facts.css'
import { IllustrationType, KluddIllustrationType } from 'atomic/components/atoms/illustrations'
import KluddIllustration from 'atomic/components/atoms/illustrations/KluddIllustration'
import Illustration from 'atomic/components/atoms/illustrations/Illustration'

export type FactItem = {
  illustration: IllustrationType
  kluddType?: KluddIllustrationType
  kluddColor: keyof ThemeColors
  kluddWidth?: number
  text: string
}

type FactsItemProps = {
  fact: FactItem
}

const FactsItem: FC<FactsItemProps> = ({ fact }) => {
  return (
    <Box display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'flex-start'} gap={'l'}>
      <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
        <KluddIllustration
          name={fact?.kluddType ?? 'kludd-4'}
          color={fact?.kluddColor ?? 'blåbär2'}
          width={fact?.kluddWidth ?? 145}
          className={css.illustrationBackdrop}
        />
        <Illustration name={fact?.illustration ?? 'chat'} className={css.illustration} />
      </Box>
      <Box display={'flex'} alignItems={'flex-start'} justifyContent={'center'}>
        <Text fontSize={{ mobile: 'xl', desktop: 'l' }} fontWeight={'extrabold'} textAlign={'center'} marginBottom={'none'}>
          {fact?.text}
        </Text>
      </Box>
    </Box>
  )
}

export default FactsItem
