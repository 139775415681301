import React, { FC } from 'react'

import Box from '../box/Box'
import { IllustrationBaseProps } from './Base'

export const Instagram: FC<IllustrationBaseProps> = ({ width = '100%', height = '100%', ...props }) => {
  return (
    <Box {...props}>
      <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
          <g fill="currentColor">
            <path d="M12 0c6.623 0 12 5.377 12 12s-5.377 12-12 12S0 18.623 0 12 5.377 0 12 0zm0 4.5c-2.037 0-2.292.009-3.092.045-.799.037-1.344.163-1.82.349a3.677 3.677 0 00-1.33.865 3.677 3.677 0 00-.864 1.328c-.186.477-.312 1.022-.349 1.82C4.51 9.708 4.5 9.964 4.5 12s.009 2.292.045 3.092c.037.799.163 1.344.349 1.82.191.494.448.912.865 1.33.417.416.835.673 1.328.864.477.186 1.022.312 1.82.349.8.036 1.056.045 3.093.045s2.292-.009 3.092-.045c.799-.037 1.344-.163 1.82-.349a3.677 3.677 0 001.33-.865c.416-.417.673-.835.864-1.328.186-.477.312-1.022.349-1.82.036-.8.045-1.056.045-3.093s-.009-2.292-.045-3.092c-.037-.799-.163-1.344-.349-1.82a3.677 3.677 0 00-.865-1.33 3.677 3.677 0 00-1.328-.864c-.477-.186-1.022-.312-1.82-.349-.8-.036-1.056-.045-3.093-.045zm0 1.351c2.003 0 2.24.008 3.03.044.732.033 1.129.156 1.393.258.35.136.6.299.863.561.262.263.425.513.56.863.103.264.226.661.259 1.392.036.791.044 1.028.044 3.031s-.008 2.24-.044 3.03c-.033.732-.156 1.129-.258 1.393-.136.35-.299.6-.561.863a2.322 2.322 0 01-.863.56c-.264.103-.661.226-1.392.259-.791.036-1.028.044-3.031.044s-2.24-.008-3.03-.044c-.732-.033-1.129-.156-1.393-.258-.35-.136-.6-.299-.863-.561a2.323 2.323 0 01-.56-.863c-.103-.264-.226-.661-.259-1.392-.036-.791-.044-1.028-.044-3.031s.008-2.24.044-3.03c.033-.732.156-1.129.258-1.393.136-.35.299-.6.561-.863.263-.262.513-.425.863-.56.264-.103.661-.226 1.392-.259.791-.036 1.028-.044 3.031-.044zm0 2.298a3.851 3.851 0 100 7.702 3.851 3.851 0 000-7.702zm0 6.351a2.5 2.5 0 110-5 2.5 2.5 0 010 5zm4.904-6.503a.9.9 0 11-1.8 0 .9.9 0 011.8 0z"></path>
          </g>
        </g>
      </svg>
    </Box>
  )
}
