import Head from 'next/head'
import { FC } from 'react'
import { StoryblokContent } from 'types/StoryblokContent'

import { storyblokEditable } from '@storyblok/react'

import StoryblokImage from '../../storyblokimage/StoryblokImage'
import ContentContainer from '../../styled/ContentContainer'

import styles from './NotFound.module.scss'

type PropTypes = {
  blok?: StoryblokContent
}

const NotFound: FC<PropTypes> = ({ blok }) => {
  return (
    <>
      <Head>
        <meta name="robots" content="noindex" />
        <meta name="googlebot" content="noindex" />
      </Head>
      <section className={styles.container} {...storyblokEditable(blok)}>
        <ContentContainer className={styles.contentContainer}>
          <div className={styles.contentContainer} key={blok._uid}>
            <StoryblokImage alt="not found" src={blok.image?.filename} width={400} height={400} />
            <h1>{blok.title}</h1>
            <p>{blok.text}</p>
          </div>
        </ContentContainer>
      </section>
    </>
  )
}

export default NotFound
