import { FC } from 'react'

import { SbBlokData, StoryblokComponent } from '@storyblok/react'

import Article from './storybloks/article/Article'
import ArticleList from './storybloks/articlelist/ArticleList'
import BlackFridaySpecial from './storybloks/blackfridayspecial/BlackFridaySpecial'
import BlogPost from './storybloks/blogpost/BlogPost'
import BlogPostList from './storybloks/blogpostlist/BlogPostList'
import BulletListSection from './storybloks/bulletlistsection/BulletListSection'
import CardSliderSection from './storybloks/cardslidersection/CardSliderSection'
import Carousel from './storybloks/carousel/Carousel'
import ChecklistSection from './storybloks/checklistsection/ChecklistSection'
import ChristmasDeliveryDatesList from './storybloks/christmasdeliverydateslist/ChristmasDeliveryDatesList'
import CollabInvite from './storybloks/collabinvite/CollabInvite'
import CollaborateWithUs from './storybloks/collaboratewithus/CollaborateWithUs'
import CollapsibleSection from './storybloks/collapsiblesection/CollapsibleSection'
import Column from './storybloks/column/Column'
import CreateBookButton from './storybloks/createbookbutton/CreateBookButton'
import DeliveryInfo from './storybloks/deliveryinfo/DeliveryInfo'
import DeliveryOptions from './storybloks/deliveryoptions/DeliveryOptions'
import FeedbackForm from './storybloks/feedbackform/FeedbackForm'
import FormSection from './storybloks/formsection/FormSection'
import GetAppButton from './storybloks/getappbutton/GetAppButton'
import GetAppModal from './storybloks/getappmodal/GetAppModal'
import GetAppSection from './storybloks/getappsection/GetAppSection'
import GetNewsletterModal from './storybloks/getnewslettermodal/GetNewsletterModal'
import GetNewsletterSection from './storybloks/getnewslettersection/GetNewsletterSection'
import GetStoriesModal from './storybloks/getstoriesmodal/GetStoriesModal'
import { Hero } from './storybloks/hero/Hero'
import IllustrationContainer from './storybloks/illustrationcontainer/IllustrationContainer'
import IllustrationWrapper from './storybloks/illustrationwrapper/IllustrationWrapper'
import ImageSection from './storybloks/imagesection/ImageSection'
import ImageSizeable from './storybloks/imagesizeable/ImageSizeable'
import ImageWithDescription from './storybloks/imagewithdescription/ImageWithDescription'
import ImageWithTextAndCta from './storybloks/imagewithtextandcta/ImageWithTextAndCta'
import IndexedRichTextSection from './storybloks/indexedrichtextsection/IndexedRichTextSection'
import IndexedTextSection from './storybloks/indexedtextsection/IndexedTextSection'
import InspirationSection from './storybloks/inspirationsection/InspirationSection'
import JobList from './storybloks/joblist/JobList'
import Kundo from './storybloks/kundo/Kundo'
import LinkButton from './storybloks/linkbutton/LinkButton'
import NotFound from './storybloks/notfound/NotFound'
import Offers from './storybloks/offers/Offers'
import PhotobookDetails from './storybloks/photobook_details/PhotobookDetails'
import PostList from './storybloks/postlist/PostList'
import ProductSection from './storybloks/productsection/ProductSection'
import RichtextContainer from './storybloks/richtextcontainer/RichtextContainer'
import Row from './storybloks/row/Row'
import Seamless from './storybloks/seamless/Seamless'
import Splash from './storybloks/splash/Splash'
import SupportForm from './storybloks/supportform/SupportForm'
import TextSection from './storybloks/textsection/TextSection'
import TextSectionImage from './storybloks/textsectionimage/TextSectionImage'
import TextSectionItem from './storybloks/textsectionitem/TextSectionItem'
import TextSectionWithStyle from './storybloks/textsectionwithstyle/TextSectionWithStyle'
import TextWithLinkSection from './storybloks/textwithlinksection/TextWithLinkSection'
import TipsAndTricks from './storybloks/tipsandtricks/TipsAndTricks'
import Video from './storybloks/video/Video'
import Youtube from './storybloks/youtube/Youtube'
// import PhotoBooks from './storybloks/photobooks/PhotoBooks'
// import PhotosPrints from './storybloks/photoprints/PhotoPrints'
import CustomerSatisfactionSection from './storybloks/customersatisfactionsection/CustomerSatisfactionSection'
import CustomerSatisfactionContainer from './storybloks/customersatisfactioncontainer/CustomerSatisfactionContainer'
// import Yearbook from './storybloks/yearbook/Yearbook'

interface IComponents {
  [key: string]: React.ElementType
}

export const storyblokComponents: IComponents = {
  row: Row,
  richtext_container: RichtextContainer,
  collapsible_section: CollapsibleSection,
  job_list: JobList,
  hero: Hero,
  offers: Offers,
  card_slider_section: CardSliderSection,
  get_app_section: GetAppSection,
  product_section: ProductSection,
  illustration_container: IllustrationContainer,
  inspiration_section: InspirationSection,
  text_with_link_section: TextWithLinkSection,
  get_newsletter_section: GetNewsletterSection,
  get_app_modal: GetAppModal,
  get_newsletter_modal: GetNewsletterModal,
  get_stories_modal: GetStoriesModal,
  support_form: SupportForm,
  text_section: TextSection,
  text_section_with_style: TextSectionWithStyle,
  illustration_wrapper: IllustrationWrapper,
  image_section: ImageSection,
  photobook_details: PhotobookDetails,
  // photobooks: PhotoBooks,
  // photoprints: PhotosPrints,
  checklist_section: ChecklistSection,
  get_app_button: GetAppButton,
  not_found: NotFound,
  text_section_item: TextSectionItem,
  text_section_image: TextSectionImage,
  form_section: FormSection,
  image_sizeable: ImageSizeable,
  indexed_text_section: IndexedTextSection,
  delivery_options: DeliveryOptions,
  blogpost: BlogPost,
  blogpost_list: BlogPostList,
  post_list: PostList,
  feedback_form: FeedbackForm,
  article: Article,
  article_list: ArticleList,
  christmas_delivery_dates: ChristmasDeliveryDatesList,
  youtube: Youtube,
  black_friday_special: BlackFridaySpecial,
  link_button: LinkButton,
  collab_invite: CollabInvite,
  column: Column,
  bullet_list_section: BulletListSection,
  splash: Splash,
  carousel: Carousel,
  image_with_description: ImageWithDescription,
  image_with_text_and_cta: ImageWithTextAndCta,
  tips_and_tricks: TipsAndTricks,
  indexed_rich_text_section: IndexedRichTextSection,
  create_book_button: CreateBookButton,
  seamless: Seamless,
  delivery_info: DeliveryInfo,
  collaborate_with_us: CollaborateWithUs,
  kundo: Kundo,
  video: Video,
  customer_satisfaction_section: CustomerSatisfactionSection,
  customer_satisfaction_container: CustomerSatisfactionContainer,
  // yearbook: Yearbook,
}

type DynamicComponentProps = {
  blok: SbBlokData
  [key: string]: unknown
}

const DynamicComponent: FC<DynamicComponentProps> = ({ blok, ...restProps }) => {
  if (blok.hide) return null

  return <StoryblokComponent blok={blok} {...restProps} />
}

export default DynamicComponent
