import { Box, Container, Text } from 'atomic'
import React, { FC } from 'react'
import { BuilderProps, removeKeys } from '../helpers/BuilderPropCleaner'
import useCountdown, { countdownFormat } from 'hooks/useCountdown'
import { BoxProps } from 'atomic/components/atoms/box/Box'
import { useSafeTranslation } from 'hooks/useSafeTranslation'
import * as css from './Countdown.css'

type CountdownProps = {
  fullbleed: boolean
  targetDate: Date
  targetText?: string
  eventEndDate?: Date
  eventEndText?: string
  invertedTextColor: boolean
  contentAlignment?: 'left' | 'center' | 'right'
  useTextGradient: boolean
} & BuilderProps &
  BoxProps

const Countdown: FC<CountdownProps> = ({
  fullbleed = true,
  targetDate,
  targetText,
  eventEndDate,
  eventEndText,
  invertedTextColor = false,
  contentAlignment = 'center',
  useTextGradient = false,
  ...props
}) => {
  // Remove Builder.io specific props
  const cleanProps = removeKeys(props, { builderBlock: true, builderState: true })
  const { t } = useSafeTranslation()
  const [startDays, startHours, startMinutes, startSeconds] = useCountdown(targetDate)
  const [endDays, endHours, endMinutes, endSeconds] = useCountdown(eventEndDate)

  const countdownTargetDate =
    startDays <= 0
      ? `${countdownFormat(startHours)}:${countdownFormat(startMinutes)}:${countdownFormat(startSeconds)}`
      : startDays === 1
      ? `${startDays} ${t('Countdown_Website_Day')}`
      : `${startDays} ${t('Countdown_Website_Days')}`

  const countdownEndDate =
    endDays <= 0
      ? `${countdownFormat(endHours)}:${countdownFormat(endMinutes)}:${countdownFormat(endSeconds)}`
      : endDays === 1
      ? `${endDays} ${t('Countdown_Website_Day')}`
      : `${endDays} ${t('Countdown_Website_Days')}`

  // detect if the countdown is over
  const isCountdownOver = startDays <= 0 && startHours <= 0 && startMinutes <= 0 && startSeconds <= 0

  const countdownOutput = isCountdownOver && eventEndDate ? countdownEndDate : countdownTargetDate

  const containerAlignment = contentAlignment === 'center' ? 'center' : contentAlignment === 'left' ? 'flex-start' : 'flex-end'

  return (
    <>
      {fullbleed ? (
        <Box paddingY={'xxl'} {...cleanProps}>
          <Container display={'flex'} flexDirection={'column'} alignItems={containerAlignment} justifyContent={'center'}>
            <Text fontFamily={props.fontFamily} fontSize={props.fontSize} color={invertedTextColor ? 'white' : 'malm0'} marginBottom={'none'}>
              {countdownOutput}
            </Text>
            {targetText && !isCountdownOver && (
              <Text
                fontFamily={'accent'}
                fontSize={'xxl'}
                color={invertedTextColor ? 'white' : 'malm0'}
                marginBottom={'none'}
                textAlign={contentAlignment}
                className={useTextGradient ? css.textEffect[invertedTextColor ? 'inverted' : 'default'] : undefined}
              >
                {targetText}
              </Text>
            )}
            {eventEndText && isCountdownOver && eventEndDate && (
              <Text
                fontFamily={'accent'}
                fontSize={'xxl'}
                color={invertedTextColor ? 'white' : 'malm0'}
                marginBottom={'none'}
                textAlign={contentAlignment}
                className={useTextGradient ? css.textEffect[invertedTextColor ? 'inverted' : 'default'] : undefined}
              >
                {eventEndText}
              </Text>
            )}
          </Container>
        </Box>
      ) : (
        <Container
          display={'flex'}
          flexDirection={'column'}
          alignItems={containerAlignment}
          paddingY={'xxl'}
          style={{ width: '100%' }}
          {...cleanProps}
        >
          <Text fontFamily={props.fontFamily} fontSize={props.fontSize} color={invertedTextColor ? 'white' : 'malm0'} marginBottom={'none'}>
            {countdownOutput}
          </Text>
          {targetText && !isCountdownOver && (
            <Text
              fontFamily={'accent'}
              fontSize={'xxl'}
              color={invertedTextColor ? 'white' : 'malm0'}
              marginBottom={'none'}
              className={useTextGradient ? css.textEffect[invertedTextColor ? 'inverted' : 'default'] : undefined}
            >
              {targetText}
            </Text>
          )}
          {eventEndText && isCountdownOver && eventEndDate && (
            <Text
              fontFamily={'accent'}
              fontSize={'xxl'}
              color={invertedTextColor ? 'white' : 'malm0'}
              marginBottom={'none'}
              className={useTextGradient ? css.textEffect[invertedTextColor ? 'inverted' : 'default'] : undefined}
            >
              {eventEndText}
            </Text>
          )}
        </Container>
      )}
    </>
  )
}

export default Countdown
