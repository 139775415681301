import DynamicComponent from 'components/StoryblokComponents'
import { FC, useEffect, useState } from 'react'
import { StoryblokContent } from 'types/StoryblokContent'

import { storyblokEditable } from '@storyblok/react'

import StoryblokImage from '../../storyblokimage/StoryblokImage'
import { useMediaQuery } from 'react-responsive'
import { withTheme } from 'styled-components'
import { Theme } from '../../../styles/Theme'
import styles from './IllustrationWrapper.module.scss'
import { ouTheme } from 'atomic/styles/theme.css'

type PropTypes = {
  blok?: StoryblokContent
  theme: Theme
}

const IllustrationWrapper: FC<PropTypes> = ({ blok, theme }) => {
  const [isMobile, setIsMobile] = useState(false)
  const isSmallerThan = useMediaQuery({ maxWidth: theme.screenSizes.large })

  useEffect(() => {
    if (isSmallerThan !== isMobile) {
      setIsMobile(isSmallerThan)
    }
  }, [isSmallerThan])

  const flexDirection = isMobile ? 'column' : (blok.illustration_alignment ?? 'left') === 'left' ? 'row' : 'row-reverse'
  const isFullbleed = blok.illustration_fullbleed

  return (
    <div
      className={isFullbleed ? `${styles.container} ${styles.fullbleed}` : `${styles.container}`}
      style={{ backgroundColor: blok.background_color?.color }}
      {...storyblokEditable(blok)}
    >
      <div className={styles.contentContainer} style={{ flexDirection: flexDirection }}>
        <div className={styles.imageContainer}>
          <StoryblokImage
            src={blok.illustration.filename}
            fill
            sizes={`(max-width: ${ouTheme.breakpoints.desktop}) 100vw, 50vw`}
            alt={blok.illustration.name}
          />
        </div>
        <div className={styles.componentsContainer}>
          {blok.body.map((blok: StoryblokContent) => (
            <DynamicComponent blok={blok} key={blok._uid} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default withTheme(IllustrationWrapper)
