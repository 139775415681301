import { Box, Container, Heading, Text } from 'atomic'
import { Link } from 'components/link/Link'
import React, { FC } from 'react'
import Button from 'atomic/components/atoms/button/Button'
import { BuilderProps, removeKeys } from 'components/builder_components/helpers/BuilderPropCleaner'
import * as css from './FullbleedHero.css'
import { ThemeFontSizes } from 'atomic/styles/theme.css'
import { HeroButton } from '../../HeroGeneric'

type FullbleedHeroProps = {
  heading: string
  intro: string
  image: string
  imagePosition: 'center' | 'top' | 'bottom' | 'left' | 'right'
  detailedImagePosition: number
  richTextHeading: string
  uppercase: boolean
  richTextIntro: string
  headingSize: keyof ThemeFontSizes
  fontSize: keyof ThemeFontSizes
  button: HeroButton
} & BuilderProps

const FullbleedHero: FC<FullbleedHeroProps> = ({
  heading,
  intro,
  image,
  imagePosition,
  detailedImagePosition,
  richTextHeading,
  uppercase,
  richTextIntro,
  headingSize,
  fontSize,
  button,
  ...props
}) => {
  // Remove Builder.io specific props
  const cleanProps = removeKeys(props, { builderBlock: true, builderState: true })

  return (
    <Box
      position={'relative'}
      display={'flex'}
      flexDirection={'column'}
      alignItems={'center'}
      justifyContent={'center'}
      style={{
        backgroundImage: `url(${image})`,
        backgroundPosition:
          imagePosition && detailedImagePosition ? `${detailedImagePosition}% ${imagePosition}` : imagePosition ? imagePosition : 'center',
      }}
      className={css.image}
      {...cleanProps}
    >
      <Box position={'absolute'} className={css.gradientOverlay} />
      <Container className={css.container} position={'relative'} padding={{ mobile: 'l', desktop: 'xxl' }}>
        {/* Empty column to match layout */}
        <div />
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'} gap={'xxl'}>
          <Box display={'flex'} flexDirection={'column'} gap={'m'} alignItems={'center'} justifyContent={'center'}>
            {!richTextHeading ? (
              <Heading
                as={'h2'}
                fontSize={headingSize ?? 'xxl'}
                margin={'none'}
                textAlign={'center'}
                color={'white'}
                fontWeight={'extrabold'}
                style={{ textTransform: uppercase ? 'uppercase' : 'initial', lineHeight: '1' }}
              >
                {heading}
              </Heading>
            ) : (
              <Box
                fontSize={headingSize ?? 'xxl'}
                margin={'none'}
                textAlign={'center'}
                color={'white'}
                fontWeight={'extrabold'}
                className={css.richTextHeading}
                style={{ textTransform: uppercase ? 'uppercase' : 'initial', lineHeight: '1' }}
                dangerouslySetInnerHTML={{ __html: richTextHeading }}
              />
            )}
            {!richTextIntro ? (
              <Text fontWeight={'normal'} textAlign={'center'} margin={'none'} fontSize={fontSize ?? 'l'} color={'white'}>
                {intro}
              </Text>
            ) : (
              <Box
                fontWeight={'normal'}
                textAlign={'center'}
                margin={'none'}
                fontSize={fontSize ?? 'l'}
                color={'white'}
                dangerouslySetInnerHTML={{ __html: richTextIntro }}
              />
            )}
            {button && (
              <Link href={button?.link}>
                <Button
                  variant={button?.type}
                  size={button?.size}
                  style={
                    (button.darkMode && button?.type === 'secondary') || button?.type === 'toggle'
                      ? { borderColor: 'white', color: 'white' }
                      : button.darkMode && button?.type === 'primary'
                      ? { backgroundColor: 'white', color: '#222' }
                      : {}
                  }
                >
                  {button?.label}
                </Button>
              </Link>
            )}
          </Box>
        </Box>
        {/* Empty column to match layout */}
        <div />
      </Container>
    </Box>
  )
}

export default FullbleedHero
