import React, { FC } from 'react'
import { StoryblokContent } from 'types/StoryblokContent'
import styles from './CustomerSatisfactionSection.module.scss'
import { SbBlokData, storyblokEditable } from '@storyblok/react'
import ContentContainer from 'components/styled/ContentContainer'
import Grid from 'components/styled/Grid'
import DynamicComponent from 'components/StoryblokComponents'

type PropTypes = {
  blok?: StoryblokContent
  className?: string
}

const CustomerSatisfactionSection: FC<PropTypes> = (props) => {
  const shouldWrap = props.blok.wrap
  const gridGap = `${props.blok.grid_gap}px`

  return (
    <>
      {!props.blok.hidden && (
        <section className={props.className ? `${styles.container} ${props.className}` : styles.container} {...storyblokEditable(props.blok)}>
          <ContentContainer style={{ width: '100%', paddingLeft: 0, paddingRight: 0 }}>
            <Grid shouldWrap={shouldWrap} gridGap={gridGap}>
              {props.blok.columns.map((item: SbBlokData) => (
                <DynamicComponent key={item._uid} blok={item} />
              ))}
            </Grid>
          </ContentContainer>
        </section>
      )}
    </>
  )
}

export default CustomerSatisfactionSection
