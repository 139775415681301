import React, { FC, useEffect, useRef, useState } from 'react'

import ActionDiv from '../actiondiv/ActionDiv'
import DownArrow from '../icons/DownArrow'

import styles from './Dropdown.module.scss'

export type ListItem = {
  label: string
  key: string
}

type PropTypes = {
  list: ListItem[]
  selectedItem: ListItem
  label: string
  header?: string
  className?: string
  onItemSelected: (listItem: ListItem) => void
  disabled?: boolean
}

const Dropdown: FC<PropTypes> = ({ list, selectedItem, label, header, className, onItemSelected, disabled }) => {
  const node = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const [isListOpen, toggleList] = useState(false)

  const handleClick = (e: MouseEvent): void => {
    if (!node?.current.contains(e.target as Node)) {
      toggleList(false)
    }
  }

  useEffect(() => {
    if (isListOpen) {
      document.addEventListener('mousedown', handleClick)
    }
    return () => document.removeEventListener('mousedown', handleClick)
  })

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [isListOpen])

  const selectItem = (item): void => {
    onItemSelected(item)
    toggleList(false)
  }

  const handleContainerClick = (): void => {
    toggleList(!isListOpen)
  }

  const dropdownValueText = (): JSX.Element => {
    const labelElement = <p>{`– ${label} –`}</p>
    if (isListOpen) {
      return labelElement
    }
    return selectedItem ? <p>{selectedItem.label}</p> : labelElement
  }

  return (
    <div ref={node} className={className ? [styles.container, className].join(' ') : styles.container}>
      {header && <p className={styles.header}>{header}</p>}
      <ActionDiv ariaLabel="dropdown" className={disabled ? `${styles.label} ${styles.disabled}` : styles.label} onClick={handleContainerClick}>
        {dropdownValueText()}
        <div style={isListOpen ? { transform: 'scaleY(-1)', marginBottom: 4 } : null}>
          <DownArrow dark />
        </div>
      </ActionDiv>
      {isListOpen && (
        <ul className={styles.list}>
          {list.map((item, i) => (
            <ActionDiv ariaLabel="list-item dropdown" className={styles.listitem} key={i} onClick={() => selectItem(item)}>
              <p>{item.label}</p>
            </ActionDiv>
          ))}
        </ul>
      )}
    </div>
  )
}

export default Dropdown
