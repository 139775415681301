import { FC } from 'react'
import { StoryblokContent } from 'types/StoryblokContent'

import { storyblokEditable } from '@storyblok/react'

import Check from '../../icons/Check'

import styles from './Checklist.module.scss'

type PropTypes = {
  blok?: StoryblokContent
  smallHeader?: boolean
}

const Checklist: FC<PropTypes> = ({ blok, smallHeader }) => {
  const renderChecklistItems = (): JSX.Element[] => {
    return blok.items.map((checklistItem: StoryblokContent) => {
      return (
        <div className={styles.checklistItem} key={checklistItem._uid}>
          <p>
            <Check />
            {checklistItem.text}
          </p>
        </div>
      )
    })
  }
  return (
    <div className={styles.container} {...storyblokEditable(blok)}>
      <h2 className={styles.header} style={smallHeader && { fontSize: 'var(--baseFontSize)' }}>
        {blok.title}
      </h2>
      {renderChecklistItems()}
    </div>
  )
}

export default Checklist
