import Image, { ImageProps } from 'next/image'
import { FC } from 'react'
import log from 'utils/logger'

const StoryblokImage: FC<ImageProps> = (imageProps: ImageProps) => {
  const src = imageProps.src as string
  if (!src) {
    log.error('No src found for Image')
    return null
  }
  const id = src.split('.com')[1]
  const blurredImageUrl = `https://img2.storyblok.com/filters:quality(10)${id}`
  const imagePropsWithBlur = Object.assign({ placeholder: 'blur', blurDataURL: blurredImageUrl }, imageProps)

  return <Image {...imagePropsWithBlur} />
}

export default StoryblokImage
