import Head from 'next/head'
import { useRouter } from 'next/router'
import { FC, useEffect, useRef, useState } from 'react'
import { NODE_PARAGRAPH, render } from 'storyblok-rich-text-react-renderer'
import { StoryblokContent } from 'types/StoryblokContent'

import { storyblokEditable } from '@storyblok/react'

import ActionDiv from '../../actiondiv/ActionDiv'

import styles from './IndexedRichTextSection.module.scss'

type IndexedRichTextSection = {
  _uid: string
  title: string
  intro_text: string
  items: IndexedRichTextSectionContainer[]
  component: string
  nav_title: string
}

type IndexedRichTextItem = {
  _uid: string
  title: string
  textarea: string
  component: string
}

type IndexedRichTextSectionContainer = {
  _uid: string
  title: string
  items: IndexedRichTextItem[]
  anchor_link: string
}

type PropTypes = {
  blok?: IndexedRichTextSection
}

let navBottomPosition = null

const IndexedRichTextSection: FC<PropTypes> = ({ blok }) => {
  const router = useRouter()

  if (!blok.items || blok.items.length < 1) {
    return <div>SplitContainer is missing</div>
  }

  const [stickNavToBottom, setStickNavToBottom] = useState(false)
  const [isAnchorLink, setIsAnchorLink] = useState(false)
  const sectionRefs = useRef([])
  const mobileSectionRefs = useRef([])
  const navRef = useRef(null)

  const scrollTo = (element: HTMLDivElement): void => {
    const yOffset = -100
    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset

    window.scrollTo({
      top: y,
      behavior: 'smooth',
    })
  }

  const getBottomPositionOfElement = (element: HTMLDivElement): number => {
    return element.getBoundingClientRect().y + element.getBoundingClientRect().height
  }

  const handleScroll = (): void => {
    handleStickyNav()
  }

  const handleResize = (): void => {
    if (navRef?.current && !stickNavToBottom) {
      navBottomPosition = getBottomPositionOfElement(navRef.current)
    }
  }

  const handleStickyNav = (): void => {
    const lastItemRef = sectionRefs.current[sectionRefs.current.length - 1]
    if (lastItemRef) {
      const lastItemRefBottomPosition = getBottomPositionOfElement(lastItemRef)
      setStickNavToBottom(lastItemRefBottomPosition < navBottomPosition)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    window.addEventListener('resize', handleResize)

    if (router.asPath.includes('#')) {
      setIsAnchorLink(true)
    }
    return () => {
      setIsAnchorLink(false)
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    if (navRef?.current) {
      navBottomPosition = getBottomPositionOfElement(navRef.current)
    }
  }, [navRef])

  function capitalizeFirstLetterOf(string: string): string {
    if (!string) return null
    const firstLetter = string.match(/[A-Öa-ö]/)[0]
    const indexOfFirstLetter = string.indexOf(firstLetter)
    return replaceCharAtIndex(string.toLowerCase(), indexOfFirstLetter, firstLetter.toUpperCase())
  }

  function replaceCharAtIndex(string: string, index: number, character: string): string {
    if (index > string.length - 1) return string
    return string.substring(0, index) + character + string.substring(index + 1)
  }

  return (
    <>
      {isAnchorLink && (
        <Head>
          {/* Tell the browser to never restore the scroll position on load */}
          <script
            dangerouslySetInnerHTML={{
              __html: `history.scrollRestoration = "manual"`,
            }}
          />
        </Head>
      )}
      <section className={`${styles.container} largerThanXLarge`} {...storyblokEditable(blok)}>
        <div className={styles.left}>
          <div className={`${styles.navigation} ${stickNavToBottom ? styles.stickToBottom : null}`} ref={navRef}>
            <p className={styles.navTitle}>{blok.nav_title}</p>
            {blok.items.map((container: IndexedRichTextSectionContainer, index: number) => (
              <div key={container._uid} className={styles.navigationItem}>
                <ActionDiv className={`${styles.navButton}`} onClick={() => scrollTo(sectionRefs.current[index])} ariaLabel="Navigation item">
                  {capitalizeFirstLetterOf(container.title)}
                </ActionDiv>
              </div>
            ))}
          </div>
        </div>
        <div className={styles.right}>
          <h1 className={styles.title}>{blok.title}</h1>

          <p className={styles.introText}>{render(blok.intro_text)}</p>
          {blok.items.map((container: IndexedRichTextSectionContainer, index: number) => (
            <div key={container._uid} className={styles.anchor} id={container.anchor_link}>
              <div id={container._uid} className={`${styles.rightContainer}`} ref={(el) => (sectionRefs.current[index] = el)}>
                <h2 className={styles.containerTitle}>{capitalizeFirstLetterOf(container.title)}</h2>
                {container.items.map((item: StoryblokContent) => (
                  <div className={styles.itemContainer} key={item._uid}>
                    <p className={styles.itemTitle}>{capitalizeFirstLetterOf(item.title)}</p>
                    <p className={styles.itemText}>
                      {render(item.textarea, {
                        nodeResolvers: {
                          // eslint-disable-next-line react/display-name
                          [NODE_PARAGRAPH]: (children) => {
                            return <>{children}</>
                          },
                        },
                      })}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </section>
      <section className={`${styles.container} smallerThanXLarge`}>
        <div className={styles.right}>
          <h1 className={styles.title}>{blok.title}</h1>
          <p className={styles.introText}>{render(blok.intro_text)}</p>
          <div className={styles.left}>
            <div className={styles.navigation}>
              <p className={styles.navTitle}>{blok.nav_title}</p>
              {blok.items.map((container: IndexedRichTextSectionContainer, index: number) => (
                <div key={container._uid} className={styles.navigationItem}>
                  <ActionDiv className={`${styles.navButton}`} onClick={() => scrollTo(mobileSectionRefs.current[index])} ariaLabel="Navigation item">
                    {capitalizeFirstLetterOf(container.title)}
                  </ActionDiv>
                </div>
              ))}
            </div>
          </div>
          {blok.items.map((container: IndexedRichTextSectionContainer, index: number) => (
            <div key={container._uid} className={styles.anchor} id={container.anchor_link}>
              <div id={container._uid} className={`${styles.rightContainer}`} ref={(el) => (mobileSectionRefs.current[index] = el)}>
                <h2 className={styles.containerTitle}>{capitalizeFirstLetterOf(container.title)}</h2>
                {container.items.map((item: StoryblokContent) => (
                  <div className={styles.itemContainer} key={item._uid}>
                    <p className={styles.itemTitle}>{capitalizeFirstLetterOf(item.title)}</p>
                    <p className={styles.itemText}>
                      {render(item.textarea, {
                        nodeResolvers: {
                          // eslint-disable-next-line react/display-name
                          [NODE_PARAGRAPH]: (children) => {
                            return <>{children}</>
                          },
                        },
                      })}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  )
}

export default IndexedRichTextSection
