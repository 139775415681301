export const theme = {
  screenSizes: {
    small: '500px',
    medium: '845px',
    large: '1024px',
    xlarge: '1365px',
  },
}

export type Theme = typeof theme
