/* eslint-disable @next/next/no-before-interactive-script-outside-document */
import { useRouter } from 'next/router'
import Script from 'next/script'
import { FC, useEffect } from 'react'

import styles from './Kundo.module.scss'

const Kundo: FC = () => {
  const router = useRouter()
  const language = router.locale.length > 2 ? router.locale.slice(0, 2) : 'en'
  let url = 'https://once.upon.se.kb.kundo.se'

  switch (language) {
    case 'en':
      url = 'https://once.upon.se.kb.kundo.se'
      break
    case 'da':
      url = 'https://upon.once.kb.kundo.se'
      break
    case 'fr':
      url = 'https://onceupon2.kb.kundo.se'
      break
    case 'it':
      url = 'https://oonce.se.kb.kundo.se/'
      break
    case 'nl':
      url = 'https://once.se.kb.kundo.se/'
      break
    case 'nb':
      url = 'https://once.upn.se.se.kb.kundo.se/'
      break
    case 'es':
      url = 'https://es.once.kb.kundo.se/'
      break
    case 'sv':
      url = 'https://onceupon1.kb.kundo.se'
      break
    case 'de':
      url = 'https://once.upon.kb.kundo.se/'
      break
    default:
      url = 'https://once.upon.se.kb.kundo.se'
      break
  }

  useEffect(() => {
    const kundoClickListener = (): void => {
      // scroll to content
      const element = document.getElementById('kundo-knowledge-embed')
      if (element !== undefined && element !== null) {
        element.scrollIntoView({ behavior: 'auto', block: 'start', inline: 'start' })
      }
    }
    document.querySelector('#kundo-knowledge-embed').addEventListener('click', kundoClickListener)
    // clean up
    return () => {
      document.querySelector('#kundo-knowledge-embed')?.removeEventListener('click', kundoClickListener)
    }
  }, [])

  // Special hack to fix issue with Kundo and the Back-button
  useEffect(() => {
    window.addEventListener('popstate', (event) => {
      if (event.state) {
        const { href } = event.state
        if (href === undefined) {
          window.location.href = window.location.href
        }
      }
    })
  })

  return (
    <div className={styles.container}>
      <div id="kundo-knowledge-embed" data-language={language} data-base-url={url} className={styles.kundoContainer}></div>
      <Script src="https://kb.kundo.se/js/embed.js" strategy="beforeInteractive"></Script>
    </div>
  )
}

export default Kundo
