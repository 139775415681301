import ExternalLink from 'components/externallink/ExternalLink'
import InternalLink from 'components/internallink/InternalLink'
import { FC, ReactElement } from 'react'
import { StoryblokLink } from 'types/StoryblokLink'
import { getLinkType, LinkType } from 'utils/get-link-type'
import { urlFromStory } from 'utils/url-from-story'

type PropTypes = {
  linkObject: StoryblokLink
  children: ReactElement
}

const DynamicLink: FC<PropTypes> = ({ linkObject, children }) => {
  if (linkObject?.linktype === 'story' && !linkObject.story) {
    return null
  }

  if (linkObject) {
    const linkType = getLinkType(linkObject)
    if (linkType === LinkType.INTERNAL) {
      const url = urlFromStory(linkObject)
      const anchor = linkObject.anchor
      return (
        <InternalLink href={url} anchor={anchor}>
          {children}
        </InternalLink>
      )
    } else if (linkType === LinkType.EXTERNAL) {
      return <ExternalLink href={linkObject.url}>{children}</ExternalLink>
    } else {
      // fallback
      return <InternalLink href={'/'}>{children}</InternalLink>
    }
  } else {
    // fallback
    return <InternalLink href={'/'}>{children}</InternalLink>
  }
}

export default DynamicLink
