import { useRouter } from 'next/router'
import { FC, SyntheticEvent } from 'react'
import { StoryblokContent } from 'types/StoryblokContent'

import { storyblokEditable } from '@storyblok/react'

import { useLink } from '../../../context/LinkContext'
import { useModalState } from '../../../context/ModalStateContext'
import { languageStringFromLocale } from '../../languagepicker/LanguageHelper'
import Modal from '../../modal/Modal'

import styles from './GetStoriesModal.module.scss'
import { useOuCookies } from 'components/cookiebanner/CookieBanner'

type PropTypes = {
  blok?: StoryblokContent
}

const GetStoriesModal: FC<PropTypes> = ({ blok }) => {
  const { consentNecessary, NEXT_LOCALE, setCookies } = useOuCookies()
  const { showGetStoriesModal, setShowGetStoriesModal } = useModalState()
  const router = useRouter()
  const { getLocalePath } = useLink()

  const closeStoriesModal = (): void => {
    const storage = globalThis?.sessionStorage
    if (!storage) return

    let prevPath = storage.getItem('prevPath') ?? '/' // default to root of the website i.e. if the user is coming directly from an external source
    const currentPath = storage.getItem('currentPath')
    if (prevPath === currentPath) prevPath = '/' // this happens i.e. if you refresh the page

    if (prevPath && prevPath !== null && prevPath !== undefined) {
      router.push({ pathname: prevPath, query: router.query }, prevPath, { locale: false }).then(() => {
        setShowGetStoriesModal(false)
      })
    }
  }

  const selectLanguage = (selectedLocale: string, event: SyntheticEvent): void => {
    event.preventDefault()
    const { pathname, query } = router
    const localePath = getLocalePath(router.asPath, selectedLocale)
    router.push({ pathname, query }, localePath, { locale: selectedLocale, scroll: false }).then(() => setShowGetStoriesModal(false))

    if (consentNecessary && NEXT_LOCALE !== selectedLocale) {
      setCookies('NEXT_LOCALE', selectedLocale)
    }
  }

  return (
    <Modal show={showGetStoriesModal} onClosed={() => closeStoriesModal()}>
      <div className={styles.textContainer} {...storyblokEditable(blok)}>
        <h2>{blok.title}</h2>
        <p>{blok.text}</p>
        <div className={styles.languagesContainer}>
          <a key={'en-gb'} className={styles.listitem} href={`/en-gb/stories`} onClick={(event) => selectLanguage('en-gb', event)}>
            English
          </a>
          <a key={'sv-se'} className={styles.listitem} href={`/sv-se/stories`} onClick={(event) => selectLanguage('sv-se', event)}>
            {languageStringFromLocale('sv-se')}
          </a>
          <a key={'nl-nl'} className={styles.listitem} href={`/nl-nl/stories`} onClick={(event) => selectLanguage('nl-nl', event)}>
            {languageStringFromLocale('nl-nl')}
          </a>
          <a key={'de-de'} className={styles.listitem} href={`/de-de/stories`} onClick={(event) => selectLanguage('de-de', event)}>
            {languageStringFromLocale('de-de')}
          </a>
          <a key={'it-it'} className={styles.listitem} href={`/it-it/stories`} onClick={(event) => selectLanguage('it-it', event)}>
            {languageStringFromLocale('it-it')}
          </a>
          <a key={'fr-fr'} className={styles.listitem} href={`/fr-fr/stories`} onClick={(event) => selectLanguage('fr-fr', event)}>
            {languageStringFromLocale('fr-fr')}
          </a>
          <a key={'es-es'} className={styles.listitem} href={`/es-es/stories`} onClick={(event) => selectLanguage('es-es', event)}>
            {languageStringFromLocale('es-es')}
          </a>
        </div>
        <p className={styles.infoText}>{blok.postscript_text}</p>
      </div>
    </Modal>
  )
}

export default GetStoriesModal
