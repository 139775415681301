import { Box, Button, Container, Heading, Text } from 'atomic'
import React from 'react'
import * as css from './ContractSection.css'
import { BuilderProps, removeKeys } from 'components/builder_components/helpers/BuilderPropCleaner'
import Image from 'next/image'
import { Link } from 'components/link/Link'
import { ButtonType } from 'atomic/components/atoms/button/Button'
import { ouTheme } from 'atomic/styles/theme.css'
import { useMediaQuery } from 'react-responsive'

type ButtonItem = {
  text: string
  type: ButtonType
  link: string
  target: boolean
  size: 'l' | 'm' | 's'
}

type HandwrittenItem = {
  text: string
  fontFamily?: keyof typeof ouTheme.fontFamilies
  fontSize?: keyof typeof ouTheme.fontSizes
  lineHeight?: keyof typeof ouTheme.lineHeights
}

type Props = {
  backgroundImage: string
  hideBackgroundImageOnMobile?: boolean
  image: string
  heading: string
  addHeadingLink?: boolean
  headingLink?: string
  intro: string
  button: ButtonItem
  handwrittenText: HandwrittenItem
} & BuilderProps

const ContractSection: React.FC<Props> = ({
  backgroundImage,
  hideBackgroundImageOnMobile = false,
  image,
  heading,
  addHeadingLink = false,
  headingLink,
  intro,
  button,
  handwrittenText,
  ...props
}) => {
  // Remove Builder.io specific props
  const cleanProps = removeKeys(props, { builderBlock: true, builderState: true })
  const isDesktopMobile = useMediaQuery({ maxWidth: ouTheme.breakpoints.tablet })

  return (
    <Box
      backgroundColor={'kantarell4'}
      position={'relative'}
      display={'flex'}
      flexDirection={'column'}
      paddingY={'xxl'}
      gap={'xl'}
      style={{
        backgroundImage: hideBackgroundImageOnMobile && isDesktopMobile ? '' : `url(${backgroundImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
      id={addHeadingLink ? headingLink : undefined}
      {...cleanProps}
    >
      <Box display={{ mobile: 'flex', desktop: 'none' }} className={css.imageContainer}>
        <Image src={image} fill style={{ objectFit: 'contain', objectPosition: 'center bottom' }} alt={`${image} image`} />
      </Box>
      <Container
        display={'flex'}
        flexDirection={{ mobile: 'column', desktop: 'row' }}
        style={{ width: '100%' }}
        gap={{ mobile: 'xl', desktop: 'xxxl' }}
      >
        <Box display={'flex'} flexDirection={'column'} gap={'l'} justifyContent={'center'} style={{ width: '100%' }}>
          <Box display={'flex'} flexDirection={'column'} gap={'l'} justifyContent={'center'}>
            <Heading as={'h3'} fontSize={'xl'} fontWeight={'extrabold'} margin={'none'}>
              {heading}
            </Heading>
            <Box fontSize={'base'} margin={'none'} fontWeight={'light'} dangerouslySetInnerHTML={{ __html: intro }} />
          </Box>
          <Link href={button.link} target={button.target ? '_blank' : undefined} style={{ zIndex: 1 }}>
            <Button variant={button.type ?? 'primary'} size={button.size ?? 'l'}>
              {button.text}
            </Button>
          </Link>
          <Box style={{ transform: 'rotate(-4.478deg)', maxWidth: 583 }}>
            <Text
              fontFamily={handwrittenText?.fontFamily ?? 'accent'}
              fontSize={handwrittenText?.fontSize ?? 'xl'}
              lineHeight={handwrittenText?.lineHeight ?? 'xs'}
              margin={'none'}
            >
              {handwrittenText.text}
            </Text>
          </Box>
        </Box>
        <Box display={{ mobile: 'none', desktop: 'flex' }} className={css.imageContainer} style={{ width: '100%' }}>
          <Image src={image} fill style={{ objectFit: 'contain', objectPosition: 'right bottom' }} alt={`${image} image`} />
        </Box>
      </Container>
    </Box>
  )
}

export default ContractSection
