/* eslint-disable @typescript-eslint/no-empty-function */

import { createContext, FC, ReactNode, useContext } from 'react'

import JobylonJob from '../types/JobylonJob'

type JobylonListContextType = {
  jobylonlist: JobylonJob[]
}

const JobylonListContextDefaultValues: JobylonListContextType = {
  jobylonlist: [],
}

const JobylonListContext = createContext<JobylonListContextType>(JobylonListContextDefaultValues)

export function useJobList(): JobylonListContextType {
  return useContext(JobylonListContext)
}

type PropTypes = {
  children: ReactNode
  jobylonlist: JobylonJob[]
}

export const JobylonProvider: FC<PropTypes> = ({ children, jobylonlist }) => {
  const value = {
    jobylonlist: jobylonlist,
  }

  return (
    <>
      <JobylonListContext.Provider value={value}>{children}</JobylonListContext.Provider>
    </>
  )
}
