import React, { FC } from 'react'
import { StoryblokContent } from 'types/StoryblokContent'

import { storyblokEditable } from '@storyblok/react'

import styles from './Youtube.module.scss'

type PropTypes = {
  blok?: StoryblokContent
}

const Youtube: FC<PropTypes> = ({ blok }) => {
  // Url should be i.e. https://www.youtube.com/watch?v=hDpuoxGorBA
  let embeddedVideoUrl: string = blok.url.indexOf('watch?v=') > 0 ? blok.url.replace('watch?v=', 'embed/') : blok.url

  // related videos will come from the same channel as the video that was just played
  if (embeddedVideoUrl.indexOf('?') > 0) {
    embeddedVideoUrl += '&rel=0'
  } else {
    embeddedVideoUrl += '?rel=0'
  }

  const isFullWidth = blok.full_width ?? true

  return (
    <div className={styles.container} {...storyblokEditable(blok)}>
      <div className={isFullWidth ? `${styles.videoContainer} ${styles.fullWidth}` : `${styles.videoContainer}`}>
        <iframe
          src={embeddedVideoUrl}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  )
}

export default Youtube
