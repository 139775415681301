export const trackOutboundLink = (url: string): void => {
  if (!url) return

  if (typeof window?.gtag !== 'undefined') {
    gtag('event', 'outbound', { click: url })
  }
}
type GtagEvent = {
  action: string
  value: string
  clickTarget?: string
}
export const trackEvent = (event: GtagEvent): void => {
  if (typeof window?.gtag !== 'undefined') {
    gtag('event', event.action, {
      value: event.value,
    })
  }
}
