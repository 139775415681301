import { FC } from 'react'
import { render } from 'storyblok-rich-text-react-renderer'
import { StoryblokContent } from 'types/StoryblokContent'

import { storyblokEditable } from '@storyblok/react'

import styles from './RichtextContainer.module.scss'

type PropTypes = {
  blok?: StoryblokContent
}

const RichtextContainer: FC<PropTypes> = ({ blok }) => {
  return (
    <div className={styles.container} {...storyblokEditable(blok)}>
      {render(blok.richtext)}
    </div>
  )
}

export default RichtextContainer
