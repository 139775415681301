import React, { FC } from 'react'
import { ThemeColors } from 'atomic/styles/theme.css'
import { Facebook } from './Facebook'
import { Instagram } from './Instagram'
import { Pinterest } from './Pinterest'

export const socialMediaIconTypes = ['facebook', 'instagram', 'pinterest'] as const

export type SocialMediaIconType = (typeof socialMediaIconTypes)[number]

export type SocialMediaIconProps = {
  name: SocialMediaIconType
  color: keyof ThemeColors
  width?: number
  height?: number
  className?: string
}

const SocialMediaIcon: FC<SocialMediaIconProps> = ({ name, color, className, width, height }) => {
  switch (name) {
    case 'facebook':
      return <Facebook className={className} color={color} width={width} height={height} />
    case 'instagram':
      return <Instagram className={className} color={color} width={width} height={height} />
    case 'pinterest':
      return <Pinterest className={className} color={color} width={width} height={height} />
    default:
      return null
  }
}

export default SocialMediaIcon
