import { FC } from 'react'
import { StoryblokContent } from 'types/StoryblokContent'

import { storyblokEditable } from '@storyblok/react'

import ContentContainer from '../../styled/ContentContainer'

import styles from './TextSectionWithStyle.module.scss'

type PropTypes = {
  blok?: StoryblokContent
}

const TextSectionWithStyle: FC<PropTypes> = ({ blok }) => {
  if (blok.hide) return null

  const bgColorHex = blok.background_color?.color
  return (
    <section className={styles.container} {...storyblokEditable(blok)}>
      <ContentContainer className={styles.contentContainer} style={{ backgroundColor: bgColorHex }}>
        <h2 style={{ margin: !blok.intro_text && 0 }}>{blok.title}</h2>
        {blok.intro_text && <p className={styles.introText}>{blok.intro_text}</p>}
        {blok.italic_text && <p className={styles.italicText}>{blok.italic_text}</p>}
      </ContentContainer>
    </section>
  )
}

export default TextSectionWithStyle
