import { FC } from 'react'
import { StoryblokContent } from 'types/StoryblokContent'

import { storyblokEditable } from '@storyblok/react'

import StoryblokImage from '../../storyblokimage/StoryblokImage'

import styles from './ImageSection.module.scss'

type PropTypes = {
  blok?: StoryblokContent
}

const ImageSection: FC<PropTypes> = ({ blok }) => {
  return (
    <section className={styles.container} {...storyblokEditable(blok)}>
      <div className={styles.contentContainer}>
        <div className={`${styles.backgroundWrapper} largerThanSmall`}>
          <StoryblokImage src={blok.image.filename} alt={blok.image.name} fill quality={100} />
        </div>
        <div className={`${styles.backgroundWrapper} smallerThanSmall`}>
          <StoryblokImage
            src={blok.image_mobile_url?.url ? blok.image_mobile_url.url : blok.image.filename}
            alt={blok.image.name}
            fill
            quality={100}
          />
        </div>
      </div>
    </section>
  )
}

export default ImageSection
