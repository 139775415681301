import { FC } from 'react'
import { StoryblokContent } from 'types/StoryblokContent'

import { Button } from '@onceuponapp/ui'
import { storyblokEditable } from '@storyblok/react'

import { useModalState } from '../../../../context/ModalStateContext'
import IllustrationContainer from '../../illustrationcontainer/IllustrationContainer'

type PropTypes = {
  blok?: StoryblokContent
}

const GetNewsletterModalResult: FC<PropTypes> = ({ blok }) => {
  const { setShowGetAppModal, setShowGetNewsletterModal } = useModalState()

  const showGetAppModal = (): void => {
    setShowGetNewsletterModal(false)
    setShowGetAppModal(true)
  }

  return (
    <div {...storyblokEditable(blok)}>
      <IllustrationContainer blok={blok.illustration_container[0]} />
      <Button onClick={() => setShowGetNewsletterModal(false)}>{blok.back_button_title}</Button>
      <Button onClick={showGetAppModal}>{blok.get_app_button_title}</Button>
    </div>
  )
}

export default GetNewsletterModalResult
