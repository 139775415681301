import { FC, useEffect, useRef } from 'react'

import { DisableGlobalScroll, Overlay } from '../styled/Overlay'

import styles from './Modal.module.scss'

type PropTypes = {
  show?: boolean
  onClosed: () => void
  children: JSX.Element | JSX.Element[]
  className?: string
}

const Modal: FC<PropTypes> = ({ show, onClosed, children, className }) => {
  const modalElement = useRef(null)

  useEffect(() => {
    if (show && modalElement.current) {
      modalElement.current.focus()
    }
  }, [show])

  const handleKeypress = (e: React.KeyboardEvent): void => {
    switch (e.code) {
      case 'Escape':
        onClosed()
        break
      default:
        break
    }
  }

  return (
    <Overlay style={{ display: show ? 'block' : 'none' }} ref={modalElement} onKeyDown={handleKeypress} tabIndex={-1}>
      <DisableGlobalScroll show={show} />
      <div className={`${styles.contentContainer} ${className}`}>
        <button className={`${styles.closeButton} noEffects`} onClick={() => onClosed()}>
          &#x2715;
        </button>
        {children}
      </div>
    </Overlay>
  )
}

export default Modal
