import { trackOutboundLink } from 'lib/analytics-utils'
import { FC, ReactElement } from 'react'
import { useRouter } from 'next/router'
import { getCampaignParameters } from 'utils/get-campaign-parameters'

type PropTypes = {
  children: ReactElement
  href: string
}

const ExternalLink: FC<PropTypes> = (props) => {
  const { href, children, ...rest } = props
  const router = useRouter()
  const campaignParameters = getCampaignParameters(router.query)
  const campaignParametersAsQueryString = new URLSearchParams(campaignParameters).toString()
  let url = href
  const hasParametersAlreadyBeenAppended = url?.indexOf(campaignParametersAsQueryString) > -1

  if (campaignParameters && !hasParametersAlreadyBeenAppended) {
    url = `${url}?${campaignParametersAsQueryString}`
  }
  const onClick = (): void => {
    trackOutboundLink(url)
  }

  const handleKeypress = (e: React.KeyboardEvent): void => {
    if (e.code === 'Enter') {
      onClick()
    }
  }

  return (
    <a href={url} onClick={onClick} onKeyDown={handleKeypress} target="_blank" rel="noreferrer" {...rest}>
      {children}
    </a>
  )
}

export default ExternalLink
