import axios from 'axios'
import { FC, useState } from 'react'
import { StoryblokContent } from 'types/StoryblokContent'

import { storyblokEditable } from '@storyblok/react'

import ContentContainer from '../../styled/ContentContainer'
import FormSection from '../formsection/FormSection'

import styles from './FeedbackForm.module.scss'

type PropTypes = {
  blok?: StoryblokContent
}

type Target = {
  email: { value: string }
  feedback: { value: string }
}

const FeedbackForm: FC<PropTypes> = ({ blok }) => {
  const [files, setFiles] = useState<{ [key: string]: File }>({})

  const subject = (): string => {
    return `Feedback`
  }

  const message = (target: Target): string => {
    return `
    <b>Email</b><br/> ${target.email.value}<br/><br/>
    <b>Feedback</b><br/> ${target.feedback.value}<br/><br/>
    <br/>
    `
  }

  const handleOnSubmit = async (event: React.SyntheticEvent, token: string): Promise<void> => {
    const url = process.env.NEXT_PUBLIC_SUPPORT_FORM_URL
    const target = event.target as typeof event.target & Target

    const formData = new FormData()
    formData.append('email', target.email.value)
    formData.append('captcha', token)
    formData.append('subject', subject())
    formData.append('message', message(target))

    const images = Object.values(files)
    images.forEach((image) => {
      formData.append('files', image)
    })

    const config = {
      headers: { 'content-type': 'multipart/form-data' },
    }

    return await axios.post(url, formData, config)
  }

  return (
    <section className={styles.container} {...storyblokEditable(blok)}>
      <ContentContainer>
        <FormSection blok={blok.form[0]} handleOnSubmit={handleOnSubmit} getFiles={files} setFiles={setFiles} />
      </ContentContainer>
    </section>
  )
}

export default FeedbackForm
