import DynamicComponent from 'components/StoryblokComponents'
import { FC } from 'react'
import { StoryblokContent } from 'types/StoryblokContent'

import { storyblokEditable } from '@storyblok/react'

import ContentContainer from '../../styled/ContentContainer'

import styles from './TextSection.module.scss'

type PropTypes = {
  blok?: StoryblokContent
}

const TextSection: FC<PropTypes> = ({ blok }) => {
  if (blok.hide) return null

  const leftAlignedItems = blok.left_aligned_items

  return (
    <>
      <span className={styles.anchor} id={blok.anchor_id} />
      <section className={blok.left_aligned ? `${styles.container} ${styles.leftAligned} ` : styles.container} {...storyblokEditable(blok)}>
        <ContentContainer className={styles.contentContainer}>
          <span className={styles.tagline}>{blok.tagline}</span>
          <h2>{blok.title}</h2>
          {blok.intro_text && <p className={styles.introText}>{blok.intro_text}</p>}
          {blok.bold_intro_text && <p className={styles.boldIntroText}>{blok.bold_intro_text}</p>}
          <div className={leftAlignedItems ? styles.leftAlignedItems : null}>
            {blok.items.map((blok: StoryblokContent, index: number) => (
              <div key={index}>
                {leftAlignedItems && <div className={styles.count}>{index + 1}.</div>}
                <DynamicComponent blok={blok} key={blok._uid} />
              </div>
            ))}
          </div>
        </ContentContainer>
      </section>
    </>
  )
}

export default TextSection
