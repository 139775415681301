const isVideo = (filename: string): boolean => {
  if (!filename) return false
  const fileExtension = filename.split('.').pop().toLocaleLowerCase()
  switch (fileExtension) {
    case 'm4v':
    case 'avi':
    case 'mpg':
    case 'mp4':
      return true
  }
  return false
}

export default isVideo
