import { FC } from 'react'

type PropTypes = {
  dark?: boolean
  strokeWidth?: number
}
const DownArrow: FC<PropTypes> = ({ dark, strokeWidth }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19 9L12 16L5 9" stroke={dark ? '#222' : '#fff'} strokeWidth={strokeWidth ?? '1'} strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export default DownArrow
