/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import * as Sentry from '@sentry/nextjs'

const isProduction = process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'

const getTimeString = (): string => {
  const now = new Date()
  return `[${now.toLocaleTimeString()}]`
}

const paramsToString = (params: any[]): string => {
  const stringArray = params.map((param) => JSON.stringify(param))
  return stringArray.join(', ')
}

const styles = {
  info: 'color: #6493ba; font-weight: bold',
  warning: 'color: #f3d877; font-weight: bold',
  error: 'color: #b40b0b; font-weight: bold',
}

const info = (message?: any, ...optionalParams: any[]): void => {
  if (!isProduction) {
    console.log(`${getTimeString()} %c[INFO] %c${message}`, styles.info, {}, ...optionalParams)
  } else {
    Sentry.captureMessage(`${getTimeString()} [INFO] ${message} ${paramsToString(optionalParams)}`)
  }
}

const warn = (message?: any, ...optionalParams: any[]): void => {
  if (!isProduction) {
    console.log(`${getTimeString()} %c[WARNING] %c${message}`, styles.warning, {}, ...optionalParams)
  } else {
    Sentry.captureMessage(`${getTimeString()} [WARNING] ${message} ${paramsToString(optionalParams)}`)
  }
}

const error = (message?: any, ...optionalParams: any[]): void => {
  if (!isProduction) {
    console.log(`${getTimeString()} %c[ERROR] %c${message}`, styles.error, {}, ...optionalParams)
  } else {
    Sentry.captureException(message)
  }
}

const log = {
  info,
  warn,
  error,
}

export default log
