import { useEffect, useState } from 'react'

const DAY_MULTIPLIER = 86400
const HOUR_MULTIPLIER = 3600
const MINUTE_MULTIPLIER = 60

export function getRemainingTime(endTime: Date) {
  const remainingSeconds = new Date(endTime).getTime() / 1000 - Date.now() / 1000

  if (remainingSeconds < 0) return 0

  return remainingSeconds
}

export function countdownFormat(time: number) {
  return time < 10 ? `0${time}` : time
}

// Returns an array with [days, hours, minutes, seconds]
export default function useCountdown(targetTime: Date) {
  const [remainingSeconds, setRemainingSeconds] = useState(getRemainingTime(targetTime))

  useEffect(() => {
    setRemainingSeconds(getRemainingTime(targetTime))
    const interval = setInterval(() => {
      setRemainingSeconds(getRemainingTime(targetTime))
    }, 1000)

    return () => clearInterval(interval)
  }, [targetTime])

  const days = Math.floor(remainingSeconds / DAY_MULTIPLIER)
  const hours = Math.floor((remainingSeconds - days * DAY_MULTIPLIER) / HOUR_MULTIPLIER)
  const minutes = Math.floor((remainingSeconds - days * DAY_MULTIPLIER - hours * HOUR_MULTIPLIER) / MINUTE_MULTIPLIER)
  const seconds = Math.floor(remainingSeconds - days * DAY_MULTIPLIER - hours * HOUR_MULTIPLIER - minutes * MINUTE_MULTIPLIER)

  return [days, hours, minutes, seconds]
}
