import { FC } from 'react'
import { StoryblokContent } from 'types/StoryblokContent'

import { storyblokEditable } from '@storyblok/react'

import StoryblokImage from '../../storyblokimage/StoryblokImage'

import styles from './ImageWithDescription.module.scss'
import { ouTheme } from 'atomic/styles/theme.css'

type PropTypes = {
  blok?: StoryblokContent
}

const ImageWithDescription: FC<PropTypes> = ({ blok }) => {
  return (
    <div {...storyblokEditable(blok)}>
      <div className={styles.imageContainer}>
        <StoryblokImage
          key={blok.image.id}
          src={blok.image.filename}
          alt={blok.image.name}
          fill
          sizes={`(max-width: ${ouTheme.breakpoints.desktop}) 100vw, 50vw`}
          className={styles.image}
        />
      </div>
      <div className={styles.textContainer}>
        <div className={styles.title}>{blok.title}</div>
        <div className={styles.description}>{blok.description}</div>
      </div>
    </div>
  )
}

export default ImageWithDescription
