import React, { CSSProperties, FC } from 'react'

import Button from '../../atoms/button/Button'
import Text from '../../atoms/text/Text'
import * as css from './CardContent.css'
import { CardContentProps, TextItem } from './types'

const defaultTitle: TextItem = { text: 'Title', style: { fontWeight: 'bold', fontSize: 'l', textAlign: 'center', marginBottom: 'm' } }
const defaultSubtitle: TextItem = {
  text: 'Subtitle',
  style: { fontSize: 's', textAlign: 'center', paddingLeft: 'm', paddingRight: 'm' },
}
const CardContent: FC<CardContentProps> = ({ title = defaultTitle, subtitle = defaultSubtitle, action, illustration, backgroundColor }) => {
  const styles: Record<string, CSSProperties> = {
    container: {
      backgroundColor: backgroundColor,
    },
  }
  return (
    <div className={css.cardContent} style={styles.container}>
      {illustration}
      <Text {...title.style}>{title.text}</Text>
      {subtitle.text !== defaultSubtitle.text ? <Text {...subtitle.style}>{subtitle.text}</Text> : null}

      {action ? (
        <a href={action.url} target="_blank" rel="noreferrer">
          <Button variant="secondary">{action.btnText}</Button>
        </a>
      ) : null}
    </div>
  )
}

export default CardContent
