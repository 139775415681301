export enum Environment {
  LOCAL = 'local',
  DEVELOPMENT = 'development',
  STAGING = 'staging',
  PRODUCTION = 'production',
}

export const getEnvironment = (): Environment => {
  // If building on vercel, NEXT_PUBLIC_VERCEL_ENV will be either production or preview
  if (process.env.NEXT_PUBLIC_VERCEL_ENV === 'production') {
    return Environment.PRODUCTION
  } else {
    // Switch on custom set variable (set in package.json and github action scripts)
    // Altough NEXT_PUBLIC_VERCEL_ENV is preview - NEXT_PUBLIC_WEBAPP_ENV can be either staging or development
    // If building a local production build - NEXT_PUBLIC_WEBAPP_ENV will be production
    switch (process.env.NEXT_PUBLIC_WEBAPP_ENV) {
      case 'production':
        return Environment.PRODUCTION
      case 'staging':
        return Environment.STAGING
      case 'development':
        return Environment.DEVELOPMENT
      default:
        return Environment.LOCAL
    }
  }
}

export const getIsProduction = (): boolean => {
  return getEnvironment() === Environment.PRODUCTION
}

export const getIsStaging = (): boolean => {
  return getEnvironment() === Environment.STAGING
}

export const getIsDevelopment = (): boolean => {
  return getEnvironment() === Environment.DEVELOPMENT
}

export const getIsLocal = (): boolean => {
  return getEnvironment() === Environment.LOCAL
}
