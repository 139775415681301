import { Box } from 'atomic'
import React, { AllHTMLAttributes, FC, FormEvent, useRef, useState } from 'react'
import * as css from '../../contact_forms/ContactForms.css'
import { useSafeTranslation } from 'hooks/useSafeTranslation'
import { themeVars } from 'atomic/styles/theme.css'
import { Sprinkles, sprinkles } from 'atomic/styles/sprinkles.css'
import { InputSize } from 'atomic/components/atoms/input/Input'
import { parseSprinkleProps } from 'atomic/components/atoms/helpers/sprinkleHelper'
import { inputSize } from 'atomic/components/atoms/input/input.css'
import { Loadingstatus } from 'common/enums'
import axios from 'axios'
import log from 'utils/logger'
import { Button, Modal } from '@onceuponapp/ui'
import ReCAPTCHA from 'react-google-recaptcha'
import FormInput from '../form_components/form_input/FormInput'
import FormTextArea from '../form_components/form_text_area/FormTextArea'

type FormProps = Omit<AllHTMLAttributes<HTMLInputElement>, 'className' | 'size'> &
  Sprinkles & {
    className?: string
    size: InputSize
  }

const CollaborateForm: FC<FormProps> = ({ className, size = 'm', borderRadius = 's', fontWeight = 'normal', ...props }) => {
  const { t } = useSafeTranslation()
  const { sprinkleProps, otherProps } = parseSprinkleProps(props)
  const [formData, setFormData] = useState({ name: '', country: '', email: '', link1: '', link2: '', link3: '', message: '' })
  const [loadingStatus, setLoadingStatus] = useState<Loadingstatus>(Loadingstatus.IDLE)
  const recaptchaRef = useRef(null)

  const subject = (): string => {
    return `Collaboration request - ${formData.country} - ${formData.email}`
  }

  const message = (): string => {
    return `
    <br/>
    <b>Name:</b> ${formData.name}<br/>
    <b>Email:</b> ${formData.email}<br/>
    <b>Country:</b> ${formData.country}<br/>
    <b>Social Media Link:</b> ${formData.link1}<br/>
    <b>Social Media Link (optional):</b> ${formData.link2}<br/>
    <b>Social Media Link (optional):</b> ${formData.link3}<br/>
    <b>Message:</b> ${formData.message}<br/>
    <br/>
    `
  }

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    recaptchaRef.current.reset()
    setLoadingStatus(Loadingstatus.LOADING)
    let token = ''
    try {
      token = await recaptchaRef.current.executeAsync()
    } catch (error) {
      log.error(error)
    }

    const url = process.env.NEXT_PUBLIC_COLLABORATION_FORM_URL

    const formDataRequest = new FormData()
    formDataRequest.append('email', formData.email)
    formDataRequest.append('captcha', token)
    formDataRequest.append('subject', subject())
    formDataRequest.append('message', message())

    const config = {
      headers: { 'content-type': 'multipart/form-data' },
    }

    try {
      await axios.post(url, formDataRequest, config)
      setLoadingStatus(Loadingstatus.SUCCESS)
      onReset()
    } catch (error) {
      log.error(error)
      setLoadingStatus(Loadingstatus.IDLE)
    }

    // Reset the reCAPTCHA so that it can be executed again if user submits another email.
    recaptchaRef.current.reset()
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    setFormData({ ...formData, [name]: value })
  }

  const onReset = () => {
    setFormData({ name: '', country: '', email: '', link1: '', link2: '', link3: '', message: '' })
  }

  return (
    <Box>
      <form name={'collaborate'} onSubmit={onSubmit}>
        <Box display={'flex'} flexDirection={'column'} gap={'xxl'}>
          <Box gap={'m'} className={css.inputContainer}>
            <Box gap={'m'} className={css.columns}>
              <FormInput
                type="text"
                name="name"
                translatedText={t('web_influencer_landingpage_form_name')}
                value={formData.name}
                onChange={handleInputChange}
                style={{ width: '100%' }}
                className={`${inputSize[size]} ${sprinkles({ ...sprinkleProps, borderRadius, fontWeight })}`}
                {...otherProps}
                required
              />
              <FormInput
                type="text"
                name="country"
                translatedText={t('web_influencer_landingpage_form_country')}
                value={formData.country}
                onChange={handleInputChange}
                style={{ width: '100%' }}
                className={`${className} ${inputSize[size]} ${sprinkles({ ...sprinkleProps, borderRadius, fontWeight })}`}
                {...otherProps}
                required
              />
              <FormInput
                type="email"
                name="email"
                translatedText={t('web_influencer_landingpage_form_email')}
                value={formData.email}
                onChange={handleInputChange}
                style={{ width: '100%' }}
                className={`${className} ${inputSize[size]} ${sprinkles({ ...sprinkleProps, borderRadius, fontWeight })}`}
                {...otherProps}
                required
              />

              <FormInput
                type="text"
                name="link1"
                translatedText={t('web_influencer_landingpage_form_socialmedialink')}
                value={formData.link1}
                onChange={handleInputChange}
                style={{ width: '100%' }}
                className={`${className} ${inputSize[size]} ${sprinkles({ ...sprinkleProps, borderRadius, fontWeight })}`}
                {...otherProps}
                required
              />
              <FormInput
                type="text"
                name="link2"
                translatedText={`${t('web_influencer_landingpage_form_socialmedialink')} (${t('web_influencer_landingpage_form_optional')})`}
                value={formData.link2}
                onChange={handleInputChange}
                style={{ width: '100%' }}
                className={`${className} ${inputSize[size]} ${sprinkles({ ...sprinkleProps, borderRadius, fontWeight })}`}
                {...otherProps}
              />
              <FormInput
                type="text"
                name="link3"
                translatedText={`${t('web_influencer_landingpage_form_socialmedialink')} (${t('web_influencer_landingpage_form_optional')})`}
                value={formData.link3}
                onChange={handleInputChange}
                style={{ width: '100%' }}
                className={`${className} ${inputSize[size]} ${sprinkles({ ...sprinkleProps, borderRadius, fontWeight })}`}
                {...otherProps}
              />
            </Box>
            <FormTextArea
              name="message"
              translatedText={t('web_influencer_landingpage_form_message')}
              value={formData.message}
              onChange={handleInputChange}
              style={{ width: '100%', height: 205, resize: 'none' }}
              className={`${className} ${inputSize[size]} ${sprinkles({ ...sprinkleProps, borderRadius, fontWeight })}`}
              required
              {...otherProps}
            />
          </Box>
          <Box display={'flex'} alignItems={'center'} justifyContent={'center'} gap={{ mobile: 's', desktop: 'l' }}>
            <Button type="reset" style={{ width: 260, fontSize: themeVars.fontSizes.s, outline: 'none' }} theme="secondary" onClick={onReset}>
              {t('web_influencer_landingpage_form_empty_form')}
            </Button>
            <Button
              loadingStatus={loadingStatus}
              type="submit"
              style={{ width: 260, fontSize: themeVars.fontSizes.s, outline: 'none' }}
              theme="primary"
            >
              {t('web_influencer_landingpage_form_button_text')}
            </Button>
          </Box>
        </Box>
      </form>

      <ReCAPTCHA
        ref={recaptchaRef}
        onExpired={() => recaptchaRef.current.reset()}
        size="invisible"
        sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}
      />

      <Modal
        illustration="stars-1"
        open={loadingStatus === Loadingstatus.SUCCESS}
        onClose={() => {
          setLoadingStatus(Loadingstatus.IDLE)
        }}
        actions={[{ label: t('web_influencer_submition_sent_confirmation_button'), onAction: () => setLoadingStatus(Loadingstatus.IDLE) }]}
        title={t('web_influencer_submition_sent_confirmation_header')}
        description={t('web_influencer_submition_sent_confirmation_body')}
        theme="kantarell-3"
      />
    </Box>
  )
}

export default CollaborateForm
