import { FC } from 'react'
import { StoryblokContent } from 'types/StoryblokContent'

import { useJobList } from '../../../context/JobylonListContext'
import RightArrow from '../../icons/RightArrow'

import styles from './JobList.module.scss'

type PropTypes = {
  blok?: StoryblokContent
}

const JobList: FC<PropTypes> = ({ blok }) => {
  const { jobylonlist } = useJobList()

  const renderJobList = (blok: StoryblokContent): JSX.Element[] => {
    return jobylonlist?.map((job) => (
      <div key={job.id} className={styles.item}>
        <a href={job.urls.ad} target="_blank" rel="noreferrer">
          <div className={styles.left}>
            <h4>{job.function}</h4>
            <h3>{job.title}</h3>
            <p>
              {blok.deadline_text} {job.to_date}
            </p>
          </div>
          <div className={styles.right}>
            <RightArrow width={36} height={36} />
          </div>
        </a>
      </div>
    ))
  }

  return (
    <div className={styles.container}>
      <p className={styles.header}>{blok.title}</p>
      {renderJobList(blok)}
    </div>
  )
}

export default JobList
