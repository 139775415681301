import React, { FC } from 'react'
import { ThemeColors } from 'atomic/styles/theme.css'
import { Arrow01 } from './Arrow01'
import { Arrow02 } from './Arrow02'
import { Arrow03 } from './Arrow03'
import { Arrow04 } from './Arrow04'
import { Arrow05 } from './Arrow05'
import { Arrow06 } from './Arrow06'
import { Arrow07 } from './Arrow07'
import { Arrow08 } from './Arrow08'
import { Arrow09 } from './Arrow09'
import { Arrow10 } from './Arrow10'

export const arrowIllustrationTypes = [
  'arrow-1',
  'arrow-2',
  'arrow-3',
  'arrow-4',
  'arrow-5',
  'arrow-6',
  'arrow-7',
  'arrow-8',
  'arrow-9',
  'arrow-10',
] as const

export type ArrowIllustrationType = (typeof arrowIllustrationTypes)[number]

export type ArrowPropTypes = {
  name: ArrowIllustrationType
  color: keyof ThemeColors
  width?: number
  height?: number
  className?: string
}

const ArrowIllustration: FC<ArrowPropTypes> = ({ name, color, className, width, height }) => {
  switch (name) {
    case 'arrow-1':
      return <Arrow01 className={className} color={color} width={width} height={height} />
    case 'arrow-2':
      return <Arrow02 className={className} color={color} width={width} height={height} />
    case 'arrow-3':
      return <Arrow03 className={className} color={color} width={width} height={height} />
    case 'arrow-4':
      return <Arrow04 className={className} color={color} width={width} height={height} />
    case 'arrow-5':
      return <Arrow05 className={className} color={color} width={width} height={height} />
    case 'arrow-6':
      return <Arrow06 className={className} color={color} width={width} height={height} />
    case 'arrow-7':
      return <Arrow07 className={className} color={color} width={width} height={height} />
    case 'arrow-8':
      return <Arrow08 className={className} color={color} width={width} height={height} />
    case 'arrow-9':
      return <Arrow09 className={className} color={color} width={width} height={height} />
    case 'arrow-10':
      return <Arrow10 className={className} color={color} width={width} height={height} />
    default:
      return null
  }
}

export default ArrowIllustration
