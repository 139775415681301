import DynamicComponent from 'components/StoryblokComponents'
import { FC } from 'react'
import { StoryblokContent } from 'types/StoryblokContent'

import { SbBlokData, storyblokEditable } from '@storyblok/react'

import ContentContainer from '../../styled/ContentContainer'
import Grid from '../../styled/Grid'

import styles from './Row.module.scss'

type PropTypes = {
  blok?: StoryblokContent
  className?: string
}

const Row: FC<PropTypes> = ({ blok, className }) => {
  const shouldWrap = blok.wrap
  const containsText = blok.title || blok.text
  const gridGap = `${blok.grid_gap}px`
  const padding = blok.columns.length > 1 ? 90 : 10

  return (
    <div {...storyblokEditable(blok)}>
      <span className={styles.anchor} id={blok.anchor_id} />
      <section className={className ? `${styles.container} ${className}` : styles.container} style={{ backgroundColor: blok.background_color.color }}>
        <ContentContainer style={{ width: '100%', paddingTop: padding, paddingBottom: padding }}>
          {containsText && (
            <div className={styles.textContainer}>
              <h1>{blok.title}</h1>
              <p>{blok.text}</p>
            </div>
          )}
          <Grid shouldWrap={shouldWrap} gridGap={gridGap}>
            {blok.columns.map((item: SbBlokData) => (
              <DynamicComponent key={item._uid} blok={item} />
            ))}
          </Grid>
        </ContentContainer>
      </section>
    </div>
  )
}

export default Row
