import { FC } from 'react'
import { StoryblokContent } from 'types/StoryblokContent'

import { storyblokEditable } from '@storyblok/react'

import AssetSliderItem from '../../../types/AssetSliderItem'
import AssetSlider from '../../assetslider/AssetSlider'
import ContentContainer from '../../styled/ContentContainer'

import styles from './CardSliderSection.module.scss'

type PropTypes = {
  blok?: StoryblokContent
}

const CardSliderSection: FC<PropTypes> = ({ blok }) => {
  const assetSliderItems: AssetSliderItem[] = blok.images.map((item) => ({
    id: item.id,
    filename: item.filename,
    alt: item.name,
    width: 407,
    height: 407,
  }))

  return (
    <section className={styles.container} {...storyblokEditable(blok)}>
      <ContentContainer style={{ display: 'block', padding: 0 }}>
        <div className={styles.textContainer}>
          <h2>{blok.title}</h2>
        </div>
        <AssetSlider assets={assetSliderItems}></AssetSlider>
      </ContentContainer>
    </section>
  )
}

export default CardSliderSection
