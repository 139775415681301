type SmoothScrollToIdProps = {
  scrollMarginTop?: string
  scrollBehavior?: 'auto' | 'smooth'
  scrollBlock?: 'start' | 'center' | 'end' | 'nearest'
}

export default function SmoothScrollToId(linkId: string, props: SmoothScrollToIdProps) {
  if (!linkId) return
  const elem = document.getElementById(linkId)
  if (!elem) return
  if (elem) {
    elem.style.scrollMarginTop = props.scrollMarginTop ?? null
    elem.scrollIntoView({
      behavior: props.scrollBehavior ?? 'smooth',
      block: props.scrollBlock ?? 'start',
    })
  }
}
