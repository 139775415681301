import { FC, SVGProps } from 'react'

const RightArrow: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" width={props.width} height={props.height}>
    <path
      d="M14.293 9.293a1 1 0 0 0 0 1.414L21.585 18l-7.292 7.293a1 1 0 0 0-.083 1.32l.083.094a1 1 0 0 0 1.414 0l8-8a1 1 0 0 0 0-1.414l-8-8a1 1 0 0 0-1.414 0z"
      fill={props.fill ?? '#222'}
      fillRule="nonzero"
    />
  </svg>
)

export default RightArrow
