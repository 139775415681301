import { FC } from 'react'

const Check: FC = () => (
  <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.9552 0.752897C8.22975 0.313912 8.80818 0.180606 9.24716 0.45515C9.68615 0.729694 9.81945 1.30812 9.54491 1.74711L4.85437 9.24711C4.50514 9.80551 3.70412 9.83921 3.30922 9.31211L0.499764 5.56211C0.189319 5.14774 0.27357 4.56016 0.687945 4.24971C1.10232 3.93927 1.6899 4.02352 2.00035 4.43789L3.98943 7.0925L7.9552 0.752897Z"
      fill="#222222"
    />
  </svg>
)

export default Check
