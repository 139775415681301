import DynamicLink from 'components/dynamiclink/DynamicLink'
import Image from 'next/image'
import { FC } from 'react'
import { StoryblokContent } from 'types/StoryblokContent'

import { storyblokEditable } from '@storyblok/react'

import RightArrow from '../../icons/RightArrow'
import StoryblokImage from '../../storyblokimage/StoryblokImage'

import styles from './ProductSection.module.scss'
import { ouTheme } from 'atomic/styles/theme.css'

type PropTypes = {
  blok?: StoryblokContent
}

const ProductSection: FC<PropTypes> = (props) => {
  return (
    <section className={styles.container} {...storyblokEditable(props.blok)}>
      <h2>{props.blok.title}</h2>
      <DynamicLink linkObject={props.blok.product_link}>
        <div className={styles.linkContainer}>
          <p title={props.blok.text} className={styles.ingress}>
            {props.blok.text}
          </p>
          <RightArrow width={30} height={30} />
        </div>
      </DynamicLink>

      <div className={styles.imageContainer}>
        <StoryblokImage className={styles.packImage} src={props.blok.image.filename} width={900} height={737} alt={props.blok.image.name} />

        <div className={styles.rightDecorator}>
          <Image src="/images/start_arrows_04.png" fill sizes={`(max-width: ${ouTheme.breakpoints.desktop}) 100vw, 50vw`} alt="arrow decoration" />
        </div>
        <div className={styles.leftDecorator}>
          <Image src="/images/start_arrows_05.png" fill sizes={`(max-width: ${ouTheme.breakpoints.desktop}) 100vw, 50vw`} alt="arrow decoration" />
        </div>
        {props.blok.products.map((product: StoryblokContent) => {
          const className = product.name.toLowerCase().replace(/\s+/g, '') // lowercase and remove spaces
          let imageName = ''
          let imageWidth = 0

          switch (className) {
            case 'hardcovermedium':
              imageName = '/images/start_arrows_03.png'
              imageWidth = 77
              break
            case 'hardcoverlarge':
              imageName = '/images/start_arrows_01.png'
              imageWidth = 132
              break
            case 'softcovermedium':
              imageName = '/images/start_arrows_02.png'
              imageWidth = 77
              break
            default:
              break
          }

          return (
            <div key={product._uid} className={`${styles.arrowContainer} ${styles[className]}`}>
              <Image src={imageName} width={imageWidth} height={87} alt={props.blok.image.name} />
              <p className={styles.productName}>{product.name}</p>
              <p className={styles.descriptionText}>{product.type}</p>
              <p className={styles.descriptionText}>{product.price}</p>
            </div>
          )
        })}
      </div>
    </section>
  )
}

export default ProductSection
