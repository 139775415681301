import React, { FC } from 'react'

import Box from '../box/Box'
import { IllustrationBaseProps } from './Base'

export const TwoPeople: FC<IllustrationBaseProps> = ({ width = '100%', height = '100%', ...props }) => {
  return (
    <Box {...props}>
      <svg width={width} height={height} viewBox="0 0 58 41" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M17.849 2.23112C24.01 2.23112 29.0046 7.22565 29.0046 13.3867C29.0046 16.6906 27.5683 19.659 25.2861 21.7017C31.4301 24.5178 35.6979 30.725 35.6979 37.9291C35.6979 39.1613 34.699 40.1602 33.4668 40.1602C32.2346 40.1602 31.2357 39.1613 31.2357 37.9291C31.2357 30.5358 25.2423 24.5423 17.849 24.5423C10.4557 24.5423 4.46224 30.5358 4.46224 37.9291C4.46224 39.1613 3.46333 40.1602 2.23112 40.1602C0.998907 40.1602 0 39.1613 0 37.9291C0 30.725 4.26788 24.5178 10.4131 21.6979C8.12961 19.659 6.69336 16.6906 6.69336 13.3867C6.69336 7.22565 11.6879 2.23112 17.849 2.23112ZM44.6166 0C49.5476 0 53.545 3.99563 53.545 8.92448C53.545 11.3011 52.6155 13.4608 51.1002 15.0602C55.2207 17.3409 58.0091 21.7317 58.0091 26.7734C58.0091 28.0057 57.0098 29.0046 55.7771 29.0046C54.5443 29.0046 53.545 28.0057 53.545 26.7734C53.545 21.8446 49.5476 17.849 44.6166 17.849L44.0869 17.8645C41.4524 18.019 39.0554 19.3208 37.4814 21.4079C36.7394 22.3918 35.3399 22.5882 34.3555 21.8465C33.3711 21.1048 33.1746 19.7059 33.9166 18.7219C35.0622 17.2027 36.5019 15.9601 38.1327 15.0574C36.6177 13.4608 35.6883 11.3011 35.6883 8.92448C35.6883 3.99563 39.6856 0 44.6166 0ZM17.849 6.69336C14.1523 6.69336 11.1556 9.69008 11.1556 13.3867C11.1556 17.0834 14.1523 20.0801 17.849 20.0801C21.5456 20.0801 24.5423 17.0834 24.5423 13.3867C24.5423 9.69008 21.5456 6.69336 17.849 6.69336ZM44.6166 4.46224C42.1511 4.46224 40.1524 6.46005 40.1524 8.92448C40.1524 11.3889 42.1511 13.3867 44.6166 13.3867C47.0821 13.3867 49.0808 11.3889 49.0808 8.92448C49.0808 6.46005 47.0821 4.46224 44.6166 4.46224Z"
          fill="currentColor"
        />
      </svg>
    </Box>
  )
}
