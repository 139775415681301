import { FC } from 'react'

const AddImage: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="55" height="54" viewBox="0 0 45 44">
    <g fill="none" fillRule="evenodd" transform="translate(2)">
      <circle cx="10" cy="20" r="4" fill="#222222" fillRule="nonzero" transform="rotate(-180 10 20)"></circle>
      <path stroke="#222222" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" d="M2 42l16-14 6 6 8-8"></path>
      <path stroke="#222222" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" d="M32 26v16H0V10h16"></path>
      <path
        fill="#222222"
        fillRule="nonzero"
        d="M32.278 0c1.074 0 1.944.905 1.944 2.022v6.144h6.145c1.117 0 2.022.871 2.022 1.945s-.905 1.945-2.022 1.945l-6.145-.001V18.2c0 1.117-.87 2.022-1.944 2.022-1.074 0-1.945-.905-1.945-2.022v-6.145h-6.144c-1.117 0-2.022-.87-2.022-1.944 0-1.074.905-1.944 2.022-1.944l6.144-.001V2.022c0-1.117.87-2.022 1.945-2.022z"
      ></path>
    </g>
  </svg>
)

export default AddImage
