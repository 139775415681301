import { StoryblokLink } from 'types/StoryblokLink'

export const urlFromStory = (linkObject: StoryblokLink): string => {
  if (!linkObject.story?.url && !linkObject.cached_url) {
    return undefined
  }

  if (!linkObject.story?.url) {
    return linkObject.cached_url
  }

  let url = linkObject.story.full_slug

  if (!url.startsWith('/')) {
    url = '/' + url
  }

  if (url.endsWith('/')) {
    url = url.slice(0, -1)
  }

  return url
}
