import { Container } from 'atomic'
import { BoxProps } from 'atomic/components/atoms/box/Box'
import TrustpilotWidget from 'components/trustpilot/Trustpilot'
import { container } from './Reviews.css'
import { FC } from 'react'
import { BuilderProps, removeKeys } from 'components/builder_components/helpers/BuilderPropCleaner'

const Reviews: FC<BoxProps & BuilderProps> = ({ ...props }) => {
  const cleanProps = removeKeys(props, { builderBlock: true, builderState: true })
  return (
    <Container {...cleanProps} className={container}>
      <TrustpilotWidget variant="carousel" background="lav4" paddingY="xxl" paddingX={{ mobile: 'none', tablet: 'xxl' }} borderRadius="m" />
    </Container>
  )
}

export default Reviews
