import dynamic from 'next/dynamic'
import { FC } from 'react'
import { StoryblokContent } from 'types/StoryblokContent'

import { storyblokEditable } from '@storyblok/react'

import { useModalState } from '../../../context/ModalStateContext'
import Modal from '../../modal/Modal'
import StoryblokImage from '../../storyblokimage/StoryblokImage'

import styles from './GetAppModal.module.scss'
import { ouTheme } from 'atomic/styles/theme.css'

const AppBadges = dynamic(() => import('../../appbadges/AppBadges'), { ssr: false })

type PropTypes = {
  blok?: StoryblokContent
}

const GetAppModal: FC<PropTypes> = ({ blok }) => {
  const { showGetAppModal, setShowGetAppModal } = useModalState()
  return (
    <Modal className={styles.container} show={showGetAppModal} onClosed={() => setShowGetAppModal(false)}>
      <div {...storyblokEditable(blok)}>
        <div className={styles.imageContainer}>
          <StoryblokImage
            src={blok.illustration.filename}
            fill
            sizes={`(max-width: ${ouTheme.breakpoints.desktop}) 100vw, 50vw`}
            alt={blok.illustration.name}
          />
        </div>
        <div className={styles.textContainer}>
          <h2>{blok.title}</h2>
          <p>{blok.text}</p>
        </div>
      </div>
      <AppBadges style={{ marginRight: 10 }} />
    </Modal>
  )
}

export default GetAppModal
