import { FC } from 'react'
import { CSSProperties } from 'styled-components'

import styles from './SubmitMessage.module.scss'

export enum SubmitMessageStatus {
  Positive,
  Warning,
  Negative,
}

export type SubmitMessage = {
  status: SubmitMessageStatus
  text: string | null
}

type PropTypes = {
  message: SubmitMessage
  style?: CSSProperties
}

const SubmitMessage: FC<PropTypes> = ({ message, style }) => {
  switch (message?.status) {
    case SubmitMessageStatus.Positive:
      return (
        <div className={`${styles.container} ${styles.positive}`} style={style}>
          <p>{message.text}</p>
        </div>
      )
    case SubmitMessageStatus.Warning:
      return (
        <div className={`${styles.container} ${styles.warning}`} style={style}>
          <p>{message.text}</p>
        </div>
      )
    case SubmitMessageStatus.Negative:
      return (
        <div className={`${styles.container} ${styles.negative}`} style={style}>
          <p>{message.text}</p>
        </div>
      )
    default:
      return null
  }
}

export default SubmitMessage
