import { FC } from 'react'

const LeftArrow: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="8" height="15">
    <path
      fill="#666"
      d="M7.766.234a.8.8 0 010 1.132L1.63 7.5l6.135 6.134a.8.8 0 01.077 1.042l-.077.09a.8.8 0 01-1.132 0l-6.4-6.4a.8.8 0 01-.175-.867.798.798 0 01.175-.865l6.4-6.4a.8.8 0 011.132 0z"
    ></path>
  </svg>
)

export default LeftArrow
