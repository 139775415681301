import { Box, Heading, Text } from 'atomic'
import { FC } from 'react'
import * as css from './Usp.css'
import { ThemeColors } from 'atomic/styles/theme.css'
import KluddIllustration, { KluddIllustrationType } from 'atomic/components/atoms/illustrations/KluddIllustration'
import Illustration, { IllustrationType } from 'atomic/components/atoms/illustrations/Illustration'

export type UspItemProps = {
  color: keyof ThemeColors
  title: string
  body: string
  kluddType?: KluddIllustrationType
  kluddColor?: keyof ThemeColors
  kluddWidth?: number
  illustration?: IllustrationType
}

const UspItem: FC<UspItemProps> = ({ color, title, body, kluddType, kluddColor, kluddWidth, illustration }) => (
  <Box display="flex" flexDirection="column" alignItems="flex-start" background={color ?? 'lav4'} padding="m" borderRadius="m">
    <Box
      display={'flex'}
      alignItems={'center'}
      justifyContent={'center'}
      position={'relative'}
      marginBottom={'m'}
      className={css.illustrationContainer}
    >
      <KluddIllustration
        name={kluddType ?? 'kludd-13'}
        color={kluddColor ?? 'mossa2'}
        width={kluddWidth ?? 130}
        className={css.illustrationBackdrop}
      />
      <Illustration className={css.illustration} name={illustration ?? 'flower'} />
    </Box>
    <Heading fontSize="base" as="h3" marginBottom="xxs">
      {title}
    </Heading>
    <Text fontSize="s" as="p">
      {body}
    </Text>
  </Box>
)

export default UspItem
