import InternalLink from 'components/internallink/InternalLink'
import { useRouter } from 'next/router'
import React, { FC, useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { withTheme } from 'styled-components'
import { StoryblokContent } from 'types/StoryblokContent'

import { storyblokEditable } from '@storyblok/react'

import { useLink } from '../../../context/LinkContext'
import { useModalState } from '../../../context/ModalStateContext'
import { Theme } from '../../../styles/Theme'
import StoryblokImage from '../../storyblokimage/StoryblokImage'

import styles from './PostList.module.scss'
import { ouTheme } from 'atomic/styles/theme.css'

type PropTypes = {
  blok?: StoryblokContent
  theme: Theme
}

const isBlogpostActiveYet = (activeFromDateString: string): boolean => {
  const todaysDate = new Date()
  todaysDate.setHours(0, 0, 0, 0)
  const activeFromDate = activeFromDateString?.length > 0 ? new Date(activeFromDateString) : new Date()
  activeFromDate.setHours(0, 0, 0, 0)
  if (activeFromDate.getTime() <= todaysDate.getTime()) {
    return true
  } else {
    return false
  }
}

const PostList: FC<PropTypes> = ({ blok, theme }) => {
  const router = useRouter()
  const { showGetStoriesModal, setShowGetStoriesModal } = useModalState()
  const available_languages: string[] = ['en-gb', 'en-us', 'en-au', 'nl-nl', 'de-de', 'sv-se', 'it-it', 'fr-fr', 'es-es']
  const isAllowed: boolean = available_languages?.includes(router.locale)
  const { getLocalePath } = useLink()

  // set the number of columns depending on screen width
  const mediumScreen = useMediaQuery({ maxWidth: theme.screenSizes.medium })
  const smallScreen = useMediaQuery({ maxWidth: theme.screenSizes.small })
  const [columnCount, setColumnCount] = useState(3)

  useEffect(() => {
    setColumnCount(smallScreen ? 1 : mediumScreen ? 2 : 3)
  }, [mediumScreen, smallScreen])

  useEffect(() => {
    // check if the blog is translated to the current language/locale
    if (!isAllowed && !showGetStoriesModal) {
      setShowGetStoriesModal(true)
    }
  }, [isAllowed, showGetStoriesModal])

  return (
    <div className={styles.container} key={blok._uid} {...storyblokEditable(blok)}>
      <div className={styles.postsContainer}>
        {[...Array(columnCount)].map((_, columnIndex) => {
          return (
            <div key={columnIndex} style={{ flex: 1 }}>
              {blok.posts
                .filter((blogpost) => isBlogpostActiveYet(blogpost.content.active_from_date))
                .sort((post1, post2) => Date.parse(post2.first_published_at) - Date.parse(post1.first_published_at))
                .filter((_, postIndex: number) => (postIndex + columnCount) % columnCount === columnIndex)
                .map((post) => {
                  const slugWithoutLocale = post.full_slug.replace(`${router.locale}/`, '')
                  const localePath = getLocalePath(slugWithoutLocale, router.locale)
                  return (
                    <div key={post.uuid} className={styles.postContainer}>
                      <InternalLink href={localePath}>
                        <div className={styles.post}>
                          <div className={`${styles.imageContainer}`}>
                            <StoryblokImage
                              alt="post cover"
                              src={post.content.image?.filename}
                              className={styles.postImage}
                              fill
                              sizes={`(max-width: ${ouTheme.breakpoints.desktop}) 100vw, 50vw`}
                            />
                          </div>
                          <div className={styles.categories}>{post.content.categories.map((category) => category.name).join(', ')}</div>
                          <h4 className={styles.postHeader}>{post.content.title}</h4>
                          <p>{post.content.intro}</p>
                        </div>
                      </InternalLink>
                    </div>
                  )
                })}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default withTheme(PostList)
