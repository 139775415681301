/* eslint-disable react/jsx-no-target-blank */
import { FC, useRef, useEffect } from 'react'
import { Container } from 'atomic'
import { BoxProps } from 'atomic/components/atoms/box/Box'
import { themeVars } from 'atomic/styles/theme.css'
import { container } from './Trustpilot.css'

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Trustpilot: any
  }
}

const TrustpilotWidget: FC<BoxProps & { variant: 'micro' | 'carousel' }> = ({ variant = 'micro', ...props }) => {
  const ref = useRef(null)

  useEffect(() => {
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true)
    }
  }, [])

  return (
    <Container {...props} className={container}>
      {variant === 'micro' ? (
        <div
          ref={ref}
          className="trustpilot-widget"
          style={{ marginLeft: -16 }} // hack to override Trustpilot's text alignment
          data-locale="en-US"
          data-template-id="5419b732fbfb950b10de65e5"
          data-businessunit-id="5e9572672431b30001757b92"
          data-style-height="20px"
          data-theme="light"
          data-style-width="auto"
        >
          <a href="https://uk.trustpilot.com/review/www.three.co.uk" target="_blank">
            Trustpilot
          </a>
        </div>
      ) : (
        <div
          ref={ref}
          className="trustpilot-widget"
          data-locale="en-US"
          data-template-id="53aa8912dec7e10d38f59f36"
          data-businessunit-id="5e9572672431b30001757b92"
          data-style-width="100%"
          data-theme="light"
          data-stars="3,4,5"
          data-review-languages="en"
          data-font-family="Noto Sans"
          data-text-color={themeVars.colors.ljung1}
        >
          <a href="https://www.trustpilot.com/review/onceupon.photo" target="_blank" rel="noopener">
            Trustpilot
          </a>
        </div>
      )}
    </Container>
  )
}

export default TrustpilotWidget
