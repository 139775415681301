import axios from 'axios'
import { FC, useEffect, useState } from 'react'
import { StoryblokContent } from 'types/StoryblokContent'

import { storyblokEditable } from '@storyblok/react'

import { useModalState } from '../../../context/ModalStateContext'
import Modal from '../../modal/Modal'
import FormSection from '../formsection/FormSection'
import GetNewsletterModalResult from './getnewslettermodalresult/GetNewsletterModalResult'

import styles from './GetNewsletterModal.module.scss'

type PropTypes = {
  blok?: StoryblokContent
}

export enum GetNewsletterStatus {
  SuccessfullySubscribed,
  AlreadySubscribed,
  Error,
}

const GetNewsletterModal: FC<PropTypes> = ({ blok }) => {
  const { showGetNewsletterModal, setShowGetNewsletterModal } = useModalState()
  const [clearSubmitMessageStatus, setClearSubmitMessageStatus] = useState(false)
  const [getNewsletterStatus, setGetNewsletterStatus] = useState<GetNewsletterStatus>(null)

  useEffect(() => {
    setGetNewsletterStatus(null)
    setClearSubmitMessageStatus(true)
    setTimeout(() => {
      setClearSubmitMessageStatus(false)
    }, 2000)
  }, [showGetNewsletterModal])

  const handleOnSubmit = async (event: React.SyntheticEvent, token: string): Promise<void> => {
    const url = 'https://jsonplaceholder.typicode.com/posts'

    const target = event.target as typeof event.target & {
      firstname: { value: string }
      lastname: { value: string }
      email: { value: string }
    }

    const formData = new FormData()
    formData.append('firstname', target.firstname.value)
    formData.append('lastname', target.lastname.value)
    formData.append('email', target.email.value)
    formData.append('captcha', token)

    await axios
      .post(url, formData)
      .then(() => {
        setGetNewsletterStatus(GetNewsletterStatus.SuccessfullySubscribed)
        return Promise.resolve()
      })
      .catch((error) => {
        if (error.status === 409) {
          setGetNewsletterStatus(GetNewsletterStatus.AlreadySubscribed)
        }
        setGetNewsletterStatus(GetNewsletterStatus.Error)
        return Promise.reject(error)
      })
  }

  const renderModalContent = (): JSX.Element => {
    switch (getNewsletterStatus) {
      case GetNewsletterStatus.SuccessfullySubscribed:
        return <GetNewsletterModalResult blok={blok.successful_result[0]} />
      case GetNewsletterStatus.AlreadySubscribed:
        return <GetNewsletterModalResult blok={blok.already_subscribing_result[0]} />
      case GetNewsletterStatus.Error:
      default:
        return (
          <>
            <h2>{blok.title}</h2>
            <p>{blok.text}</p>
          </>
        )
    }
  }

  const hideForm = getNewsletterStatus === GetNewsletterStatus.SuccessfullySubscribed || getNewsletterStatus === GetNewsletterStatus.AlreadySubscribed
  return (
    <Modal show={showGetNewsletterModal} onClosed={() => setShowGetNewsletterModal(false)}>
      <div className={styles.contentContainer} {...storyblokEditable(blok)}>
        {renderModalContent()}
        <FormSection
          blok={blok.form[0]}
          handleOnSubmit={handleOnSubmit}
          hide={hideForm}
          clearSubmitMessageStatus={clearSubmitMessageStatus}
          isModal={true}
        />
      </div>
    </Modal>
  )
}

export default GetNewsletterModal
