import { useTranslation } from 'next-i18next'
import { LocaleKey } from '../types/LokalizeKeys'

type TFunc = (key: LocaleKey) => string

export const useSafeTranslation = (): { t: TFunc } => {
  const { t: trans } = useTranslation()

  const t: TFunc = (key: LocaleKey): string => trans(key)

  return { t }
}

type CmsOrFunc = (cmsValue: string, lokalizeKey: LocaleKey) => string

export const useCmsOrTranslation = (): { cmsOrLokalise: CmsOrFunc } => {
  const { t } = useSafeTranslation()

  const cmsOrLokalise: CmsOrFunc = (cmsValue, lokalizeKey): string => {
    const cmsAvailable = !!cmsValue && cmsValue.trim() !== ''
    return cmsAvailable ? cmsValue : t(lokalizeKey)
  }

  return { cmsOrLokalise }
}
