import NextLink, { LinkProps } from 'next/link'
import { FC } from 'react'
import { useRouter } from 'next/router'
import { trackOutboundLink } from 'lib/analytics-utils'
import { getUrlWithCampaignParameters } from 'utils/get-campaign-parameters'

type CustomLinkProps = {
  removeCampaignParameters?: boolean
} & React.AnchorHTMLAttributes<HTMLAnchorElement> &
  LinkProps

export const Link: FC<CustomLinkProps> = ({ removeCampaignParameters, ...props }) => {
  const router = useRouter()
  const url = !removeCampaignParameters ? getUrlWithCampaignParameters(props.href, router.query) : props.href

  const onClick = (): void => {
    trackOutboundLink(url)
  }

  return <NextLink href={url} onClick={onClick} {...props} />
}
